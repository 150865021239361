import { createContext } from 'react';
import { NyseMarketState, PriceType } from '../types';
import moment from 'moment';
import { ReadyState } from 'react-use-websocket';
import { Pnl } from '../types/Pnl';

type WebSocketContextProps = {
  prices: PriceType
  pnl: Pnl
  accountSummary: any
  openOrders: any
  openPositions: any
  webSocketServerStatus: ReadyState
  ibGatewayUpAt: string
  nyseMarketState: NyseMarketState | null
  historicalDataUpdateEvent: {
    event: string
    payload: any
  } | null
  backtestEvent: {
    event: string
    payload: any
  } | null
}

export const WebSocketContext = createContext<WebSocketContextProps>({
  prices: {},
  pnl: { dailyPnL: 0, unrealizedPnL: 0, realizedPnL: 0 },
  accountSummary: {},
  openOrders: {},
  openPositions: {},
  ibGatewayUpAt: moment().utc().format(),
  webSocketServerStatus: ReadyState.UNINSTANTIATED,
  backtestEvent: null,
  historicalDataUpdateEvent: null,
  nyseMarketState: null
});