import { configureStore } from "@reduxjs/toolkit";
import appReducer from './app/appSlice'
import counterReducer from './counter/counterSlice'
import botReducer from './bot/botSlice'
import userReducer from './user/userSlice'
import backTestReducer from './backTest/backTestSlice'
import historicalDataReducer from './historicalData/historicalDataSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    counter: counterReducer,
    user: userReducer,
    bot: botReducer,
    backTest: backTestReducer,
    historicalData: historicalDataReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;