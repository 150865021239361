import './TradeNinjaButton.scss'
import { Button, ButtonProps } from '@mui/material';

const TradeNinjaButton = (props: ButtonProps) => {
  return <Button className="tradeninja-button-container" {...props} variant="outlined" style={{
    backgroundColor: 'dodgerblue', //'#2196F3',
    border: 'solid 1px azure',
    color: '#fff',
    ...props.style
  }} />
}

export default TradeNinjaButton