import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { FC } from 'react';
import { SvgIconProps } from '@mui/material';

type DropDownMenuItemProps = {
  icon?: (props: SvgIconProps) => JSX.Element
  text: string
  onClick: (event: React.MouseEvent<HTMLElement>) => void
  divider?: boolean
}

type DropDownMenuProps = {
  anchorEl: HTMLElement | null;
  handleClose: (event: React.MouseEvent<HTMLElement>) => void
  open: boolean
  arrowPosition: 'LEFT' | 'RIGHT'
  menuItems: DropDownMenuItemProps[]
}

const DropDownMenu: FC<DropDownMenuProps> = ({ anchorEl, open, handleClose, arrowPosition, menuItems }) => {

  const arrowEffect: any = {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    width: 10,
    height: 10,
    bgcolor: 'background.paper',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  }
  if (arrowPosition === 'LEFT') {
    arrowEffect.left = 14
  } else {
    arrowEffect.right = 14
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&::before': arrowEffect,
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {
        menuItems.map((item, index) => (
          <MenuItem key={index} onClick={item.onClick} divider={item.divider}>
            {
              item.icon && (
                <ListItemIcon>
                  <item.icon fontSize="small" />
                </ListItemIcon>
              )
            }
            {item.text}
          </MenuItem>

        ))
      }
    </Menu>
  );
}

export default DropDownMenu