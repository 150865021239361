import { FC, useRef } from 'react';
import CustomModal from '../components/CustomModal/CustomModal';
import { ModalButtonEnum } from '../types';
import NinjaForm from '../components/NinjaForm';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { incrementBotPosition } from '../state/bot/botSlice';

type IncrementPositionModalProps = {
  botId: string
  open: boolean
  setOpen: (open: boolean) => void
  callback: (createdBot: any) => void
}

const IncrementPositionModal: FC<IncrementPositionModalProps> = ({ botId, open, setOpen, callback }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <CustomModal
      isDialog={false}
      title={'Incrementing Position'}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          const tradeSize = parseFloat(values.tradeSize)
          
          console.log(botId, tradeSize)
          dispatch(incrementBotPosition({ botId, tradeSize, callback }))
          localStorage.setItem('increment-position-amount', tradeSize.toString())
        }
        setOpen(false)
      }}
    >
      <>
      <NinjaForm formRef={formRef} OnSubmit={() => { }} fields={
        [
            { name: 'tradeSize', label: 'Trade Size($)', zod: z.bigint({ required_error: 'Trade Size is required' }), gridWidth: 12, defaultValue: localStorage.getItem('increment-position-amount') },
          ]} />
      </>
    </CustomModal>
  )
}

export default IncrementPositionModal