import AppHeader from "../../components/AppHeader"
import './DashboardPage.scss'
import DashboardWidget from "./components/DashboardWidget"
import DepositsAndWithdrawalsWidget from "./components/widgets/DepositsAndWithdrawalsWidget"
import MarginWidget from "./components/widgets/MarginWidget"
import NetAssetValuesWidget from "./components/widgets/NetAssetValuesWidget"
import OpenPositionsWidget from "./components/widgets/OpenPositionsWidget"
import PandLWidget from "./components/widgets/PandLWidget"

const DashboardPage = () => {
  return (
    <div className="dashboard-page-container">
      <AppHeader title="Dashboard">
      </AppHeader>
      <div className="dashboard-page-body">
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '25%' }}>
            <DashboardWidget title="P&L" height="170px" width="100%">
              <PandLWidget />
            </DashboardWidget>
            <DashboardWidget title="MARGIN" height="269px" width="100%">
              <MarginWidget />
            </DashboardWidget>
          </div>
          <DashboardWidget title="Balance / Goal" height="450px" width="75%">
            <NetAssetValuesWidget />
          </DashboardWidget>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', width: '100%' }}>
          <DashboardWidget title="OPEN POSITIONS" height="435px" width="25%">
            <OpenPositionsWidget />
          </DashboardWidget>
          <DashboardWidget title="DEPOSITS / WITHDRAWALS (last 10)" height="435px" width="75%">
            <DepositsAndWithdrawalsWidget />
          </DashboardWidget>
        </div>
      </div>
    </div>
  )
}

export default DashboardPage