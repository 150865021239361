import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import webSocketPNG from '../../assets/images/websocket.png'
import './WebSocketServerStatus.scss'
import { useEffect, useState } from 'react';
import NinjaTooltip from '../NinjaTooltip';
import { useWebSocketServerStatus } from '../../hooks';
import { ReadyState } from 'react-use-websocket';

const WebSocketServerStatus = () => {
  const [color, setColor] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const webSocketServerStatus = useWebSocketServerStatus()

  useEffect(() => {
    switch (webSocketServerStatus) {
      case ReadyState.UNINSTANTIATED:
        setColor('silver')
        setMessage('Uninstantiated')
        break;
      case ReadyState.OPEN:
        setColor('#44b700')
        setMessage('Connected to WebSocket Server.')
        break;
      case ReadyState.CLOSING:
        setColor('orange')
        setMessage('Closing Connection to WebSocket Server.')
        break;
      case ReadyState.CLOSED:
        setColor('red')
        setMessage('Connection to WebSocket Server is Closed.')
        break;
    
      default:
        break;
    }
  }, [webSocketServerStatus])

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: color,
      color,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <NinjaTooltip message={message}>
      <StyledBadge
        className='websocket-server-status-container'
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar alt="WebSocket Server Status" src={webSocketPNG} />
      </StyledBadge>
    </NinjaTooltip>
  )
}

export default WebSocketServerStatus