
//gross profit divided by gross losses
const calculateProfitFactor = (positions: any[]): string | number => {
  const totalGrossProfit = positions.reduce((acc: number, position: any) => {
    if (position.exit.profit > 0) {
      acc += position.exit.profit
    }
    return acc
  }, 0)
  const totalGrossLosses = positions.reduce((acc: number, position: any) => {
    if (position.exit.profit < 0) {
      acc += Math.abs(position.exit.profit)
    }
    return acc
  }, 0)
  if (totalGrossLosses === 0) return '∞'
  return totalGrossProfit / totalGrossLosses
}

export default calculateProfitFactor