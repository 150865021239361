import { FC } from 'react';
import CustomModal from '../CustomModal/CustomModal';
import { ModalButtonEnum } from '../../types';
import DataGrid, { DataGridColumnProps } from '../DataGrid/DataGrid';
import './DataGridModal.scss'

type DataGridModalProps = {
  title: string
  open: boolean
  setOpen: (open: boolean) => void
  data: any[]
  columns: DataGridColumnProps[]
}

const DataGridModal: FC<DataGridModalProps> = ({ title, open, setOpen, data, columns }) => {

  return (
    <CustomModal
      className="data-grid-modal-container"
      width={'950px'}
      height={'450px'}
      isDialog={false}
      title={title}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          //
        }
        setOpen(false)
      }}
    >
      <DataGrid
        height={317}
        data={data}
        columns={columns}
      />
    </CustomModal>
  )
}

export default DataGridModal