import { FC, ReactNode } from "react";
import './DashboardWidget.scss'

type DashboardWidgetProps = {
  title: string
  height: string
  width: string
  children: ReactNode
}

const DashboardWidget: FC<DashboardWidgetProps> = ({ title, height, width, children }) => {
  return (
    <div className="dashboard-widget-container" style={{ width, maxHeight: height, minHeight: height }}>
      <div className="dashboard-widget-title">{title}</div>
      <div className="dashboard-widget-body">{children}</div>
    </div>
  )
}

export default DashboardWidget