import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import ibkrPNG from '../../assets/images/ibkr.png'
import './IbkrStatus.scss'
import { useEffect, useState } from 'react';
import moment from 'moment'
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../state/store';
import { setIbGatewayConnected } from '../../state/user/userSlice';
import { useIbGatewayUpAt } from '../../hooks';
import NinjaTooltip from '../NinjaTooltip';

const IbkrStatus = () => {
  const [isActive, setActive] = useState<boolean>(false)
  const dispatch = useDispatch<AppDispatch>()
  const ibGatewayConnectedAt = useIbGatewayUpAt()

  useEffect(() => {
    const interval = setInterval(async () => {
      const ibGatewayConnectedAtMom = moment(ibGatewayConnectedAt).utc()
      const now = moment().utc()
      const active = ibGatewayConnectedAtMom.isAfter(now.subtract(3, 'seconds'))
      setActive(active)
      dispatch(setIbGatewayConnected(active))
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [dispatch, ibGatewayConnectedAt])

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: isActive ? '#44b700' : 'red',
      color: isActive ? '#44b700' : 'red',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <NinjaTooltip message={isActive ? 'Connected to IB Gateway.' : 'Connectivity between IB Gateway has been lost.'}>
      <StyledBadge
        className='ibkrstatus-container'
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar alt="Interactive Broker Status" src={ibkrPNG} />
      </StyledBadge>
    </NinjaTooltip>
  )
}

export default IbkrStatus