import './NetAssetValuesWidget.scss'
import { formatNumber } from 'humanize-plus';
import LoadingData from '../../../../../components/LoadingData';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../state/store';
import { getHistoricalBalances } from '../../../../../state/user/userSlice';
import { useEffect, useRef, useState } from 'react';
import { AreaChart, AreaChartProps } from '@opd/g2plot-react';
import { Datum } from '@antv/g2plot';

const NetAssetValuesWidget = () => {
  const dispatch = useDispatch<AppDispatch>()
  const historicalBalances = useSelector((state: RootState) => state.user.historicalBalances);
  const [graphData, setGraphData] = useState<any[]>([])
  const [graphConfig, setGraphConfig] = useState<AreaChartProps>()
  const chartRef = useRef<any>()

  useEffect(() => {
    dispatch(getHistoricalBalances())
  }, [dispatch])

  useEffect(() => {
    if (historicalBalances.data) {
      const data: any = []
      for (const goal of historicalBalances.data.goal) {
        const goalMonth = goal.date.substring(0, 7)
        const goalValue = goal.value

        const balancesMonth: any[] = historicalBalances.data.balances.filter(itm => itm.date.substring(0, 7) === goalMonth)
        for (const balanceMonth of balancesMonth) {
          data.push({
            desc: 'balance',
            date: balanceMonth.date,
            value: balanceMonth.value,
          })
          data.push({
            desc: 'goal',
            date: balanceMonth.date,
            value: goalValue,
          })
        }
      }      
      setGraphData(data)
    }

    }, [historicalBalances.data])

  useEffect(() => {
    if (graphData) {
      setGraphConfig({
        height: 350,
        autoFit: true,
        xField: 'date',
        yField: 'value',
        seriesField: 'desc',
        yAxis: {
          label: null,
          grid: {
            line: {
              style: {
                lineWidth: 0
              }
            }
          }
        },
        xAxis: {
          label: null,
          line: {
            style: {
              lineWidth: 0
            }
          },
        },
        style: {
          flex: 1
        },
        areaStyle: (datum: Datum) => {
          if (datum.desc === 'balance') return { fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff', }
          return { fill: 'l(270) 0:#dcfce7 0.5:#86efac 1:#16a34a', }
        },
        data: graphData,
        legend: false,
        tooltip: {
          formatter: (datum) => ({
            name: `<b>${datum.desc}</b>`,
            value: formatNumber(datum.value, 2)
          }),
        },
      })      
    }
  }, [graphData])


  return (
    <div className="historical-balances-widget-container">
      <LoadingData loading={historicalBalances.loading}>
        <div className="historical-balances-legend">
          <div className="historical-balances-legend-item" style={{ color: '#005cb3' }}>
            <div>Today's Balance :</div><div>{formatNumber(graphData.length > 0 ? graphData[graphData.length - 2].value : 0, 2) }</div>
          </div>
          <div className="historical-balances-legend-item" style={{ color: '#16a34a' }}>
            <div>EOM Goal :</div><div>{formatNumber(graphData.length > 0 ? graphData[graphData.length - 1].value : 0, 2) }</div>
          </div>
        </div>
        <AreaChart {...graphConfig!} chartRef={chartRef} />
      </LoadingData>
    </div>
  )
}

export default NetAssetValuesWidget