
import { formatNumber } from "humanize-plus";
import moment from "moment";
import { FC, ReactNode, useEffect, useState } from "react"
import './DataGrid.scss'
import classNames from "classnames";

export interface DataGridColumnProps {
  name: string
  width: string
  title?: string
  align?: string
  type?: string
  calc?: (record: any, column: DataGridColumnProps) => any
  renderer?: (data: any[], record: any, column: DataGridColumnProps, recordIndex: number) => ReactNode
}

interface DataGridProps {
  height: number
  data: any[]
  columns: DataGridColumnProps[]
  className?: string
  selectable?: boolean
  shouldSelectRow?: (record: any) => boolean
  onSelectRecord?: (record: any) =>  void
}

const columnStyle = (column: DataGridColumnProps) => {
  const style: any = { width: column.width }
  if (column.align) {
    style.textAlign = column.align
  }
  return style
}

const DataGrid: FC<DataGridProps> = ({ height, data, columns, className, selectable, onSelectRecord, shouldSelectRow }) => {
  const showHeader = columns.some(itm => itm.title)
  const [selectedRecord, setSelectedRecord] = useState(null)

  useEffect(() => {
    if (data && data.length > 0 && selectable && !selectedRecord) setSelectedRecord(data[0])
  }, [data, selectable, selectedRecord, setSelectedRecord])
  
  const dataGridHeader = () => {
    return (
      <div className="data-grid-title-container">
        {
          columns.map((column, idx) => {
            return (
              <div className="data-grid-title-column" key={idx} style={columnStyle(column)}>{column.title || ''}</div>
            )
          })
        }
      </div>
    )
  }

  const renderValue = (record: any, column: DataGridColumnProps): string => {
    const value = column.calc ? column.calc(record, column) : record[column.name!]
    if (column.type === 'number') return formatNumber(value, 2)
    if (column.type === 'date') return moment(value).format('YYYY-MM-DD')
    if (column.type === 'datetime') return moment(value).format('YYYY-MM-DD HH:mm')
    return value
  }

  const dataGridBody = () => {
    return (
      <div className={classNames('data-grid-body-container', { selectable: selectable === true })}>
        <div className="data-grid-body">
          {
            data.map((record: any, recordIndex: number) => {
              return (
                <div key={record.id} className={classNames('data-grid-row', { selected: selectable ? shouldSelectRow!(record) : false })} onClick={() => {
                  if (selectable) {
                    setSelectedRecord(record)
                    if (onSelectRecord) onSelectRecord(record)
                  }
                }}>
                  {
                    columns.map(column => {
                      return (
                        <div className="data-grid-column" style={columnStyle(column)}>
                          {column.renderer ? column.renderer(data, record, column, recordIndex) : renderValue(record, column)}
                        </div>
                      )
                    })
                  }
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <div className="data-grid-container" style={{ height: `${height}px` }}>
      <div className="data-grid">
        <div className="data-grid-content">
          {showHeader && dataGridHeader()}
          {dataGridBody()}
        </div>
      </div>
    </div>
  )
}

export default DataGrid