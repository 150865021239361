import { FC, useEffect, useState } from "react"
import './BotDetails.scss'
import { useDispatch, useSelector } from "react-redux"
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import getPositionDetails, { getPositionDetailsResponse } from "../../../../utils/getPositionDetails"
import TradeNinjaButton from "../../../../components/TradeNinjaButton"
import { Button, Checkbox } from "@mui/material"
import TradeNinjaTabs from "../../../../components/TradeNinjaTabs"
import TradeNinjaTab from "../../../../components/TradeNinjaTab"
import { BotStatusEnum, PriceType } from "../../../../types";
import { AppDispatch, RootState } from "../../../../state/store";
import { cancelBotPosition, closeBotPosition, deleteMountedBot, mountBot } from "../../../../state/bot/botSlice";
import IncrementPositionModal from "../../../../modals/IncrementPositionModal";
import { openDialogConfirm, openDialogWarning } from "../../../../utils/openDialog";
import { useSnackbar } from 'notistack';
import NinjaTooltip from "../../../../components/NinjaTooltip";

type BotDetailsProps = {
  bot: any
  isPinned: boolean
  setPinned: (pin: boolean) => void
  prices: PriceType
  setSelectedBotId: (selectedBotId: string) => void
  isMountedBot: boolean

  onEnableBot: (botId: string, enable: boolean) => void
}

const BotDetails: FC<BotDetailsProps> = ({ bot, isPinned, setPinned, prices, isMountedBot, setSelectedBotId, onEnableBot }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const dispatch = useDispatch<AppDispatch>()
  const [positionDetails, setPositionDetails] = useState<getPositionDetailsResponse | null>(null)
  const [openIncrementPosition, setOpenIncrementPosition] = useState(false)
  const deletedMountedBot = useSelector((state: RootState) => state.bot.deleteMountedBot);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (deletedMountedBot.data) {
      enqueueSnackbar('Mounted Bot deleted successfully!', { variant: 'success' })
    }
  }, [deletedMountedBot.data, enqueueSnackbar])

  useEffect(() => {
    if (bot?.position?.orders) {
      setPositionDetails(getPositionDetails(bot.position.entry.side, bot.position.orders, prices[bot.symbol]))
    }
  }, [bot, prices])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const cancelPosition = (_id: string) => {
    dispatch(cancelBotPosition({ _id }))
  }

  if (!bot) return null
  const hasPosition = bot.position !== undefined
  const hasOrderFilled = bot.position && (bot.position.orders || []).some((itm: any) => itm.status === 'Filled')

  return (
    <div className="bot-details-container">
      <IncrementPositionModal botId={bot._id} open={openIncrementPosition} setOpen={setOpenIncrementPosition} callback={(updatedBot) => { }} />
      <div className="bot-details-toolbar">
        <div className="bot-details-toolbar-buttons">
          <div className="bot-details-toolbar-pin">
            <Checkbox
              icon={<PushPinOutlinedIcon />}
              checkedIcon={<PushPinIcon />}
              checked={isPinned}
              onChange={(e) => setPinned(e.target.checked)}
            />
          </div>
          {
            hasOrderFilled ? (
              <>
                <TradeNinjaButton
                  onClick={() => setOpenIncrementPosition(true)}
                  // className="bot-details-enable-button"
                  variant="outlined">
                  Increment Position
                </TradeNinjaButton>
                <TradeNinjaButton
                  onClick={() => dispatch(closeBotPosition({ _id: bot._id }))}
                  className="bot-details-enable-button"
                  variant="outlined">
                  Close Position
                </TradeNinjaButton>
              </>
            ) : (
              <>
                {
                  (isMountedBot && [BotStatusEnum.RUNNING_LONG, BotStatusEnum.RUNNING_SHORT].includes(bot.status)) && (
                    <TradeNinjaButton
                      onClick={() => cancelPosition(bot._id)}
                      className="bot-details-enable-button"
                      variant="outlined">
                      Cancel Position
                    </TradeNinjaButton>
                  )
                }
                {
                  (isMountedBot && ![BotStatusEnum.RUNNING_LONG, BotStatusEnum.RUNNING_SHORT].includes(bot.status)) && (
                    <TradeNinjaButton
                      onClick={() => onEnableBot(bot._id, !bot.enabled)}
                      className="bot-details-enable-button"
                      variant="outlined">
                      {bot.enabled ? 'Disable Bot' : 'Enable Bot'}
                    </TradeNinjaButton>
                  )
                }
                {
                  (!isMountedBot) && (
                    <NinjaTooltip message="Mount the bot making it available">
                      <TradeNinjaButton
                        onClick={() => {
                            openDialogConfirm(`Are you sure you want to mount this bot and make it available?`, button => {
                              if (button === 'confirm') {
                                dispatch(mountBot({
                                  botId: bot._id, force: false, callback: (resp: { code?: number, message?: string, bots?: any[] }) => {
                                    if (resp.bots && resp.bots.length > 0) {
                                      if (setSelectedBotId) setSelectedBotId(resp.bots[0]._id)
                                      enqueueSnackbar(`Bot "${bot.name}" mounted successfully`, { variant: 'success' })
                                    } else if (resp.code === 4002) {
                                      openDialogConfirm(`${resp.message} Do you want to force it to remount?`, button => {
                                        if (button === 'confirm') {
                                          alert('TODO: Finish it: ' + resp.message)
                                        }
                                      })
                                    }
                                  }
                                }))
                              }
                            })
                        }}
                        className="bot-details-enable-button"
                        variant="outlined"
                      >
                        Mount Bot
                      </TradeNinjaButton>
                    </NinjaTooltip>
                  )
                }
              </>
            )
          }
        </div>
      </div>

      <div className="bot-details-body">
        <div className="bot-details-field">
          <div className="bot-details-field-label"><span>Id:</span></div>
          <div className="bot-details-field-value">{bot._id}</div>
        </div>

        <div className="bot-details-field">
          <div className="bot-details-field-label"><span>Name:</span></div>
          <div className="bot-details-field-value">{bot.name}</div>
        </div>

        <div className="bot-details-field">
          <div className="bot-details-field-label"><span>Strategy:</span></div>
          <div className="bot-details-field-value">{typeof bot.strategy === 'object' ? bot.strategy.code : bot.strategy}</div>
        </div>

        {
          isMountedBot ? (
            <div className="bot-details-field">
              <div className="bot-details-field-label"><span>Symbol:</span></div>
              <div className="bot-details-field-value">{bot.symbol}</div>
            </div>
          ) : (
            <div className="bot-details-field">
              <div className="bot-details-field-label"><span>Symbols:</span></div>
              <NinjaTooltip message={JSON.stringify(bot.symbols, null, 2)}>
                <div className="bot-details-field-value" style={{
                  fontWeight: 100,
                  overflow: 'hidden',
                  height: '18px',
                  textOverflow: 'ellipsis',
                  maxWidth: '360px',
                  whiteSpace: 'nowrap'
                }}>
                  {JSON.stringify(bot.symbols, null, 2)}
                </div>
              </NinjaTooltip>
            </div>
          )
        }

        <div className="bot-details-field">
          <div className="bot-details-field-label"><span>Status:</span></div>
          <div className="bot-details-field-value">{isMountedBot ? bot.status : 'UNMOUNTED'}</div>
        </div>

        {
          isMountedBot && (
            <>
              <div className="bot-details-field">
                <div className="bot-details-field-label"><span>Time Frames:</span></div>
                <div className="bot-details-field-value">{JSON.stringify(bot.timeFrames)}</div>
              </div>
              <div className="bot-details-field">
                <div className="bot-details-field-label"><span>Allowed Sides:</span></div>
                <div className="bot-details-field-value">{JSON.stringify(bot.allowedSides)}</div>
              </div>

              {
                bot.limitCandles && (
                  <div className="bot-details-field">
                    <div className="bot-details-field-label"><span>Limit Candles:</span></div>
                    <div className="bot-details-field-value">{bot.limitCandles}</div>
                  </div>
                )
              }

              {
                bot.doNotTradeTimes && bot.doNotTradeTimes.length > 0 && (
                  <div className="bot-details-field">
                    <div className="bot-details-field-label"><span>Skip Times:</span></div>
                    <div className="bot-details-field-value">{bot.doNotTradeTimes.join(', ')}</div>
                  </div>
                )
              }

              {
                bot.processBeforeCandleClose && (
                  <div className="bot-details-field">
                    <div className="bot-details-field-label"><span>Process before candle close:</span></div>
                    <div className="bot-details-field-value">{bot.processBeforeCandleClose} mins.</div>
                  </div>
                )
              }
            </>
          )
        }
      </div>

      <div className="bot-details-tabs-container">
        <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}>
          {
            hasPosition && (
              <TradeNinjaTab label="Position" />
            )
          }
          {
            !hasPosition && (
              <TradeNinjaTab label="Entry Conditions" />
            )
          }
          <TradeNinjaTab label="Take Profit" />
          <TradeNinjaTab label="Orders" />
          <TradeNinjaTab label="Advanced" />
        </TradeNinjaTabs>
      </div>
      <div className="bot-details-tabs-body">
        <pre>
          {
            (hasPosition && selectedTab === 0) && (
              JSON.stringify(positionDetails, null, 2)
            )
          }
          {
            (hasPosition && selectedTab === 2) && (
              JSON.stringify(bot.position.orders, null, 2)
            )
          }
        </pre>
        {
          (selectedTab === 3) && (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'end', paddingRight: '14px', paddingTop: '8px' }}>
              <Button
                disabled={!bot || [BotStatusEnum.RUNNING_LONG, BotStatusEnum.RUNNING_SHORT, BotStatusEnum.WAITING_TO_CLOSE_POSITION].includes(bot.status)}
                onClick={() => {
                  if ([BotStatusEnum.RUNNING_LONG, BotStatusEnum.RUNNING_SHORT].includes(bot.status)) {
                    openDialogWarning(`Bot "${bot.name}" can not be delete, because it is running (${bot.status}).`)
                  } else {
                    openDialogConfirm(`Are you sure you want to delete the bot "${bot.name}"?`, button => {
                      if (button === 'confirm') {
                        dispatch(deleteMountedBot({ botId: bot._id }))
                      }
                    })
                  }
                }}
                variant="contained"
                color="error"
              >
                Delete Bot
              </Button>
            </div>
          )
        }
      </div>
    </div>
  )
}

export default BotDetails