import { useDispatch, useSelector } from 'react-redux'
import './BotsPage.scss'
import { AppDispatch, RootState } from '../../state/store';
import { enableBot, getAllBots, getLastExecutions, getStrategies } from '../../state/bot/botSlice';
import BotWidget from './components/BotWidget';
import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Alert, Button, Drawer } from '@mui/material';
import BotDetails from './components/BotDetails';
import AppHeader from '../../components/AppHeader';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';
import { styled, alpha } from '@mui/material/styles';
import { BotStatusEnum } from '../../types';
import classNames from 'classnames';
import { BOX_DETAILS_DRAWER_WIDTH } from '../../utils/constants';
// import LoadingFirestoreData from '../../components/LoadingFirestoreData';
import { usePrices, useMountedBots } from '../../hooks';
import getPositionDetails from '../../utils/getPositionDetails';
import LoadingData from '../../components/LoadingData';
import TradeNinjaTabs from '../../components/TradeNinjaTabs';
import TradeNinjaTab from '../../components/TradeNinjaTab';
import BuyAndHoldModal from '../../modals/BuyAndHoldModal';
import SellAndHoldModal from '../../modals/SellAndHoldModal';
import LastExecutions from './components/LastExecutions';
import OpenOrders from './components/OpenOrders';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width:
      'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const BotsPage = () => {
  const prices = usePrices()
  const user = useSelector((state: RootState) => state.user);
  const allBots = useSelector((state: RootState) => state.bot.allBots);
  const strategies = useSelector((state: RootState) => state.bot.strategies);
  const allMountedBots = useMountedBots()
  const dispatch = useDispatch<AppDispatch>()
  const [selectedTab, setSelectedTab] = useState(0)
  const [selectedBotId, setSelectedBotId] = useState<any>(null)
  const [selectedBot, setSelectedBot] = useState<any>()
  const [showDetails, setShowDetails] = useState(false)
  const [isBotDetailsPinned, setBotDetailsPinned] = useState(false)
  const [runningBots, setRunningBots] = useState<any[]>([])
  const [disabledBots, setDisabledBots] = useState<any[]>([])
  const [waitingBots, setWaitingBots] = useState<any[]>([])
  const [openBuyAndHold, setOpenBuyAndHold] = useState(false)
  const [openSellAndHold, setOpenSellAndHold] = useState(false)

  useEffect(() => {
    dispatch(getAllBots())
    dispatch(getStrategies())
  }, [dispatch])

  useEffect(() => {
    if (selectedTab === 1) {
      dispatch(getLastExecutions())
    }
  }, [selectedTab, dispatch])

  useEffect(() => {
    setRunningBots(
      allMountedBots.data
        .filter(itm => [BotStatusEnum.RUNNING_LONG, BotStatusEnum.RUNNING_SHORT, BotStatusEnum.WAITING_TO_CLOSE_POSITION].includes(itm.status))
        .sort((bot1: any, bot2: any) => {
          const positionDetails1 = getPositionDetails(bot1.position.entry.side, bot1.position.orders, prices[bot1.symbol])
          const positionDetails2 = getPositionDetails(bot2.position.entry.side, bot2.position.orders, prices[bot2.symbol])
          if (positionDetails1.profitPerc > positionDetails2.profitPerc) return -1
          if (positionDetails1.profitPerc < positionDetails2.profitPerc) return 1
          return 0
        })
    )

    setDisabledBots(allMountedBots.data.filter(itm => !itm.enabled))
    setWaitingBots(allMountedBots.data.filter(itm => itm.enabled && itm.status === 'WAITING'))

  }, [allMountedBots, prices])

  useEffect(() => {
    if (selectedBotId) {
      const selected = allMountedBots.data.concat(allBots.data).find(itm => itm._id === selectedBotId)
      if (selected) {
        setSelectedBot({ ...selected, strategy: selected.strategy || strategies.data.find(itm => selected.strategyId === itm._id) })
      } else {
        setSelectedBotId(allMountedBots.data[0]._id)
      }
    }
  }, [allMountedBots, selectedBotId, allBots.data, strategies.data])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const onClickBot = (bot: any) => {
    debugger
    setSelectedBotId(bot._id)
    setShowDetails(true)
  }

  return (
    <div className='bots-page-container'>
      <BuyAndHoldModal open={openBuyAndHold} setOpen={setOpenBuyAndHold} callback={(createdBot) => setSelectedBotId(createdBot._id)} />
      <SellAndHoldModal open={openSellAndHold} setOpen={setOpenSellAndHold} callback={(createdBot) => setSelectedBotId(createdBot._id)} />
      <LoadingData loading={allMountedBots.loading || allBots.loading || strategies.loading}>
        <AppHeader title="Bots" paddingRight={isBotDetailsPinned ? BOX_DETAILS_DRAWER_WIDTH : undefined}>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
        </AppHeader>
        {
          !user.ibGatewayconnected && (
            <Alert sx={{ borderBottom: 'solid 1px #fecaca' }} severity="error">Connectivity between IB Gateway has been lost.</Alert>
          )
        }
        {/* <LoadingFirestoreData colletionId='mountedBots' setData={setAllBots} > */}
        <div className="bots-page" style={{ paddingRight: isBotDetailsPinned ? BOX_DETAILS_DRAWER_WIDTH : 0 }}>
          <div className='bots-page-body'>
            <div className='bots-page-action-buttons-container'>
              {
                selectedTab === 0 && (
                  <div className='bots-page-action-buttons'>
                    <Button size='small' variant='outlined' onClick={() => setOpenBuyAndHold(true)}>Long Position</Button>
                    <Button size='small' variant='outlined' color="error" onClick={() => setOpenSellAndHold(true)}>Short Position</Button>
                    <Button onClick={() => { alert('sdajflçkdjsaflkdjf') }} size='small' variant='outlined' color='secondary'>Dividend Capture</Button>
                  </div>
                )
              }
            </div>
            <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}>
              <TradeNinjaTab label='Bots' />
              <TradeNinjaTab label='Last Executions' />
              <TradeNinjaTab label='Open Orders' />
            </TradeNinjaTabs>
            {
              selectedTab === 0 && (
                <>
                  <div className={classNames('bots-items', { running: true })}>
                    {runningBots.map(bot => <BotWidget key={bot._id} prices={prices} isDisabled={false} isSelected={selectedBotId === bot._id} bot={bot} onClick={onClickBot} />)}
                  </div>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Waiting
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='bots-items'>
                        {waitingBots.map(bot => <BotWidget key={bot._id} prices={prices} isDisabled={false} isSelected={selectedBotId === bot._id} bot={bot} onClick={onClickBot} />)}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Disabled
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='bots-items'>
                        {disabledBots.map(bot => <BotWidget key={bot._id} prices={prices} isDisabled={true} isSelected={selectedBotId === bot._id} bot={bot} onClick={onClickBot} />)}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      Unmounted Bots
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className='bots-items'>
                        {
                          allBots.data
                            .filter(bot => !allMountedBots.data.some(itm => itm.botId === bot._id))
                            .map(bot => (
                              <BotWidget
                                key={bot._id}
                                prices={prices}
                                isDisabled={true}
                                isSelected={selectedBotId === bot._id}
                                bot={bot}
                                onClick={onClickBot}
                              />
                            ))
                        }
                      </div>
                    </AccordionDetails>
                  </Accordion>
                  <Drawer
                    variant={isBotDetailsPinned ? 'permanent' : 'temporary'}
                    sx={{
                      display: { xs: 'none', sm: 'block' },
                      '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                      '& .MuiPaper-root': { width: BOX_DETAILS_DRAWER_WIDTH }
                    }}
                    anchor={'right'}
                    open={showDetails}
                    onClose={() => setShowDetails(false)}
                  // onClose={toggleDrawer(anchor, false)}
                  >
                    <BotDetails
                      isMountedBot={selectedBot && allMountedBots.data.some(itm => itm._id === selectedBot._id)}
                      bot={selectedBot}
                      isPinned={isBotDetailsPinned}
                      setPinned={setBotDetailsPinned}
                      prices={prices}
                      setSelectedBotId={setSelectedBotId}
                      onEnableBot={(botId, enable) => {
                        dispatch(enableBot({ botId, enabled: !selectedBot.enabled }))
                      }}
                    />
                  </Drawer>
                </>
              )
            }
            {selectedTab === 1 && <LastExecutions allMountedBots={allMountedBots} />}
            {selectedTab === 2 && <OpenOrders allMountedBots={allMountedBots} />}
          </div>
        </div>
      </LoadingData>
      {/* </LoadingFirestoreData> */}
    </div>
  )
}

export default BotsPage