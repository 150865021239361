import { FC } from 'react';
import { ReactCodeMirrorProps, ReactCodeMirrorRef } from '@uiw/react-codemirror';
import CodeMirror from "@uiw/react-codemirror";
import './NinjaCodeEditor.scss'


const NinjaCodeEditor: FC<ReactCodeMirrorProps & React.RefAttributes<ReactCodeMirrorRef>> = props => {
  return (
    <div className='ninja-code-editor-container'>
      <CodeMirror
        // theme={"dark"}
        {...props}
      />
    </div>
  )
}

export default NinjaCodeEditor