import { useDispatch, useSelector } from 'react-redux';
import NinjaTextEditor from '../../../../components/NinjaTextEditor'
import './StocksNotes.scss'
import { AppDispatch, RootState } from '../../../../state/store';
import { IconButton } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import { updateUser } from '../../../../state/user/userSlice';
import { useState } from 'react';

const StocksNotes = () => {
  const user = useSelector((state: RootState) => state.user);  
  const [notes, setNotes] = useState(user.user.data!.notes || '')
  const [editing, setEditing] = useState(false)
  const dispatch = useDispatch<AppDispatch>()
  const screen = useSelector((state: RootState) => state.app.screen);

  return (
    <div className="stocks-notes-container">
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', paddingRight: '20px', paddingTop: '4px' }}>
        <IconButton
          disabled={!editing}
          color="primary"
          style={{ marginBottom: '-40px', zIndex: 9 }}
          onClick={() => {
            setEditing(false)
            dispatch(updateUser({ notes }))
          }}>
          <SaveIcon />
        </IconButton>
      </div>
      <NinjaTextEditor
        height={`${screen.height - 180}px`}
        defaultValue={notes}
        onChange={value => {
          setNotes(value)
        }}
        onKeyUp={() => {
          setEditing(true)
        }}
        html={notes}
      />
    </div>
  )
}

export default StocksNotes