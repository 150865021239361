import { Button, Paper } from '@mui/material'
import { z } from 'zod'
import './SigninPage.scss'
import NinjaForm from '../../components/NinjaForm'
import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../state/store'
import { signIn } from '../../state/user/userSlice'
import { useNavigate } from 'react-router-dom';

const SigninPage = () => {
  const signinFormRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.user.data) navigate('/bots');
  }, [user.user.data, navigate])

  useEffect(() => {
    const firstInput = signinFormRef.current?.querySelector('input')
    firstInput?.focus()
  }, [])

  const onSubmit = (data: any) => {
    dispatch(signIn({ email: data.email, password: data.password }))
  }

  return (
    <div className="signin-page-container">
      <div className="signin-page-body">
        <Paper elevation={2}>
          <NinjaForm formRef={signinFormRef} OnSubmit={onSubmit} fields={[
            { name: 'email', label: 'Email', zod: z.string({ required_error: 'Email is required' }).email(), gridWidth: 12 },
            { name: 'password', label: 'Password', zod: z.string({ required_error: 'Password is required' }), gridWidth: 12 },
          ]} />
          <div className="signin-page-buttons">
            <div className="signin-page-error-message">{user.signinErrorMessage}</div>
            <Button
              disabled={user.user.loading}
              size='large'
              className="signin-page-button"
              onClick={() => signinFormRef.current?.requestSubmit()} variant="contained"
            >
              {user.user.loading ? 'loading...' : 'Sign in'}
            </Button>
          </div>
        </Paper>
      </div >
    </div >
  )
}

export default SigninPage