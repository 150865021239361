import { useDispatch, useSelector } from "react-redux"
import AppHeader from "../../components/AppHeader"
import './ConfigBotsPage.scss'
import { AppDispatch, RootState } from "../../state/store"
import { useEffect, useState } from "react"
import { deleteBotAsync, getBotsByStrategy, getStrategies, mountBot, updateBot, updateStrategy } from "../../state/bot/botSlice"
import LoadingData from "../../components/LoadingData"
import { json } from '@codemirror/lang-json';
import TradeNinjaTabs from "../../components/TradeNinjaTabs"
import TradeNinjaTab from "../../components/TradeNinjaTab"
import NinjaTextEditor from "../../components/NinjaTextEditor"
import { Button } from "@mui/material"
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import { useSnackbar } from 'notistack';
import NinjaCodeEditor from "../../components/NinjaCodeEditor"
import { useSearchParams } from "react-router-dom"
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NinjaTooltip from "../../components/NinjaTooltip"
import PublicIcon from '@mui/icons-material/Public';
import ConstructionIcon from '@mui/icons-material/Construction';
import NewBotModal from "../../modals/NewBotModal"
import { openDialogConfirm } from "../../utils/openDialog"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NinjaSelect from "../../components/NinjaSelect"
import IconButton from '@mui/material/IconButton';

const ConfigBotsPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const strategies = useSelector((state: RootState) => state.bot.strategies);
  const botsByStrategy = useSelector((state: RootState) => state.bot.botsByStrategy);
  const screen = useSelector((state: RootState) => state.app.screen);
  const [selectedStrategyTab, setSelectedStrategyTab] = useState(0)
  const [selectedStrategy, setSelectedStrategy] = useState<any>()
  const [selectedStrategyObj, setSelectedStrategyObj] = useState<any>()
  const [editingStrategy, setEditingStrategy] = useState<boolean>(false)
  const [editingBot, setEditingBot] = useState<boolean>(false)
  const [strategyDescription, setStrategyDescription] = useState<string>('')
  const [strategyCode, setStrategyCode] = useState<string>('')
  const [strategyCodeValues, setStrategyCodeValues] = useState<string>('')
  const [selectedBot, setSelectedBot] = useState<string | undefined>()
  const [selectedBotObj, setSelectedBotObj] = useState<any>()
  const [botCode, setBotCode] = useState<string>('')
  const [botCodeValues, setBotCodeValues] = useState<string>('')
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const [openNewBotModal, setOpenNewBotModal] = useState<boolean>(false)

  useEffect(() => {
    dispatch(getStrategies())
  }, [dispatch])

  useEffect(() => {
    if (strategies.data && strategies.data.length > 0) {
      const qryStringStrategyId = searchParams.get('strategy')
      if (qryStringStrategyId) {
        const foundStrategy = strategies.data.find(itm => itm._id === qryStringStrategyId)
        setSelectedStrategy({ value: foundStrategy._id, label: foundStrategy.code })
      } else {
        setSelectedStrategy({ value: strategies.data[0]._id, label: strategies.data[0].code })
      }
      const strategyTabIndex = searchParams.get('strategyTab')
      if (strategyTabIndex) setSelectedStrategyTab(parseInt(strategyTabIndex))
    }
  }, [strategies.data, dispatch, searchParams])

  useEffect(() => {
    if (selectedStrategy?.value) {
      dispatch(getBotsByStrategy({ strategyId: selectedStrategy.value }))
    }
  }, [selectedStrategy?.value, dispatch])

  useEffect(() => {
    if (selectedStrategy) {
      setSelectedStrategyObj(strategies.data.find(itm => itm._id === selectedStrategy.value))
    }
  }, [selectedStrategy, strategies.data])

  useEffect(() => {
    if (selectedStrategyObj) {
      setStrategyCode(JSON.stringify(normalizeStrategyCode(selectedStrategyObj), null, 2))
      setStrategyCodeValues(JSON.stringify(selectedStrategyObj.values, null, 2))
      setStrategyDescription(selectedStrategyObj.description)
    }
  }, [selectedStrategyObj])

  useEffect(() => {
    if (botsByStrategy.data && botsByStrategy.data.length > 0) {
      const searchParamsBotId = searchParams.get('bot')
      if (searchParamsBotId) setSelectedBot(searchParamsBotId)
    }
  }, [botsByStrategy.data, searchParams])

  useEffect(() => {
    if (selectedBot) {
      setSelectedBotObj(botsByStrategy.data.find(itm => itm._id === selectedBot))
    }
  }, [selectedBot, botsByStrategy.data])

  useEffect(() => {
    if (selectedBotObj) {
      setBotCode(JSON.stringify(normalizeBotCode(selectedBotObj), null, 2))
      setBotCodeValues(JSON.stringify(selectedBotObj.values, null, 2))
    }
  }, [selectedBotObj])

  const handleStrategyTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedStrategyTab(newValue);
    setSearchParams(params => {
      params.set("strategyTab", newValue.toString());
      return params;
    });
  };

  const normalizeStrategyCode = (stratObj: any) => {
    const toReturn: any = {}
    if (stratObj) {
      const strategyProps = [
        'code', 'timeFrames', 'allowedSides', 'longEntryConditions', 'longStopLoss', 'longTakeProfit', 'shortEntryConditions', 'shortStopLoss',
        'shortTakeProfit', 'extendedTradingHours', 'resetSetupOnExtendedTradingHours', 'martinGale', 'limitCandles'
      ]
      strategyProps.forEach((prop: string) => {
        if (stratObj[prop] !== undefined) toReturn[prop] = stratObj[prop]
      })
    }
    return toReturn
  }

  const normalizeBotCode = (botObj: any) => {
    const botProps = ['name', 'symbols', 'doNotTradeTimes', 'limitCandles']
    const toReturn: any = {}
    botProps.forEach((prop: string) => {
      if (botObj[prop] !== undefined) toReturn[prop] = botObj[prop]
    })
    return toReturn
  }

  const saveStrategy = () => {
    try {
      const strategy = { ...JSON.parse(strategyCode), values: JSON.parse(strategyCodeValues), description: strategyDescription }
      dispatch(updateStrategy({
        strategyId: selectedStrategy.value, data: {
          ...strategy
        }, callback: () => {
          enqueueSnackbar('Strategy updated successfully', { variant: 'success' })
        }
      }))
    } catch (error: any) {
      enqueueSnackbar(`Error in the strategy code: ${error.message}`, { variant: 'error' })
    }
  }

  const saveBot = () => {
    try {
      const bot = { ...JSON.parse(botCode), values: JSON.parse(botCodeValues) }
      dispatch(updateBot({
        botId: selectedBot!, data: {
          ...bot
        }, callback: () => {
          enqueueSnackbar('Bot updated successfully', { variant: 'success' })
        }
      }))
    } catch (error: any) {
      enqueueSnackbar(`Error in the bot code: ${error.message}`, { variant: 'error' })
    }
  }
  {
    const runBackTest = () => {
      window.open(`${window.location.origin}/backtests?bot=${selectedBot}`, '_blank', 'noreferrer')
    }

    const thereIsBots = botsByStrategy && botsByStrategy.data && botsByStrategy.data.length > 0

    return (
      <div className="config-bots-page-container">
        <NewBotModal strategyId={selectedStrategy?.value} open={openNewBotModal} setOpen={setOpenNewBotModal} callback={(createdBot) => {
          if (createdBot) {
            setTimeout(() => {
              setSelectedBot(createdBot._id)
              setSearchParams(params => {
                params.set("bot", createdBot._id);
                return params;
              });
            }, 100)
          }
        }} />
        <AppHeader title="Config Bots">
        </AppHeader>
        <LoadingData loading={strategies.loading}>
          <div className="config-bots-toolbar-container">
            <div className="config-bots-toolbar">
              <Button
                variant="outlined"
                color="success"
                disabled={(!selectedStrategyObj || (!editingStrategy && !editingBot))}
                startIcon={<SaveIcon />}
                onClick={() => {
                  if (editingStrategy) saveStrategy()
                  if (editingBot) saveBot()
                  setEditingStrategy(false)
                  setEditingBot(false)
                }}
              >Save Changes</Button>
              <Button
                variant="outlined"
                color="warning"
                disabled={(!selectedStrategyObj || (!editingStrategy && !editingBot))}
                startIcon={<ClearIcon />}
                onClick={() => {
                  setStrategyCode(JSON.stringify(normalizeStrategyCode(selectedStrategyObj), null, 2))
                  setStrategyCodeValues(JSON.stringify(selectedStrategyObj.values, null, 2))
                  if (selectedBotObj) {
                    setBotCode(JSON.stringify(normalizeBotCode(selectedBotObj), null, 2))
                    setBotCodeValues(JSON.stringify(selectedBotObj.values, null, 2))
                  }
                  setEditingStrategy(false)
                  setEditingBot(false)
                }}
              >Cancel Changes</Button>
            </div>
          </div>
          <div className="config-bots-select-strategy-container">
            <NinjaSelect
              aria-label="Strategy"
              className="config-bots-select-strategy"
              isDisabled={(botsByStrategy.loading || editingStrategy || editingBot)}
              value={selectedStrategy}
              isLoading={botsByStrategy.loading}
              isClearable={false}
              isSearchable={true}
              name="color"
              options={
                strategies
                  .data
                  .filter(strategy => !['BUY_AND_HOLD', 'SELL_AND_HOLD'].includes(strategy.code))
                  .map(strategy => ({ value: strategy._id, label: strategy.code }))
              }
              onChange={(option: any) => {
                setSelectedStrategy(option)
                setSearchParams(params => {
                  params.set("strategy", option.value);
                  return params;
                });
              }}
            />
          </div>
          <div className="config-bots-body" style={{ height: `${screen.height - 64}px` }}>
            {/* <LoadingData loading={botsByStrategy.loading}> */}
            <div className="config-bots-left-panel">
              <TradeNinjaTabs value={selectedStrategyTab} onChange={(handleStrategyTabChange)}>
                <TradeNinjaTab label="Strategy Description" />
                <TradeNinjaTab label="Statrategy Config" />
              </TradeNinjaTabs>

              {selectedStrategyTab === 0 && selectedStrategyObj && (
                <NinjaTextEditor
                  height={`${screen.height - 266}px`}
                  defaultValue={strategyDescription}
                  onChange={value => {
                    setStrategyDescription(value)
                  }}
                  onKeyUp={() => {
                    setEditingStrategy(true)
                  }}
                  html={strategyDescription}
                />
              )}
              {selectedStrategyTab === 1 && (
                <div>
                  <NinjaCodeEditor
                    value={strategyCode}
                    onChange={value => {
                      setEditingStrategy(true)
                      setStrategyCode(value)
                    }}
                    height={`${Math.trunc(screen.height / 2) - 112}px`}
                    extensions={[json()]}
                  />
                  <div className="config-bots-editor-divisor" />
                  <NinjaCodeEditor
                    value={strategyCodeValues}
                    onChange={value => {
                      setEditingStrategy(true)
                      setStrategyCodeValues(value)
                    }}
                    height={`${Math.trunc(screen.height / 2) - 112}px`}
                    extensions={[json()]}
                  />
                </div>
              )
              }
            </div>
            <div className="config-bots-right-panel">
              <div className="config-bots-right-panel-header-container">
                <div className="config-bots-right-panel-header">
                  <NinjaTooltip message="Add a new bot to the selected strategy">
                    <Button variant="contained" onClick={() => setOpenNewBotModal(true)} startIcon={<AddCircleOutlineIcon />} disabled={!selectedStrategyObj || editingStrategy || editingBot} size="small">New Bot</Button>
                  </NinjaTooltip>
                  <NinjaTooltip message="Mount the bot making it available">
                    <Button variant="contained" onClick={() => {
                      openDialogConfirm(`Are you sure you want to mount this bot and make it available?`, button => {
                        if (button === 'confirm') {
                          dispatch(mountBot({
                            botId: selectedBot!, force: false, callback: (resp: { code?: number, message?: string, bots?: any[] }) => {
                              if (resp.bots && resp.bots.length > 0) {
                                enqueueSnackbar(`Bot "${selectedBotObj.name}" mounted successfully`, { variant: 'success' })
                              } else if (resp.code === 4002) {
                                openDialogConfirm(`${resp.message} Do you want to force it to remount?`, button => {
                                  if (button === 'confirm') {
                                    alert('TODO: Finish it: ' + resp.message)
                                  }
                                })
                              }
                            }
                          }))
                        }
                      })
                    }} startIcon={<PublicIcon />} disabled={!selectedStrategyObj || !selectedBotObj || editingStrategy || editingBot} size="small">Mount Bot</Button>
                  </NinjaTooltip>
                  <NinjaTooltip message="Run a backtest with the selected bot.">
                    <Button variant="contained" onClick={runBackTest} startIcon={<ConstructionIcon />} disabled={!selectedStrategyObj || !selectedBotObj || editingStrategy || editingBot} size="small">Run BackTest</Button>
                  </NinjaTooltip>
                </div>
              </div>
              {
                thereIsBots ? (
                  <>
                    <div className="config-bots-select-bot-container">
                      <NinjaSelect
                        aria-label="Bot"
                        className="config-bots-select-bot"
                        isDisabled={(botsByStrategy.loading || editingStrategy || editingBot)}
                        value={selectedBotObj && selectedBotObj._id ? { value: selectedBotObj._id, label: selectedBotObj.name } : null}
                        isLoading={botsByStrategy.loading}
                        isClearable={false}
                        isSearchable={true}
                        options={botsByStrategy.data.map(bot => ({ value: bot._id, label: bot.name }))}
                        onChange={(option: any) => {
                          setSelectedBot(option.value)
                          setSearchParams(params => {
                            params.set("bot", option.value);
                            return params;
                          });
                        }}
                      />
                      <NinjaTooltip message="Delete the selected bot">
                        <IconButton disabled={!selectedBot} onClick={() => {
                          openDialogConfirm(`Are you sure you want to delete this bot?`, button => {
                            if (button === 'confirm') {
                              dispatch(deleteBotAsync({
                                botId: selectedBot!, callback: (deletedBotId) => {
                                  enqueueSnackbar('Bot deleted successfully', { variant: 'success' })
                                }
                              }))
                            }
                          })
                        }}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </NinjaTooltip>
                    </div>
                    {/* <DataGrid
                      height={heightForBotsDataGrid}
                      data={botsByStrategy.data}
                      columns={[
                        { name: 'name', title: 'Bot', width: '30%' },
                        {
                          name: 'symbols', title: 'Symbols', width: '64%', renderer: (data: any, record: any) => {
                            return record.symbols.join(',')
                          }
                        },
                        {
                          name: '_id', title: '', width: '6%', renderer: (data: any, record: any) => {
                            return <DeleteForeverIcon onClick={() => {
                              openDialogConfirm(`Are you sure you want to delete this bot?`, button => {
                                if (button === 'confirm') {
                                  dispatch(deleteBotAsync({
                                    botId: record._id, callback: (deletedBotId) => {
                                      enqueueSnackbar('Bot deleted successfully', { variant: 'success' })
                                    }
                                  }))
                                }
                              })
                            }} className="delete-bot-icon" />
                          }
                        },
                      ]}
                      selectable={true}
                      shouldSelectRow={(record: any) => record._id === selectedBot}
                      onSelectRecord={(record: any) => {
                        setSelectedBot(record._id)
                        setSearchParams(params => {
                          params.set("bot", record._id);
                          return params;
                        });
                      }}
                    /> */}
                    <NinjaCodeEditor
                      value={botCode}
                      onChange={value => {
                        setEditingBot(true)
                        setBotCode(value)
                      }}
                      height={`${Math.trunc(screen.height / 2) - 113}px`}
                      extensions={[json()]}
                    />
                    <div className="config-bots-editor-divisor" />
                    <NinjaCodeEditor
                      value={botCodeValues}
                      onChange={value => {
                        setEditingBot(true)
                        setBotCodeValues(value)
                      }}
                      height={`${Math.trunc(screen.height / 2) - 110}px`}
                      extensions={[json()]}
                    />
                  </>
                ) : <div style={{ color: '#64748b', fontStyle: 'italic' }}>No Bots found for this strategy.</div>
              }
            </div>
            {/* </LoadingData> */}
          </div>
        </LoadingData>
      </div>
    )
  }
}
export default ConfigBotsPage;