import axios from "axios"
import { TRADENINJA_API_URL } from "./constants"
import { enqueueSnackbar } from "notistack"

class ApiClient {

  static get(path: string): Promise<any> {
    return axios.get(`${TRADENINJA_API_URL}${path}`, { headers: this._getHeader() }).then(resp => resp.data).catch(this._getHandleErrorResponse)
  }

  static post(path: string, data?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      axios.post(`${TRADENINJA_API_URL}${path}`, data, { headers: this._getHeader() })
        .then(resp => resolve(resp.data))
        .catch(this._getHandleErrorResponse)
    })
  }

  static put(path: string, data?: any): Promise<any> {
    return axios.put(`${TRADENINJA_API_URL}${path}`, data, { headers: this._getHeader() }).then(resp => resp.data).catch(this._getHandleErrorResponse)
  }

  static delete(path: string): Promise<any> {
    return axios.delete(`${TRADENINJA_API_URL}${path}`, { headers: this._getHeader() }).then(resp => resp.data).catch(this._getHandleErrorResponse)
  }

  static _getHandleErrorResponse = (error: any): any => {
    switch (error.response.status) {
      case 401:
        window.location.href = 'signin'
        break;
      case 400:
      case 500:
        enqueueSnackbar(error.response.data.message, { variant: 'error' })
        break;      
      default:
        break;
    }
  }

  static _getHeader() {
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${localStorage.getItem('tradeninja-token')}`
    }
  }

}

export default ApiClient
