
import { FC, useEffect, useState } from 'react'
import './PerformanceAnalysisRenderer.scss'
import getBackTestOverviewProps from '../../../../utils/getBackTestOverviewProps'
import LoadingData from '../../../LoadingData'
import { formatNumber } from 'humanize-plus'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import DataGridModal from '../../../DataGridModal'

type PerformanceAnalysisRendererProps = {
  backTests: any[]
  items: any
  titleRenderer?: (title: string) => string
  breakBotsBySymbol?: boolean
}

const getPositionsByBots = (backTests: any[], items: any, objKeys: string[], breakBotsBySymbol?: boolean) => {
  return objKeys.reduce((acc: any, key: string) => {
    const positions: any[] = items[key]

    acc[key] = positions.reduce((acc2: any, position: any) => {
      const backTest = backTests.find(btItm => btItm._id === position.backTestId)
      if (backTest) {
        const bot = backTest.botOriginal
        const botKey = breakBotsBySymbol ? `${bot._id}-${position.symbol}` : bot._id
        if (!acc2[botKey]) acc2[botKey] = {
          _id: bot._id,
          backTestId: backTest._id,
          name: bot.name,
          strategy: {
            _id: bot.strategy._id,
            code: bot.strategy.code
          },
          symbols: bot.symbols,
          positions: []
        }
        acc2[botKey].positions.push({ ...position })
      }
      return acc2
    }, {})

    return acc
  }, {})
}
const PerformanceAnalysisRenderer: FC<PerformanceAnalysisRendererProps> = ({ backTests, items, titleRenderer, breakBotsBySymbol }) => {
  const [keys, setKeys] = useState<string[]>([])
  const [positionsByBots, setPositionsByBots] = useState<any>({})
  const [openTradesModal, setOpenTradesModal] = useState<boolean>(false)
  const [positionsTradesModal, setPositionsTradesModal] = useState<any[]>([])
  const [titleTradesModal, setTitleTradesModal] = useState<string>('')

  const setResultsToBots = (positionsByBots: any) => {
    const keysToUse = Object.keys(positionsByBots)
    for (const keyToUse of keysToUse) {
      const bots = positionsByBots[keyToUse]
      for (const botId of Object.keys(bots)) {
        const bot = bots[botId]
        bot.results = getBackTestOverviewProps(50000, bot.positions)
      }
    }
  }

  useEffect(() => {
    const keysToUse = Object.keys(items).sort((a: string, b: string) => {
      if (a > b) return -1
      if (a < b) return 1
      return 0
    })
    setKeys(keysToUse)
    const positionsByBots = getPositionsByBots(backTests, items, keysToUse, breakBotsBySymbol)
    setResultsToBots(positionsByBots)
    setPositionsByBots(positionsByBots)

  }, [backTests, items, breakBotsBySymbol])

  const getPositionsFromBotsOrderedByProfits = (botsWithPositions: any): any[] => {
    const botsIds = Object.keys(botsWithPositions)
    return botsIds.map(botId => botsWithPositions[botId]).sort((a, b) => {
      if (a.results.totalProfits > b.results.totalProfits) return -1
      if (a.results.totalProfits < b.results.totalProfits) return 1
      return 0
    })
  }

  const initialCapital = 50000

  return (
    <div className="performance-analysis-renderer-container">
      <DataGridModal
        title={titleTradesModal}
        open={openTradesModal}
        setOpen={setOpenTradesModal}
        data={positionsTradesModal}
        columns={[
          {
            name: 'symbol', title: 'Side', width: '10%', renderer: (data, record) => {
              return record.entry.side
            }
          },
          { name: 'symbol', title: 'Symbol', width: '10%' },
          { name: 'stopLoss', title: 'Stop Loss', width: '8%', type: 'number', align: 'right' },
          {
            name: 'entry.datetime', title: 'Entry at', width: '26%', align: 'center', renderer: (data, record) => {
              return record.entry.datetime
            }
          },
          {
            name: 'exit.datetime', title: 'Exit at', width: '26%', align: 'center', renderer: (data, record) => {
              return record.exit.datetime
            }
          },
          {
            name: 'exit.proit', title: 'Profit', width: '10%', align: 'right', renderer: (data, record) => {
              const color = record.exit.profit >= 0 ? 'green' : 'red'
              return <span style={{ color }}>{formatNumber(record.exit.profit, 2)}</span>
            }
          },
        ]}
      />
      <LoadingData loading={!positionsByBots}>
        {
          keys.map(key => {
            const results = getBackTestOverviewProps(50000, items[key])
            const title = titleRenderer ? titleRenderer(key) : key
            return (
              <>
                <div className="performance-analysis-title">{title}</div>
                <div className="performance-analysis-subtitle-container">
                  <div className="performance-analysis-subtitle">
                    <span>P&L:</span>
                    <span style={{ color: results.totalProfits > 0 ? '#16a34a' : '#ef4444' }}>{formatNumber(results.totalProfits, 2)}</span>
                  </div>
                </div>
                <div className="performance-analysis-results-by-bots">
                  <div className="performance-analysis-results-by-bot-header">
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '40px', textAlign: 'center', padding: 0 }}><EmojiEventsIcon /></div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '170px' }}>Bot</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '120px', textAlign: 'right' }}>Net Profit</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '120px', textAlign: 'center' }}>Trades</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '120px', textAlign: 'right' }}>Perc. Profitable</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '150px', textAlign: 'right' }}>Profit Factor</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '170px', textAlign: 'right' }}>Max Drawdown</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '160px', textAlign: 'right' }}>Avg Trade</div>
                    <div className="performance-analysis-results-by-bot-header-itm" style={{ width: '150px', textAlign: 'center' }}>Avg # Bars in Trade</div>
                  </div>
                  <div className="performance-analysis-results-by-bot">
                    {
                      getPositionsFromBotsOrderedByProfits(positionsByBots[key]).map((botObj, idx) => {
                        const botUrl = `https://denimarlab.pro/config-bots?strategy=${botObj.strategy._id}&bot=${botObj._id}&strategyTab=1`
                        const avgTrade = botObj.results.totalProfits / botObj.results.totalTrades
                        const botLabel = breakBotsBySymbol ? `${botObj.name} - ${botObj.positions[0].symbol}` : botObj.name
                        return (
                          <div className="performance-analysis-results-by-bot-row" key={`${key}-${botObj._id}-${idx}`}>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '40px', textAlign: 'center' }}>{idx + 1}º</div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '170px' }}><a href={botUrl} target="_blank" rel="noreferrer">{botLabel}</a></div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '120px', textAlign: 'right', color: botObj.results.totalProfits > 0 ? '#16a34a' : '#ef4444' }}>{formatNumber(botObj.results.totalProfits, 2)}</div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '120px', textAlign: 'center' }}><a href="#void" onClick={() => {
                              setTitleTradesModal(title + ` - ${botObj.name}`)
                              setPositionsTradesModal(botObj.positions.sort((a: any, b: any) => {
                                if (a.exit.datetime > b.exit.datetime) return -1
                                if (a.exit.datetime < b.exit.datetime) return 1
                                return 0
                              }))
                              setOpenTradesModal(true)
                            }}>{botObj.results.totalTrades}</a></div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '120px', textAlign: 'right' }}>{formatNumber(botObj.results.percentProfitable, 2)}%</div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '150px', textAlign: 'right' }}>{typeof botObj.results.profitFactor === 'number' ? formatNumber(botObj.results.profitFactor, 3) : <AllInclusiveIcon fontSize='small' />}</div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '170px', textAlign: 'right' }}>{botObj.results.maxDrawdownValue > 0 ? `${formatNumber(botObj.results.maxDrawdownValue, 2)}(${formatNumber(botObj.results.maxDrawdownPerc, 2)}%)` : '0'}</div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '160px', textAlign: 'right' }}>{formatNumber(avgTrade, 2)} ({formatNumber(avgTrade * 100 / initialCapital, 2)}%)</div>
                            <div className="performance-analysis-results-by-bot-value" style={{ width: '150px', textAlign: 'center' }}>{formatNumber(botObj.results.avgBarsInTrades, 1)}</div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              </>
            )
          })
        }
      </LoadingData>
    </div>
  )
}

export default PerformanceAnalysisRenderer