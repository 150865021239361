import { LineChart, LineChartProps } from '@opd/g2plot-react'
import { FC, useEffect, useRef, useState } from 'react'
import moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../utils/constants'
import './PortfolioMaxPositionsByDayChart.scss'

type PortfolioMaxPositionsByDayChartProps = {
  positions: any[]
  startMonth: string
  endMonth: string
}

type GraphDataType = {
  date: string
  positionCount: number
}

const PortfolioMaxPositionsByDayChart: FC<PortfolioMaxPositionsByDayChartProps> = ({ positions, startMonth, endMonth }) => {
  const [graphConfig, setGraphConfig] = useState<LineChartProps>()
  const chartRef = useRef<any>()

  const normalizeDataForGraph = (startMonth: string, endMonth: string, positions: any[]): GraphDataType[] => {
    if (!startMonth || !endMonth || positions.length === 0) return []

    const startDate = startMonth.substring(0, 10)
    const endDate = endMonth.substring(0, 10)
    const currentDateMom = moment(startDate).tz(DEFAULT_TIMEZONE, true)
    const endDateMom = moment(endDate).tz(DEFAULT_TIMEZONE, true)
    const dateInterval: any = []
    while (currentDateMom.isSameOrBefore(endDateMom)) {
      dateInterval.push(currentDateMom.format('YYYY-MM-DD'))
      currentDateMom.add(1, 'day')
    }

    let positionCount = 0
    const positionCountByDate: GraphDataType[] = []
    for (const date of dateInterval) {
      const entryPositionsInDate = positions.filter(itm => itm.entry.datetime.substring(0, 10) === date).length
      const exitPositionsInDate = positions.filter(itm => itm.exit.datetime.substring(0, 10) === date).length
      positionCount = positionCount + entryPositionsInDate - exitPositionsInDate
      positionCountByDate.push({ date, positionCount })
    }

    return positionCountByDate
  }

  useEffect(() => {
    if (positions) {
      const graphData = normalizeDataForGraph(startMonth, endMonth, positions)
      setGraphConfig({
        height: 350,
        autoFit: true,
        xField: 'date',
        yField: 'positionCount',
        yAxis: {
          min: 45000,
        },
        xAxis: {
          label: null
        },
        style: {
          flex: 1
        },
        data: graphData,
        smooth: true,
        // tooltip: {
        //   formatter: (datum) => ({
        //     name: '<b>Balance</b>',
        //     value: formatNumber(datum.balance, 2)
        //   }),
        // },
      })
    }

  }, [positions, startMonth, endMonth])  

  return (
    <div className="portfolio-performance-chart-container">
      <LineChart {...graphConfig!} chartRef={chartRef} />
    </div>
  )
}

export default PortfolioMaxPositionsByDayChart