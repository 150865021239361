import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FC, ReactNode, useState } from 'react';
import IbkrStatus from '../IbkrStatus';
import DropDownMenu from '../DropDownMenu';
import { Logout, Settings } from '@mui/icons-material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ConstructionIcon from '@mui/icons-material/Construction';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import { useNavigate } from 'react-router-dom';
import NyseStatus from '../nyseStatus';
import WebSocketServerStatus from '../WebSocketServerStatus';
import { refreshUser } from '../../state/user/userSlice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../state/store';

type AppHeaderProps = {
  title: string
  paddingRight?: string
  children: ReactNode
}

const AppHeader: FC<AppHeaderProps> = ({ title, paddingRight, children }) => {
  const [accountMenuButtonAnchorEl, setAccountMenuButtonAnchorEl] = useState<null | HTMLElement>(null);
  const isAccountMenuOpen = Boolean(accountMenuButtonAnchorEl);
  const [appMenuButtonAnchorEl, setAppMenuButtonAnchorEl] = useState<null | HTMLElement>(null);
  const isAppMenuOpen = Boolean(appMenuButtonAnchorEl);
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate();

  const handleAccountMenuButtonOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAccountMenuButtonAnchorEl(event.currentTarget);
  };

  const handleAppMenuButtonOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAppMenuButtonAnchorEl(event.currentTarget);
  };  

  const logout = () => {
    localStorage.removeItem('tradeninja-token');
    dispatch(refreshUser())
  }

  return (
    <Box sx={{ flexGrow: 1, paddingRight: paddingRight }}>
      <AppBar position="static">
        <Toolbar>
          <IconButton sx={{ mr: 2 }} size="large" edge="start" aria-haspopup="true" onClick={handleAppMenuButtonOpen} color="inherit">
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            {title}
          </Typography>
          {children}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <WebSocketServerStatus />
            <IbkrStatus />            
            <NyseStatus />
            <IconButton style={{ marginRight: '36px' }} size="large" color="inherit" >
              <Badge badgeContent={17} color="error"><NotificationsIcon /></Badge>
            </IconButton>
            <IconButton size="large" edge="end" aria-haspopup="true" onClick={handleAccountMenuButtonOpen} color="inherit">
              <AccountCircle />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <DropDownMenu open={isAppMenuOpen} arrowPosition='LEFT' anchorEl={appMenuButtonAnchorEl} handleClose={() => setAppMenuButtonAnchorEl(null)} menuItems={[
        { text: 'Dashboard', icon: () => <DashboardIcon />, onClick: () => navigate('/'), divider: true },
        { text: 'Trader Workstation', icon: () => <WaterfallChartIcon />, onClick: () => navigate('/trader-workstation') },        
        { text: 'Config Bots', icon: () => <SmartToyIcon />, onClick: () => navigate('/config-bots') },
        { text: 'Running Bots', icon: () => <PublishedWithChangesIcon />, onClick: () => navigate('/bots') },
        { text: 'Ranks', icon: () => <EmojiEventsIcon />, onClick: () => { alert('TODO: Implement it') }, divider: true },
        { text: 'Historical Data', icon: () => <CandlestickChartIcon />, onClick: () => navigate('/historical-data') },
        { text: 'Back Tests', icon: () => <ConstructionIcon />, onClick: () => navigate('/backtests') },
      ]} />
      <DropDownMenu open={isAccountMenuOpen} arrowPosition='RIGHT' anchorEl={accountMenuButtonAnchorEl} handleClose={() => setAccountMenuButtonAnchorEl(null)} menuItems={[
        { text: 'Settings', icon: () => <Settings />, onClick: () => { alert('TODO: Implement it') }, divider: true },
        { text: 'Logout', icon: () => <Logout />, onClick: logout },
      ]} />      
    </Box>
  )
}

export default AppHeader