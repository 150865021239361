import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import userService from "../../service/userService"
import { AsyncData, DepositsAndWithdrawalsType, User } from '../../types'

interface UserState {
  signinErrorMessage: string
  depositsAndWithdrawals: AsyncData<DepositsAndWithdrawalsType[]>,
  historicalBalances: AsyncData<{ balances: { date: string, value: number }[], goal: { date: string, value: number }[] }>,
  user: AsyncData<User | null>,
  options: {
    tradeSize: number
    holidays: string[]
    maxPositionSize: number
  },
  ibGatewayconnected: boolean
}

const initialState: UserState = {
  signinErrorMessage: '',
  depositsAndWithdrawals: { loading: false, data: [] },
  historicalBalances: { loading: false, data: { balances: [], goal: [] } },
  user: { loading: false, data: null },
  options: {
    tradeSize: 0,
    holidays: [],
    maxPositionSize: 0,
  },
  ibGatewayconnected: true
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateOptions: (state, action) => {
      state.options = { ...state.options, ...action.payload }
    },
    setIbGatewayConnected: (state, action) => {
      state.ibGatewayconnected = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      //signin
      .addCase(signIn.pending, (state) => {
        state.user = { loading: true, data: null }
      })
      //signin.fulfilled
      .addCase(signIn.fulfilled, (state, action) => {
        if (action.payload && action.payload.token) {
          localStorage.setItem('tradeninja-token', action.payload.token)
          state.user.data = action.payload.user
        } else {
          state.signinErrorMessage = 'Invalid email or password.'
        }
        state.user.loading = false
      })
      //refreshUser
      .addCase(refreshUser.pending, (state) => {
        state.user.loading = true
        state.user.data = null
      })
      //refreshUser.fulfilled
      .addCase(refreshUser.fulfilled, (state, action) => {
        state.user.loading = false
        state.user.data = action.payload
      })

      //updateUser
      .addCase(updateUser.pending, (state) => {
        //
      })
      //updateUser.fulfilled
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user.data = action.payload
      })


      //getDepositsAndWithdrawals
      .addCase(getDepositsAndWithdrawals.pending, (state) => {
        state.depositsAndWithdrawals.loading = true
        state.depositsAndWithdrawals.data = []
      })
      //getDepositsAndWithdrawals.fulfilled
      .addCase(getDepositsAndWithdrawals.fulfilled, (state, action) => {
        state.depositsAndWithdrawals.loading = false
        state.depositsAndWithdrawals.data = action.payload
      })

      //getHistoricalBalances
      .addCase(getHistoricalBalances.pending, (state) => {
        state.historicalBalances.loading = true
        state.historicalBalances.data = { balances: [], goal: [] }
      })
      //getHistoricalBalances.fulfilled
      .addCase(getHistoricalBalances.fulfilled, (state, action: any) => {
        state.historicalBalances.loading = false
        state.historicalBalances.data = action.payload
      });

  }
})

export const signIn = createAsyncThunk(
  "user/signin",
  async (payload: { email: string, password: string }) => {
    return userService.signIn(payload.email, payload.password)
  }
)

export const refreshUser = createAsyncThunk(
  "user/refresh",
  async () => {
    return userService.refreshUser()
  }
)

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (payload: any) => {
    return userService.updateUser(payload)
  }
)

export const getDepositsAndWithdrawals = createAsyncThunk(
  "user/getDepositsAndWithdrawals",
  async () => {
    return userService.getDepositsAndWithdrawals()
  }
)

export const getHistoricalBalances = createAsyncThunk(
  "user/getHistoricalBalances",
  async () => {
    return userService.getHistoricalBalances()
  }
)

export const { updateOptions, setIbGatewayConnected } = userSlice.actions;

export default userSlice.reducer;