import { z } from 'zod'
import { TextField } from '@mui/material'
import { FieldErrors } from 'react-hook-form'
import PasswordField from '../PasswordField'
import { NinjaFormField } from '../../types'
import NinjaSelect from '../NinjaSelect'

const getFormItem = (materialUiField: any, ninjaFormField: NinjaFormField, errors: FieldErrors) => {
  const { label, options, style, defaultValue, zod, disabled } = ninjaFormField
  if (options) {
    debugger
    return (
      <NinjaSelect
        autoFocus={true}
        aria-label={label}
        name={ninjaFormField.name}
        value={defaultValue ? options.find(itm => itm.value === defaultValue) : undefined}
        options={options}
      />
    )
  } else if (zod instanceof z.ZodNumber || zod instanceof z.ZodBigInt) {
    return (
      <TextField
        {...materialUiField}
        style={style}
        label={label}
        defaultValue={defaultValue}
        InputLabelProps={{ shrink: true }}
        disabled={disabled === true}
        fullWidth
        variant="standard"
        type="number"
        error={!!errors[materialUiField.name]}
      />
    )
  } else if (zod instanceof z.ZodString && materialUiField.name === 'password') {
    return (
      <PasswordField
        {...materialUiField}
        style={style}
        label={label}
        InputLabelProps={{ shrink: true }}
        disabled={disabled === true}
        // fullWidth
        variant="standard"
        error={!!errors[materialUiField.name]}
      />
    )
  }

  return (
    <TextField
      {...materialUiField}
      label={label}
      defaultValue={defaultValue}
      InputLabelProps={{ shrink: true }}
      disabled={disabled === true}
      inputProps={{ style }}
      fullWidth
      variant="standard"
      type="text"
      error={!!errors[materialUiField.name]}
    />
  )
}

export default getFormItem