import NinjaTooltip from '../../../../../components/NinjaTooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './PandLWidget.scss'
import { formatNumber } from 'humanize-plus';
import { usePnl } from '../../../../../hooks/usePnl';
import LoadingData from '../../../../../components/LoadingData';

const PandLWidget = () => {
  const pnl = usePnl()


  const renderValue = (value: number) => {
    const color = value === 0 ? 'inherit' : (value > 0 ? 'green' : 'red')
    return <span style={{ color }}>{formatNumber(value, 2)}</span>
  }

  const renderSummaryField = (label: string, value: number, tooltip: string) => (
    <div className='broker-info-form-field'>
      <div className='broker-info-form-field-label'>{label}:</div>
      <div className='broker-info-form-field-value'>{renderValue(value)}</div>
      <NinjaTooltip message={tooltip}><InfoOutlinedIcon /></NinjaTooltip>
    </div>
  )


  return (
    <div className="pnl-widget-container">
      <LoadingData loading={pnl.dailyPnL === 0}>
        {renderSummaryField('Daily', pnl.dailyPnL, 'Shows my profit in the current day, even on open positions.')}
        {renderSummaryField('Realized', pnl.realizedPnL, 'Shows my profit or loss on closed positions (including comissions).')}
        {renderSummaryField('Unrealized', pnl.unrealizedPnL, 'The difference between the current market value and the average price of my positions.')}
      </LoadingData>
    </div>
  )
}

export default PandLWidget