import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import DataGrid from "../../../../components/DataGrid";
import './LastExecutions.scss'
import { AsyncData } from "../../../../types";
import { FC } from "react";

type LastExecutionsProps = {
  allMountedBots: AsyncData<any[]>
}

const LastExecutions: FC<LastExecutionsProps> = ({ allMountedBots }) => {
  const lastExecutions = useSelector((state: RootState) => state.bot.lastExecutions);
  const screen = useSelector((state: RootState) => state.app.screen);

  return (
    <div className="last-executions-container">
      <DataGrid
        data={lastExecutions.data}
        height={screen.height - 120}
        columns={[
          { name: 'time', width: '15%', title: 'Time', type: 'datetime' },
          { name: 'side', width: '10%', title: 'Side' },
          { name: 'symbol', width: '15%', title: 'Symbol' },
          { name: 'shares', width: '15%', title: 'Shares', align: 'center' },
          { name: 'avgPrice', width: '10%', title: 'Price', type: 'number', align: 'right' },
          {
            name: 'botId', width: '35%', title: 'Bot', renderer: (data, record) => {
              // const foundBot = allMountedBots.data.find(itm => itm._id === record.botId)
              // if (!foundBot) return record.botId
              // if (foundBot.type === 'BUY_AND_HOLD') return foundBot.name
              // return <a href="#">{foundBot.name}</a>
              return ''
            }
          },
        ]}
      />
    </div>
  )
}

export default LastExecutions