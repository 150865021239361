import { FC, useEffect, useState } from 'react'
import './BackTestOverview.scss'
import { AsyncData, BackTestOverviewPropsType } from '../../../../types';
import { formatNumber } from 'humanize-plus';
import classNames from 'classnames';
import LoadingData from '../../../../components/LoadingData';
import getBackTestOverviewProps from '../../../../utils/getBackTestOverviewProps';
import TradeNinjaTabs from '../../../../components/TradeNinjaTabs';
import TradeNinjaTab from '../../../../components/TradeNinjaTab';
import PortfolioPerformanceChart from '../../../../components/PortfolioPerformanceChart';
import PortfolioComparingChart from '../../../../components/PortfolioComparingChart';
import PortfolioMaxPositionsByDayChart from '../../../../components/PortfolioMaxPositionsByDayChart';

type BackTestOverviewProps = {
  backTests: any[]
  initialCapital: number
  tradeSize: number
  backTestPositions: AsyncData<any[]>
}

const BackTestOverview: FC<BackTestOverviewProps> = ({ backTests, initialCapital, tradeSize, backTestPositions }) => {
  const [backTestOverviewProps, setBackTestOverviewProps] = useState<BackTestOverviewPropsType>()
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    setBackTestOverviewProps(getBackTestOverviewProps(initialCapital, backTestPositions.data))
  }, [initialCapital, backTestPositions.data])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };


  const backTestOverviewPropsLoaded = backTestOverviewProps && backTestOverviewProps.startMonth !== null
  const avgTrade = backTestOverviewPropsLoaded ? backTestOverviewProps.totalProfits / backTestPositions.data.length : 0

  return (
    <div className="backtest-overview-container">
      {
        backTestOverviewPropsLoaded && backTestPositions.data.length > 0 && (
          <div className="backtest-overview-header">
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Initial Capital</div>
              <div className="backtest-overview-field-value">{formatNumber(initialCapital, 2)}</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Trade Size</div>
              <div className="backtest-overview-field-value">{formatNumber(tradeSize, 2)}</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Period</div>
              <div className="backtest-overview-field-value">{`${backTestOverviewProps.startMonth.substring(0, 10)} - ${backTestOverviewProps.endMonth.substring(0, 10) }`}</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Net Profit</div>
              <div className={classNames('backtest-overview-field-value', { positive: backTestOverviewProps.totalProfits > 0, negative: backTestOverviewProps.totalProfits < 0 })}>{formatNumber(backTestOverviewProps.totalProfits, 2)}</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Total Closed Trades</div>
              <div className="backtest-overview-field-value">{backTestOverviewProps.totalTrades}</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Percent Profitable</div>
              <div className="backtest-overview-field-value">{formatNumber(backTestOverviewProps.percentProfitable, 2)}%</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Profit Factor</div>
              <div className="backtest-overview-field-value">{typeof backTestOverviewProps.profitFactor === 'number' ? formatNumber(backTestOverviewProps.profitFactor, 3) : backTestOverviewProps.profitFactor}</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Max Drawdown</div>
              <div className={classNames('backtest-overview-field-value', { negative: backTestOverviewProps.maxDrawdownValue > 0 })}>{formatNumber(backTestOverviewProps.maxDrawdownValue, 2)} ({formatNumber(backTestOverviewProps.maxDrawdownPerc, 2)}%)</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Avg Trade</div>
              <div className="backtest-overview-field-value">{formatNumber(avgTrade, 2)} ({formatNumber(avgTrade * 100 / initialCapital, 2)}%)</div>
            </div>
            <div className="backtest-overview-field">
              <div className="backtest-overview-field-label">Avg # Bars in Trade</div>
              <div className="backtest-overview-field-value">{formatNumber(backTestOverviewProps.avgBarsInTrades, 1)}</div>
            </div>
          </div>
        )
      }
      <div className="backtest-overview-body">
        <LoadingData loading={backTestPositions.loading || backTestOverviewProps === undefined}>
          <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}>
            <TradeNinjaTab label="Portfolio Performance" />
            <TradeNinjaTab label="Comparing" />
            <TradeNinjaTab label="Positions by day" />
          </TradeNinjaTabs>
          <div className="backtest-overview-chart">
            {
              backTestOverviewProps && (
                <>
                  {selectedTab === 0 && <PortfolioPerformanceChart positions={backTestPositions.data} startMonth={backTestOverviewProps!.startMonth} initialCapital={50000} endMonth={backTestOverviewProps!.endMonth} />}
                  {selectedTab === 1 && <PortfolioComparingChart backTests={backTests} positions={backTestPositions.data} startMonth={backTestOverviewProps!.startMonth} initialCapital={50000} endMonth={backTestOverviewProps!.endMonth} />}
                  {selectedTab === 2 && <PortfolioMaxPositionsByDayChart positions={backTestPositions.data} startMonth={backTestOverviewProps!.startMonth} endMonth={backTestOverviewProps!.endMonth} />}
                </>
              )
            }
          </div>
        </LoadingData>
      </div>
    </div>
  )
}

export default BackTestOverview