import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../state/store"
import { useEffect, useState } from "react"
import { getDepositsAndWithdrawals } from "../../../../../state/user/userSlice"
import DataGrid from "../../../../../components/DataGrid"
import './OpenPositionsWidget.scss'
import LoadingData from "../../../../../components/LoadingData"
import { useOpenPositions } from "../../../../../hooks/useOpenPositions"
import { usePrices } from "../../../../../hooks"
import { formatNumber } from "humanize-plus"

const OpenPositionsWidget = () => {
  const dispatch = useDispatch<AppDispatch>()
  const depositsAndWithdrawals = useSelector((state: RootState) => state.user.depositsAndWithdrawals);
  const [openPositionsData, setOpenPositionsData] = useState<any[]>([])
  const openPositions = useOpenPositions()
  const prices = usePrices()

  useEffect(() => {
    dispatch(getDepositsAndWithdrawals())
  }, [dispatch])

  useEffect(() => {
    setOpenPositionsData(Object.keys(openPositions || {}).map(symbol => {
      const position = openPositions[symbol]
      const price = prices[symbol]
      const costValue = position.posQuantity * position.avgCost
      const marketValue = position.posQuantity * price
      const profit = marketValue - costValue
      const profitPerc = profit * 100 / costValue
      return {
        symbol,
        avgCost: position.avgCost,
        posQuantity: position.posQuantity,
        marketValue,
        profit,
        profitPerc
      }
    }))
  }, [openPositions, prices])

  return (
    <div className="open-positions-widget-container">
      <LoadingData loading={depositsAndWithdrawals.loading}>
        <DataGrid
          data={openPositionsData.filter(itm => itm.posQuantity > 0)}
          height={350}
          columns={[
            { name: 'symbol', width: '5%', title: 'Symbol' },
            { name: 'posQuantity', width: '20%', title: 'Amount', align: 'right' },
            { name: 'marketValue', width: '25%', title: 'Market Value', type: 'number', align: 'right' },
            { name: 'avgCost', width: '25%', title: 'Avg. Price', type: 'number', align: 'right' },
            {
              name: 'avgCost', width: '25%', title: 'Unrlzd P&L', type: 'number', align: 'right', renderer: (data: any[], record: any) => {
                return (
                  <div style={{ display:'flex', flexDirection:'column', alignItems:'flex-end' }}>
                    <div style={{ color: record.profit > 0 ? '#10b981' : '#ef4444', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                      <div>{`${formatNumber(record.profit, 2)}`}</div>
                      <div style={{ minWidth: '40px', fontSize: '12px', marginLeft: '4px' }}>{`${formatNumber(record.profitPerc, 1)}%`}</div>
                    </div>
                  </div>
                )
              }
            },
          ]}
        />
      </LoadingData>
    </div>
  )
}

export default OpenPositionsWidget