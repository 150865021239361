import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import botService from "../../service/botService";
import { AsyncData } from "../../types";

interface BotState {
  strategies: AsyncData<any[]>,
  lastExecutions: AsyncData<any[]>,
  botsByStrategy: AsyncData<any[]>,
  deleteBotAsync: AsyncData<string>,
  deleteMountedBot: AsyncData<string>,
  updatedStrategy: AsyncData<any>,
  createBot: AsyncData<any>,
  mountBot: AsyncData<string>,
  updatedBot: AsyncData<any>,
  allBots: AsyncData<any[]>,
  allMountedBots: AsyncData<any[]>,
  cancellingBotPosition: AsyncData<string>
  closingBotPosition: AsyncData<string>
}

const initialState: BotState = {
  strategies: { loading: false, data: [] },
  lastExecutions: { loading: false, data: [] },  
  botsByStrategy: { loading: false, data: [] },
  updatedStrategy: { loading: false, data: {} },
  createBot: { loading: false, data: {} },
  deleteBotAsync: { loading: false, data: '' },
  deleteMountedBot: { loading: false, data: '' },
  mountBot: { loading: false, data: '' },
  updatedBot: { loading: false, data: {} },
  allBots: { loading: false, data: [] },
  allMountedBots: { loading: false, data: [] },
  cancellingBotPosition: { loading: false, data: '' },
  closingBotPosition: { loading: false, data: '' },
}

const botSlice = createSlice({
  name: 'bot',
  initialState,
  reducers: {
    refreshBot: (state, action: PayloadAction<any>) => {
      state.allMountedBots.data = state.allMountedBots.data.map(itm => {
        if (itm._id === action.payload._id) return { ...action.payload }
        return itm
      })
    },
    deleteBot: (state, action: PayloadAction<string>) => {
      state.allMountedBots.data = state.allMountedBots.data.filter(itm => itm._id !== action.payload)
    },
  },
  extraReducers: (builder) => {
    builder

      //getLastExecutions
      .addCase(getLastExecutions.pending, (state) => {
        state.lastExecutions.loading = true
      })
      .addCase(getLastExecutions.fulfilled, (state, action: PayloadAction<any>) => {
        state.lastExecutions.loading = false
        state.lastExecutions.data = action.payload || []
      })
      
      //getAllMountedBots
      .addCase(getAllMountedBots.pending, (state) => {
        state.allMountedBots.loading = true
      })
      .addCase(getAllMountedBots.fulfilled, (state, action: PayloadAction<any>) => {
        state.allMountedBots.loading = false
        state.allMountedBots.data = action.payload || []
      })

      //getStrategies
      .addCase(getStrategies.pending, (state) => {
        state.strategies.loading = true
      })
      .addCase(getStrategies.fulfilled, (state, action: PayloadAction<any>) => {
        state.strategies.loading = false
        state.strategies.data = action.payload || []
      })        
      
      //getBotsByStrategies
      .addCase(getBotsByStrategy.pending, (state) => {
        state.botsByStrategy.loading = true
      })
      .addCase(getBotsByStrategy.fulfilled, (state, action: PayloadAction<any>) => {
        state.botsByStrategy.loading = false
        state.botsByStrategy.data = action.payload || []
      })  

      // updateStrategy
      .addCase(updateStrategy.pending, (state) => {
        state.updatedStrategy.loading = true
      })
      .addCase(updateStrategy.fulfilled, (state, action: PayloadAction<any>) => {
        state.updatedStrategy.loading = false
        state.strategies.data = state.strategies.data.map((itm: any) => {
          if (itm._id === action.payload._id) return { ...action.payload }
          return itm
        })
      })  

      // updateBot
      .addCase(updateBot.pending, (state) => {
        state.updatedBot.loading = true
      })
      .addCase(updateBot.fulfilled, (state, action: PayloadAction<any>) => {
        state.updatedBot.loading = false
        state.botsByStrategy.data = state.botsByStrategy.data.map((itm: any) => {
          if (itm._id === action.payload._id) return { ...action.payload }
          return itm
        })
      })     
      
      //createNewBot
      .addCase(createBot.pending, (state) => {
        state.createBot.loading = true
      })
      .addCase(createBot.fulfilled, (state, action: PayloadAction<any>) => {
        state.createBot.loading = false
        state.botsByStrategy.data.push(action.payload)
      })

      //deleteBotAsync
      .addCase(deleteBotAsync.pending, (state) => {
        state.deleteBotAsync.loading = true
      })
      .addCase(deleteBotAsync.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteBotAsync.loading = false
        debugger
        state.deleteBotAsync.data = action.payload.deletedBotId
        state.botsByStrategy.data = state.botsByStrategy.data.filter((itm: any) => itm._id !== action.payload.deletedBotId)
      })
    
      //deleteBotAsync
      .addCase(deleteMountedBot.pending, (state) => {
        state.deleteMountedBot.loading = true
      })
      .addCase(deleteMountedBot.fulfilled, (state, action: PayloadAction<any>) => {
        state.deleteMountedBot.loading = false
        state.deleteMountedBot.data = action.payload.deletedBotId
        state.allMountedBots.data = state.allMountedBots.data.filter(itm => itm._id !== action.payload.deletedBotId)
      })
      
      //createNewBot
      .addCase(mountBot.pending, (state) => {
        state.mountBot.loading = true
      })
      .addCase(mountBot.fulfilled, (state, action: PayloadAction<any>) => {
        state.mountBot.loading = false;
        state.mountBot.data = action.payload.bots ? action.payload.bots[0]._id : '';
        (action.payload.bots || []).forEach((mountedBot: any) => state.allMountedBots.data.push(mountedBot))
      })
    
      //getAllBots
      .addCase(getAllBots.pending, (state) => {
        state.allBots.loading = true
      })
      .addCase(getAllBots.fulfilled, (state, action: PayloadAction<any>) => {
        state.allBots.loading = false
        state.allBots.data = action.payload || []
      })      

      //
      .addCase(enableBot.pending, (state, action: PayloadAction<any>) => {
        //
      })
      .addCase(enableBot.fulfilled, (state, action: PayloadAction<any>) => {
        state.allMountedBots.data = state.allMountedBots.data.map(itm => {
          if (itm._id === action.payload.botId) return { ...itm, enabled: action.payload.enabled }
          return itm
        })
      })

      //
      .addCase(buyAndHold.pending, (state) => {
        //
      })
      .addCase(buyAndHold.fulfilled, (state, action: PayloadAction<any>) => {
        //
        state.allMountedBots.data.push(action.payload)
      })

      //
      .addCase(incrementBotPosition.pending, (state) => {
        //
      })
      .addCase(incrementBotPosition.fulfilled, (state, action: PayloadAction<any>) => {
        //
      })

      //
      .addCase(sizeUpPosition.pending, (state) => {
        //
      })
      .addCase(sizeUpPosition.fulfilled, (state, action: PayloadAction<any>) => {
        //
      })

      //
      .addCase(buy.pending, (state) => {
        //
      })
      .addCase(buy.fulfilled, (state, action: PayloadAction<any>) => {
        //
      })

      //
      .addCase(closePosition.pending, (state) => {
        //
      })
      .addCase(closePosition.fulfilled, (state, action: PayloadAction<any>) => {
        //
      })


      //
      .addCase(sellAndHold.pending, (state) => {
        state.allMountedBots.loading = true
      })
      .addCase(sellAndHold.fulfilled, (state, action: PayloadAction<any>) => {
        state.allMountedBots.loading = false
        state.allMountedBots.data.push(action.payload)
      })

      //
      .addCase(cancelBotPosition.pending, (state) => {
        state.cancellingBotPosition.loading = true
      })
      .addCase(cancelBotPosition.fulfilled, (state, action: PayloadAction<any>) => {
        state.cancellingBotPosition.loading = false
        state.cancellingBotPosition.data = action.payload.botId
        state.allMountedBots.data = state.allMountedBots.data.filter(itm => itm._id !== action.payload.botId)
      })
    
      //
      .addCase(closeBotPosition.pending, (state) => {
        state.closingBotPosition.loading = true
      })
    
  }
})

export const getLastExecutions = createAsyncThunk("bot/getLastExecutions", () => {
  return botService.getLastExecutions()
})

export const getAllMountedBots = createAsyncThunk("bot/getAllMountedBots", () => {
  return botService.getAllMountedBots()
})

export const getStrategies = createAsyncThunk("bot/getStrategies", () => {
  return botService.getStrategies()
})

export const getBotsByStrategy = createAsyncThunk("bot/getBotsByStrategy", (args: { strategyId: string }) => {
  return botService.getBotsByStrategy(args.strategyId)
})

export const updateStrategy = createAsyncThunk("bot/updateStrategy", (args: { strategyId: string, data: any, callback: () => void }) => {
  return botService.updateStrategy(args.strategyId, args.data, args.callback)
})

export const createBot = createAsyncThunk("bot/createBot", (args: { strategyId: string, name: string, symbols: string, callback: (createdBot: any) => void }) => {
  return botService.createBot(args.strategyId, args.name, args.symbols, args.callback)
})

export const deleteBotAsync = createAsyncThunk("bot/deleteBotAsync", (args: { botId: string, callback: (deletedBotId: string) => void }) => {
  return botService.deleteBot(args.botId, args.callback)
})

export const deleteMountedBot = createAsyncThunk("bot/deleteMountedBot", (args: { botId: string }) => {
  return botService.deleteMountedBot(args.botId)
})

export const mountBot = createAsyncThunk("bot/mountBot", (args: { botId: string, force: boolean, callback: (resp: { code?: number, message?: string, bots?: any[] }) => void }) => {
  return botService.mountBot(args.botId, args.force, args.callback)
})

export const updateBot = createAsyncThunk("bot/updateBot", (args: { botId: string, data: any, callback: () => void }) => {
  return botService.updateBot(args.botId, args.data, args.callback)
})

export const getAllBots = createAsyncThunk("bot/getBots", () => {
  return botService.getAllBots()
})

export const enableBot = createAsyncThunk("bot/enableBot", (args: { botId: string, enabled: boolean }) => {
  return botService.enableBot(args.botId, args.enabled)
})

export const buyAndHold = createAsyncThunk("bot/buyAndHold", (args: { symbol: string, tradeSize: number, callback: (createdBot: any) => void }) => {
  return botService.buyAndHold(args.symbol, args.tradeSize, args.callback)
})
  
export const incrementBotPosition = createAsyncThunk("bot/incrementBotPosition", (args: { botId: string, tradeSize: number, callback: (updatedBot: any) => void }) => {
  return botService.incrementBotPosition(args.botId, args.tradeSize, args.callback)
})

export const sizeUpPosition = createAsyncThunk("bot/sizeUpPosition", (args: { symbol: string, tradeSize: number }) => {
  return botService.sizeUpPosition(args.symbol, args.tradeSize)
})

export const buy = createAsyncThunk("bot/buy", (args: { symbol: string, tradeSize: number }) => {
  return botService.buy(args.symbol, args.tradeSize)
})

export const closePosition = createAsyncThunk("bot/closePosition", (args: { symbol: string, perc: number }) => {
  return botService.closePosition(args.symbol, args.perc)
})

export const sellAndHold = createAsyncThunk("bot/sellAndHold", (args: { symbol: string, tradeSize: number, callback: (createdBot: any) => void }) => {
  return botService.sellAndHold(args.symbol, args.tradeSize, args.callback)
})

export const cancelBotPosition = createAsyncThunk("bot/cancelBotPosition", (args: { _id: string }) => {
  return botService.cancelBotPosition(args._id)
})

export const closeBotPosition = createAsyncThunk("bot/closeBotPosition", (args: { _id: string }) => {
  return botService.closeBotPosition(args._id)
})

export const { refreshBot, deleteBot } = botSlice.actions;

export default botSlice.reducer;