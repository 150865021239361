
import { FC } from 'react'
import './BotWidget.scss'
import classNames from 'classnames'
import getPositionDetails from '../../../../utils/getPositionDetails'
import ReactLoading from "react-loading";
import isThereNotFilledOrder from '../../../../utils/isThereNotFilledOrder';
import getStatusesNotFilledOrder from '../../../../utils/getStatusesNotFilledOrder';
import moment from 'moment';
import { PriceType } from '../../../../types';
import NinjaTooltip from '../../../../components/NinjaTooltip';

type BotWidgetProps = {
  prices: PriceType
  bot: any
  isSelected: boolean
  isDisabled: boolean
  onClick: (bot: any) => void
}

const BotWidget: FC<BotWidgetProps> = ({ prices, bot, isSelected, isDisabled, onClick }) => {

  const getBotStatus = () => {
    if (isDisabled) return 'DISABLED'
    return bot.status
  }

  const getBotPositionInfo = () => {
    const position = bot.position
    const symbolPrice = prices[bot.symbol]
    const orders = position.orders.filter((itm: any) => itm.price && itm.price > 0 && itm.status === 'Filled')
    if (!symbolPrice || orders.length === 0) return <ReactLoading type="bubbles" color="#38bdf8" />
    const { profitPerc, profitValue } = getPositionDetails(position.entry.side, orders, symbolPrice)
    return (
      <div className="bot-widget-position">
        {
          symbolPrice && (
            <>
              <div className={classNames("bot-widget-position-profit-perc", { positive: profitPerc > 0, negative: profitPerc < 0 })}>{profitPerc.toFixed(2)}%</div>
              <div className={classNames("bot-widget-position-profit-value", { positive: profitPerc > 0, negative: profitPerc < 0 })}>{profitValue.toFixed(2)}</div>
            </>
          )
        }
      </div>
    )
  }

  const normalizeSymbols = (symbols: string | string[]) => {
    const symbolsToUse = typeof symbols === 'string' ? [symbols] : symbols
    if (symbolsToUse.length === 1) {
      return symbolsToUse[0]
    }
    return (
      <NinjaTooltip message={JSON.stringify(symbolsToUse, null, 2)}>Multiples</NinjaTooltip>
    )
  }

  return (
    <div className={classNames("bot-widget-container", { isSelected })} onClick={() => onClick(bot)}>
      <div className="bot-widget-left-side">
        <span className="bot-widget-name">{bot.name}</span>
        <span className="bot-widget-symbol">{normalizeSymbols(bot.symbol || bot.symbols)}</span>
        <span className="bot-widget-timeframes">{bot.timeFrames ? bot.timeFrames.join(',') : ''}</span>
        {
          bot.position ? (
            <span className="bot-widget-position">{getBotPositionInfo()}</span>
          ) : (
            <span className="bot-widget-info">{getBotStatus()}</span>
          )
        }
      </div>
      {
        isThereNotFilledOrder(bot) && (
          <div className="bot-widget-right-side">
            <div className="bot-widget-order-title">Waiting Order</div>
            <ReactLoading type="bars" width={'40px'} color="#38bdf8" />
            <div className="bot-widget-order-info">
              {getStatusesNotFilledOrder(bot).map((itm, idx) => (
                <div key={`${bot._id}-${idx}`} className="bot-widget-order-info-item">
                  <span>{ moment(itm.time).format('YYYY-MM-DD HH:mm:ss') }</span>
                  <span>{ itm.status }</span>
                </div>
              ))}
            </div>
          </div>
        )
      }
    </div >
  )
}

export default BotWidget