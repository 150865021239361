import ApiClient from "../utils/ApiClient"
import { DepositsAndWithdrawalsType, User } from "../types"

const signIn = async (email: string, password: string): Promise<any> => {
  return ApiClient.post(`/tradeninja/signin`, { email, password })
}

const refreshUser = async (): Promise<User> => {
  return ApiClient.get('/tradeninja/refreshUser')
}

const updateUser = async (data: any): Promise<User> => {
  return ApiClient.put('/tradeninja/update-user', data)
}

const getDepositsAndWithdrawals = async (): Promise<DepositsAndWithdrawalsType[]> => {
  return ApiClient.get('/tradeninja/deposits-withdrawals')
}

const getHistoricalBalances = async (): Promise<{ date: string, value: number }[]> => {
  return ApiClient.get('/tradeninja/historical-balances')
}

const userService = {
  signIn,
  refreshUser,
  updateUser,
  getDepositsAndWithdrawals,
  getHistoricalBalances
}

export default userService