import { RunBackTestOptions } from "../types"
import ApiClient from "../utils/ApiClient"

const getAllBackTests = async (): Promise<any> => {
  return ApiClient.get('/tradeninja/backtests')
}

const getAllBackTestPositions = async (backTestIds: string[]): Promise<any> => {
  return ApiClient.get(`/tradeninja/backtest-positions/${backTestIds.join(',')}`)
}

const runBackTest = async (payload: RunBackTestOptions): Promise<any> => {
  return ApiClient.post('/backtest/run', payload)
}

const deleteBackTest = async (backTestId: string): Promise<any> => {
  return ApiClient.delete(`/backtest/${backTestId}`)
}



const backTestService = {
  getAllBackTests,
  getAllBackTestPositions,
  runBackTest,
  deleteBackTest
}

export default backTestService