import { FC, useEffect, useState } from 'react';
import { WebSocketContext } from '../contexts';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { NyseMarketState, PriceType } from '../types';
import moment from 'moment';
import { deleteBot, refreshBot } from '../state/bot/botSlice';
import { store } from '../state/store';
import { Pnl } from '../types/Pnl';

type TradeNinjaWebSocketProviderProps = {
  children: React.ReactNode;
}

export const WebSocketProvider: FC<TradeNinjaWebSocketProviderProps> = ({ children }) => {
  const [prices, setPrices] = useState<PriceType>({})
  const [openOrders, setOpenOrders] = useState<any>({})
  const [openPositions, setOpenPositions] = useState<any>({})
  const [webSocketServerStatus, setWebSocketServerStatus] = useState<ReadyState>(ReadyState.UNINSTANTIATED)
  const [ibGatewayUpAt, setIbGatewayUpAt] = useState<string>(moment().utc().format())
  const [nyseMarketState, setNyseMarketState] = useState<NyseMarketState | null>(null)
  const [backtestEvent, setBacktestEvent] = useState<{ event: string, payload: any } | null>(null)
  const [historicalDataUpdateEvent, setHistoricalDataUpdateEvent] = useState<{ event: string, payload: any } | null>(null)
  const [pnl, setPnl] = useState<Pnl>({ dailyPnL: 0, unrealizedPnL: 0, realizedPnL: 0 })
  const [accountSummary, setAccountSummary] = useState<any>({})

  const { lastMessage, readyState } = useWebSocket('wss://denimarlab.pro/tradeninja-ws/?client=tradeninja-web', {
    shouldReconnect: () => true,
    filter: () => true,
    heartbeat: true
  })

  useEffect(() => {
    setWebSocketServerStatus(readyState)
  }, [readyState]);

  useEffect(() => {
    if (lastMessage !== null) {
      const message = JSON.parse(lastMessage.data)
      switch (message.event) {
        case 'ON_UPDATE_PRICE':
          setPrices({ ...prices, ...message.payload })
          break;
        case 'ON_UPDATE_EXECUTION_ORDERS':
          console.log('--------------------------------------')
          console.log('ON_UPDATE_EXECUTION_ORDERS:')
          console.log(message.payload)
          console.log('--------------------------------------')
          break;
        case 'ON_UPDATE_OPEN_ORDERS':
          console.log('--------------------------------------')
          console.log('ON_UPDATE_OPEN_ORDERS:')
          console.log(message.payload)
          console.log('--------------------------------------')
          const orderIds = Object.keys(message.payload)
          if (orderIds.length > 0) {
            const openOrdersObj = { ...openOrders }
            for (const orderId of orderIds) {
              const order = message.payload[orderId]
              if (order.status === 'Filled') {
                delete openOrdersObj[orderId]
              } else {
                openOrdersObj[orderId] = message.payload
              }
            }
            setOpenOrders({ ...openOrdersObj })
          }
          break;
        case 'ON_OPEN_POSITION':
          setOpenPositions(message.payload)
          break;
        case 'ON_UPDATE_PNL':
          setPnl({ ...message.payload })
          break;
        case 'ON_UPDATE_ACCOUNT_SUMMARY':
          setAccountSummary({ ...accountSummary, ...message.payload })
          break;
        case 'ON_REFRESH_BOT':
          store.dispatch(refreshBot(message.payload))
          break;
        case 'ON_DELETE_BOT':
          store.dispatch(deleteBot(message.payload._id))
          break;
        case 'IB_GATEWAY_IS_UP':
          setIbGatewayUpAt(message.payload.time)
          break;
        case 'ON_NYSE_MARKET_STATE':
          setNyseMarketState({
            isOpen: message.payload.is_market_open,
            timeAfterOpen: message.payload.time_after_open,
            timeToClose: message.payload.time_to_close,
            timeToOpen: message.payload.time_to_open,
            updatedAt: message.payload.updatedAt
          })
          break;
        case 'ON_BACKTEST_STARTED':
        case 'ON_BACKTEST_FINISHED':
        case 'ON_BACKTEST_PROGRESS':
        case 'ON_BACKTEST_CLOSED_POSITION':
        case 'ON_BACKTEST_ERROR':
          setBacktestEvent({ event: message.event, payload: message.payload })
          break;
        case 'ON_HISTORICAL_DATA_UPDATE_STARTED':
        case 'ON_HISTORICAL_DATA_UPDATE_FINISHED':
        case 'ON_HISTORICAL_DATA_UPDATE_PROGRESS':
        case 'ON_HISTORICAL_DATA_UPDATE_ERROR':
          setHistoricalDataUpdateEvent({ event: message.event, payload: message.payload })
          break;
        default:
          console.log(`Event not found: "${message.event}`)
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage]);

  return (
    <WebSocketContext.Provider value={{
      prices,
      pnl,
      accountSummary,
      openOrders,
      openPositions,
      nyseMarketState,
      webSocketServerStatus,
      ibGatewayUpAt,
      backtestEvent,
      historicalDataUpdateEvent,
    }} >
      {children}
    </WebSocketContext.Provider>
  )
}