import { BackTestOverviewPropsType } from "../types"
import calculateMaxDrawdown from "./calculateMaxDrawdown"
import calculatePercentProfitable from "./calculatePercentProfitable"
import calculateProfitFactor from "./calculateProfitFactor"

const getBackTestOverviewProps = (initialCapital: number, positions: any[]): BackTestOverviewPropsType => {
  const positionsToUse = (positions || [])
  const positionsRangeDates = positionsToUse.reduce((acc, itm) => {
    if (acc.startMonth === null || itm.entry.datetime < acc.startMonth) {
      acc.startMonth = itm.entry.datetime
    }
    if (acc.endMonth === null || itm.exit.datetime > acc.endMonth) {
      acc.endMonth = itm.exit.datetime
    }
    return acc
  }, { startMonth: null, endMonth: null })

  const totalProfits = positionsToUse.reduce((acc, itm) => {
    acc += itm.exit.profit
    return acc
  }, 0)
  const maxDrawdown = calculateMaxDrawdown(initialCapital, positionsToUse)
  const avgBarsInTrades = positionsToUse.reduce((acc: number, itm: any) => {
    acc += itm.additionalInfo.candlesCount || 0
    return acc
  }, 0) / positionsToUse.length

  return {
    startMonth: positionsRangeDates.startMonth,
    endMonth: positionsRangeDates.endMonth,
    totalProfits,
    totalTrades: positionsToUse.length,
    percentProfitable: calculatePercentProfitable(positionsToUse),
    profitFactor: calculateProfitFactor(positionsToUse),
    maxDrawdownValue: maxDrawdown.value,
    maxDrawdownPerc: maxDrawdown.perc,
    avgBarsInTrades
  }
}

export default getBackTestOverviewProps