
import { Tabs, styled } from "@mui/material"

const TradeNinjaTabs = styled(Tabs)({
  padding: '0 10px',  
  // borderTop: '1px solid #e8e8e8',
  borderBottom: '1px solid #e8e8e8',
  width: '100%',
  '& .MuiTabs-flexContainer': {
    paddingTop: '8px'
  },
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
  '& .MuiTab-root': {
    paddingLeft: '12x',
    paddingRight: '12px'    
  },
  '& .Mui-selected': {
    paddingLeft: '11px',
    paddingRight: '11px',
    backgroundColor: '#fff',
    borderLeft: 'solid 1px #bae6fd',
    borderRight: 'solid 1px #bae6fd',
    borderTop: 'solid 1px #bae6fd',
    borderTopRightRadius: '3px',
    borderTopLeftRadius: '3px',
  }
});


export default TradeNinjaTabs