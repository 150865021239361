import { FC, useEffect, useState } from 'react'
import { PerformanceAnalysisDataType } from '../../../../types'
import './BackTestFilter.scss'
import { Button, Checkbox, FormControlLabel } from '@mui/material'
import DoneAllIcon from '@mui/icons-material/DoneAll';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { openDialogConfirm } from '../../../../utils/openDialog';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../state/store';
import { deleteBackTest } from '../../../../state/backTest/backTestSlice';

type BackTestFilterProps = {
  data: PerformanceAnalysisDataType[]
  onApplyFilters: (selectedIds: string[]) => void
  onDeletedBackTest: (selectedId: string) => void
  selectedBackTestsIds: string[]
  setSelectedBackTestsIds: (selectedIds: string[]) => void
}

const BackTestFilter: FC<BackTestFilterProps> = ({ data, onApplyFilters, onDeletedBackTest, selectedBackTestsIds, setSelectedBackTestsIds }) => {
  const [enableApplyChanges, setEnableApplyChanges] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<string[]>(selectedBackTestsIds)
  const screen = useSelector((state: RootState) => state.app.screen);
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    setSelectedIds(selectedBackTestsIds)
  }, [selectedBackTestsIds])

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    for (const dataItem of data) {
      const foundBot = dataItem.bots.find(itm => itm.backtestId === event.target.name)
      if (foundBot) {
        if (event.target.checked) {
          setSelectedIds(selectedIds.concat([foundBot.backtestId]))
        } else {
          setSelectedIds(selectedIds.filter(itm => itm !== foundBot.backtestId))
        }
        break;
      }
    }
    setEnableApplyChanges(true)
  };

  const sortData = (dataItems: PerformanceAnalysisDataType[]): PerformanceAnalysisDataType[] => {
    return dataItems.sort((a, b) => {
      if (a.strategy.code > b.strategy.code) return 1
      if (a.strategy.code < b.strategy.code) return -1
      return 0
    })
  }

  return (
    <div className="backtest-filter-container" style={{ height: `${screen.height - 150}px` }}>
      <div className="backtest-filter-buttons">
        <Button
          startIcon={<DoneAllIcon />}
          onClick={() => {
            setEnableApplyChanges(false)
            onApplyFilters(selectedIds)
          }}
          disabled={!enableApplyChanges}
          variant='outlined'
          color='success'
        >Apply Filter
        </Button>
      </div>
      <div className="backtest-filter-body">
        {
          data && (
            sortData(data).map((item, index) => (
              <div key={item.strategy._id} className='backtest-bots-group'>
                <div className='backtest-bots-strategy'>{item.strategy.code}</div>
                <div className='backtest-bots-checkboxes'>
                  {
                    item.bots.sort((a, b) => {
                      if (a.name > b.name) return 1
                      if (a.name < b.name) return -1
                      return 0
                    }).map((botItem, index) => (
                      <div key={botItem._id} className='backtest-bots-row'>
                        <FormControlLabel
                          key={botItem.backtestId}
                          label={<a href={`https://denimarlab.pro/config-bots?strategy=${item.strategy._id}&bot=${botItem._id}&strategyTab=1`} target="_blank" rel="noreferrer">{botItem.name}</a>}
                          control={
                            <Checkbox
                              name={botItem.backtestId}
                              checked={selectedIds.includes(botItem.backtestId)}
                              onChange={handleChangeCheckBox}
                              size="small"
                            />
                          }
                        />
                        <DeleteForeverIcon className="backtest-bots-delete-icon" onClick={() => {
                          openDialogConfirm(`Are you sure you want to delete this backtest (bot:"${botItem.name}")?`, button => {
                            if (button === 'confirm') {
                              dispatch(deleteBackTest(botItem.backtestId))
                              onDeletedBackTest(botItem.backtestId)
                            }
                          })
                        }}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          )
        }
      </div>
    </div>
  )
}

export default BackTestFilter