import * as ReactDOM from 'react-dom';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { blueGrey } from '@mui/material/colors';
import CustomModal, { CustomModalProps } from '../components/CustomModal/CustomModal';
import { ModalButtonEnum } from '../types';

interface OpenDialogOptions {
  title: string
  buttons: ModalButtonEnum[]
  html?: string,
  Icon?: any
  component?: JSX.Element
}

type OpenDialogCallback = (button: ModalButtonEnum | null, values: any) => void

const _openDialog = (options: OpenDialogOptions, callback?: OpenDialogCallback, additionalOptions?: CustomModalProps) => {
  const divParent = document.createElement('div')

  ReactDOM.render((
    <CustomModal
      isDialog={true}
      height={'220px'}
      width={'720px'}
      title={options.title}
      buttons={options.buttons}
      open={true}
      onClose={({ button, values }) => {
        if (callback) callback(button, values)
      }}
      {...additionalOptions}
    >
      <div style={{ display:'flex', flexDirection:'row', alignItems:'center', padding:'0 10px 5px 10px' }}>
        {
          options.component ? <div>{options.component}</div> : (
            <>
              <div><options.Icon sx={{ fontSize: 48, color: blueGrey[500] }} /></div>
              <div style={{ marginLeft:'10px', fontFamily:'Ubuntu' }}>{options.html}</div>
            </>
          )
        }
      </div>
    </CustomModal>
  ), divParent)
}

const openDialogQuestion = (message: string, callback: OpenDialogCallback) => _openDialog({ title: 'Question', Icon: ContactSupportIcon, buttons: [ModalButtonEnum.NO, ModalButtonEnum.YES], html: message }, callback)
const openDialogConfirm = (message: string, callback: OpenDialogCallback) => _openDialog({ title: 'Confirm', Icon: ContactSupportIcon, buttons: [ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM], html: message }, callback)
const openDialogInfo = (message: string) => _openDialog({ title: 'Info', Icon: InfoIcon, buttons: [ModalButtonEnum.OK], html: message })
const openDialogError = (message: string) => _openDialog({ title: 'Error', Icon: CancelIcon, buttons: [ModalButtonEnum.OK], html: message })
const openDialogWarning = (message: string) => _openDialog({ title: 'Warning', Icon: WarningAmberIcon, buttons: [ModalButtonEnum.OK], html: message })
const openDialogCustom = (title: string, buttons: ModalButtonEnum[], component: JSX.Element, callback?: OpenDialogCallback, additionalOptions?: CustomModalProps) => _openDialog({ title, Icon: WarningAmberIcon, buttons, component }, callback, additionalOptions)

export {
  openDialogInfo,
  openDialogConfirm,
  openDialogQuestion,
  openDialogError,
  openDialogWarning,
  openDialogCustom
}