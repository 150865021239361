import { FC, ReactNode, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../state/store";
import { refreshUser } from "../../state/user/userSlice";

type PrivateRoutesContainerProps = {
  children: ReactNode
}

const PrivateRoutesContainer: FC<PrivateRoutesContainerProps> = ({ children }) => {
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (!user.user.data && !user.user.loading) {
      const storedToken = localStorage.getItem('tradeninja-token')
      if (storedToken) {
        dispatch(refreshUser())
      } else {
        navigate('/signin');
      }
    }
  }, [dispatch, navigate, user.user.data, user.user.loading])

  return (
    <>{children}</>
  )
}

export default PrivateRoutesContainer