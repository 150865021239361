import './App.scss';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardPage from './pages/dashboard';
import BotsPage from './pages/bots/BotsPage';
import { AppDispatch } from './state/store'
import CounterPage from './pages/bots/BotsPage';
import SigninPage from './pages/signin/SigninPage';
import { useDispatch } from 'react-redux'
import { updateOptions } from './state/user/userSlice';
import { useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, doc } from "firebase/firestore";
import firebaseConfig from './config/firebaseConfig.json'
import { useDocument } from 'react-firebase-hooks/firestore';
import BackTestsPage from './pages/backtests/BackTestsPage';
import { useResizeDetector } from 'react-resize-detector';
import { setScreen } from './state/app/appSlice';
import { WebSocketProvider } from './providers';
import PrivateRoutesContainer from './components/PrivateRoutesContainer';
import HistoricalDataPage from './pages/historical-data/HistoricalDataPage';
import ConfigBotsPage from './pages/configBots/ConfigBotsPage';
import { SnackbarProvider } from 'notistack';
import TraderWorkstation from './pages/trader-workstation';

// Initialize Firebase
const app = initializeApp(firebaseConfig);
global.firestoreDb = getFirestore(app);

const App = () => {
  const dispatch = useDispatch<AppDispatch>()
  const [value] = useDocument(doc(global.firestoreDb, 'userOptions', 'denimar@gmail.com'));
  const { width, height, ref } = useResizeDetector();

  useEffect(() => {
    if (width && height) dispatch(setScreen({ width, height }))
  }, [width, height, dispatch])

  useEffect(() => {
    if (value) dispatch(updateOptions(value.data()))
  }, [value, dispatch])

  return (
    <WebSocketProvider>
      <SnackbarProvider maxSnack={3}>
        <div className="app-container" ref={ref}>
          <div className="app-body">
            <BrowserRouter>
              <Routes>
                <Route path='/signin' element={<PrivateRoutesContainer><SigninPage /></PrivateRoutesContainer>} />
                <Route path='/' element={<PrivateRoutesContainer><DashboardPage /></PrivateRoutesContainer>} />
                <Route path='/counter' element={<PrivateRoutesContainer><CounterPage /></PrivateRoutesContainer>} />
                <Route path='/backtests' element={<PrivateRoutesContainer><BackTestsPage /></PrivateRoutesContainer>} />
                <Route path='/historical-data' element={<PrivateRoutesContainer><HistoricalDataPage /></PrivateRoutesContainer>} />
                <Route path='/config-bots' element={<PrivateRoutesContainer><ConfigBotsPage /></PrivateRoutesContainer>} />
                <Route path='/bots' element={<PrivateRoutesContainer><BotsPage /></PrivateRoutesContainer>} />
                <Route path='/trader-workstation' element={<PrivateRoutesContainer><TraderWorkstation /></PrivateRoutesContainer>} />
              </Routes>
            </BrowserRouter>
          </div>
        </div>
      </SnackbarProvider>
    </WebSocketProvider>
  );
}

export default App;
