import LinearProgress from '@mui/material/LinearProgress';
import { FC } from 'react';
import './NinjaLinearProgressWithLabel.scss'
import { formatNumber } from 'humanize-plus';

type NinjaLinearProgressWithLabelProps = {
  value: number
}

const NinjaLinearProgressWithLabel: FC<NinjaLinearProgressWithLabelProps> = ({ value }) => {
  return (
    <div className='ninja-linear-progress-with-label-container'>
      <LinearProgress variant="determinate"  value={value || 0.01} />
      <div className='ninja-linear-progress-value'>
        {value > 0 ? formatNumber(value, 1) : 0}%
      </div>
    </div>
  )
}

export default NinjaLinearProgressWithLabel