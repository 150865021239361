import { useEffect, useState } from 'react'
import AppHeader from '../../components/AppHeader'
import './HistoricalDataPage.scss'
import TradeNinjaTabs from '../../components/TradeNinjaTabs'
import TradeNinjaTab from '../../components/TradeNinjaTab'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../state/store'
import { getHistoricalDataDaily, getHistoricalDataIntraday } from '../../state/historicalData/historicalDataSlice'
import LoadingData from '../../components/LoadingData'
import HistoricalDataGrid from './components/HistoricalDataGrid'
import { HistoricalDataTypeEnum } from '../../types'
import { useSearchParams } from 'react-router-dom'

const HistoricalDataPage = () => {
  const [selectedTab, setSelectedTab] = useState(0)
  const historicalDataDaily = useSelector((state: RootState) => state.historicalData.historicalDataDaily);
  const historicalDataIntraday = useSelector((state: RootState) => state.historicalData.historicalDataIntraday);
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    const historicalDataType = searchParams.get('type')
    if (historicalDataType === 'daily') {
      setSelectedTab(0)
    } else if (historicalDataType === 'intraday') {
      setSelectedTab(1)
    } else {
      setSearchParams(params => {
        params.set("type", 'daily');
        return params;
      });
      setSelectedTab(0)
    }
  }, [searchParams, setSearchParams])

  useEffect(() => {
    if (selectedTab === 0) {
      dispatch(getHistoricalDataDaily())
    } else if (selectedTab === 1) {
      dispatch(getHistoricalDataIntraday())
    }
  }, [dispatch, selectedTab])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchParams(params => {
      params.set("type", newValue === 0 ? 'daily' : 'intraday');
      return params;
    });    
  };

  return (
    <div className='historical-data-page-container'>
      <AppHeader title="Historical Data">
      </AppHeader>
      <div className='historical-data-page-body'>
        <div className='historical-data-page-action-buttons-container'>
          <div className='historical-data-page-action-buttons'>
          </div>
        </div>
        <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}>
          <TradeNinjaTab label='Daily' />
          <TradeNinjaTab label='Intraday' />
        </TradeNinjaTabs>
        {
          selectedTab === 0 && (
            <LoadingData loading={historicalDataDaily.loading}>
              <HistoricalDataGrid type={HistoricalDataTypeEnum.DAILY} data={historicalDataDaily.data} />
            </LoadingData>
          )
        }
        {
          selectedTab === 1 && (
            <LoadingData loading={historicalDataIntraday.loading}>
              <HistoricalDataGrid type={HistoricalDataTypeEnum.INTRADAY} data={historicalDataIntraday.data} />
            </LoadingData>
          )
        }
      </div>
    </div>
  )
}

export default HistoricalDataPage