import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux'
import { store } from './state/store';
import { createTheme, ThemeProvider } from '@mui/material/styles'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const myTheme = createTheme({
  // Set up your custom MUI theme here
})


root.render(
  <Provider store={store}>
    <ThemeProvider theme={myTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);