import { FC, useRef } from 'react';
import CustomModal from '../components/CustomModal/CustomModal';
import { ModalButtonEnum } from '../types';
import NinjaForm from '../components/NinjaForm';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { createBot } from '../state/bot/botSlice';

type NewBotModalProps = {
  strategyId: string
  open: boolean
  setOpen: (open: boolean) => void
  callback: (createdBot: any) => void
}

const NewBotModal: FC<NewBotModalProps> = ({ strategyId, open, setOpen, callback }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <CustomModal
      isDialog={false}
      title={'Creating New Bot'}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          dispatch(createBot({ strategyId, name: values.name, symbols: values.symbols, callback }))
        }
        setOpen(false)
      }}
    >
      <>
        <NinjaForm formRef={formRef} OnSubmit={() => { }} fields={
          [
            { name: 'name', label: 'Name', zod: z.string({ required_error: 'Name is required' }), gridWidth: 12 },
            { name: 'symbols', label: 'Symbols', zod: z.string({ required_error: 'Symbols is required' }), gridWidth: 12, style: { textTransform: "uppercase" } },
          ]} />
      </>
    </CustomModal>
  )
}

export default NewBotModal