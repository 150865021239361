import NinjaTooltip from '../../../../../components/NinjaTooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import './MarginWidget.scss'
import { formatNumber } from 'humanize-plus';
import { useAccountSummary } from '../../../../../hooks/useAccountSummary';
import LoadingData from '../../../../../components/LoadingData';

const MarginWidget = () => {
  const accountSummary = useAccountSummary()

  const renderSummaryField = (label: string, value: number, tooltip: string) => (
    <div className='broker-info-form-field'>
      <div className='broker-info-form-field-label'>{label}:</div>
      <div className='broker-info-form-field-value'>{formatNumber(value, 2)}</div>
      <NinjaTooltip message={tooltip}><InfoOutlinedIcon /></NinjaTooltip>
    </div>
  )


  return (
    <div className="margin-widget-container">
      <LoadingData loading={!accountSummary.NetLiquidation}>
        {renderSummaryField('Net Liquidation', accountSummary.NetLiquidation, 'Value I would have in my account if I close all positions.')}
        {renderSummaryField('Available Funds', accountSummary.AvailableFunds, 'The amount of cash me can withdraw out of my account based on the current portfolio value.')}
        {renderSummaryField('Buying Power', accountSummary.BuyingPower, 'Buying Power tells me how much I have at my disposal including my equity and IBKR margin (Available Funds * 4).')}
        {renderSummaryField('Excess Liquidity', accountSummary.ExcessLiquidity, 'Excess Liquidity tells me whether I have sufficient cushion to maintain my current positions and I will be liquidated when it is < 0.')}
        {renderSummaryField('SMA', accountSummary.SMA, 'I need to have SMA > 0 to be in compliance with overnight margin reqs, otherwise I will be liquidated around 30 min before the market closes.')}
        {renderSummaryField('Cash', accountSummary.TotalCashValue, 'Available Cash')}        
      </LoadingData>
    </div>
  )
}

export default MarginWidget