import { FC, useEffect, useRef, useState } from 'react';
import CustomModal from '../components/CustomModal/CustomModal';
import { AsyncData, ModalButtonEnum } from '../types';
import NinjaForm from '../components/NinjaForm';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { runBackTest } from '../state/backTest/backTestSlice';
import { useSearchParams } from 'react-router-dom';

type NewBackTestModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  allBots: AsyncData<any[]>
}

const NewBackTestModal: FC<NewBackTestModalProps> = ({ open, setOpen, allBots }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()
  const [searchParams, setSearchParams] = useSearchParams();
  const [defaultBotId, setDefaultBotId] = useState<string>()

  useEffect(() => {
    if (open) {
      const initialBotId = searchParams.get('bot')
      if (initialBotId) {
        setDefaultBotId(initialBotId)
        setSearchParams(params => {
          params.delete('bot')
          return params;
        });
      }
    }
  }, [open, searchParams, setSearchParams])

  return (
    <CustomModal
      width={'650px'}
      isDialog={false}
      title={'Creating a new BackTest'}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          localStorage.setItem('backTestStartMonth', values.startMonth)
          localStorage.setItem('backTestEndMonth', values.endMonth)
          dispatch(runBackTest({
            bot: values.bot,
            startMonth: values.startMonth,
            endMonth: values.endMonth,
            initialCapital: parseFloat(values.initialCapital),
            tradeSize: parseFloat(values.tradeSize)
          }))
        }
        setOpen(false)
      }}
    >
      <NinjaForm formRef={formRef} OnSubmit={() => { }} fields={
        [
          { name: 'bot', label: 'Bot', zod: z.string({ required_error: 'Bot is required' }), defaultValue: defaultBotId, gridWidth: 6, options: allBots.data.map(itm => ({ value: itm._id, label: itm.name })) },
          { name: 'startMonth', label: 'Start Month', zod: z.string({ required_error: 'Start Month is required' }), defaultValue: localStorage.getItem('backTestStartMonth') || '2017-01', gridWidth: 3 },
          { name: 'endMonth', label: 'End Month', zod: z.string({ required_error: 'End Month is required' }), defaultValue: localStorage.getItem('backTestEndMonth') || '2024-07', gridWidth: 3 },
          { name: 'initialCapital', label: 'Initial Capital', zod: z.bigint({ required_error: 'Initial Capital is required' }), defaultValue: 50000, gridWidth: 6, disabled: false },
          { name: 'tradeSize', label: 'Trade Size', zod: z.bigint({ required_error: 'Trade Size is required' }), defaultValue: 10000, gridWidth: 6, disabled: false },
        ]}
      />
    </CustomModal>
  )
}

export default NewBackTestModal