import { FC, useEffect, useState } from 'react'
import './PerformanceAnalysis.scss'
import TradeNinjaTabs from '../TradeNinjaTabs'
import TradeNinjaTab from '../TradeNinjaTab'
import moment from 'moment'
import PerformanceAnalysisRenderer from './components/PerformanceAnalysisRenderer'
import { useSelector } from 'react-redux'
import { RootState } from '../../state/store'
import LoadingData from '../LoadingData'
import { Checkbox, FormControlLabel } from '@mui/material'

type PerformanceAnalysisProps = {
  backTests: any[]
  positions: any[]
  loading: boolean
}

const PerformanceAnalysis: FC<PerformanceAnalysisProps> = ({ backTests, positions, loading }) => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [positionsByYear, setPositionsByYear] = useState<{ [year: string]: any[] }>({})
  const [positionsByMonth, setPositionsByMonth] = useState<{ [month: string]: any[] }>({})
  // const [positionsByTime, setPositionsByTime] = useState<{ [month: string]: any[] }>({})
  const [breakBotsBySymbol, setBreakBotsBySymbol] = useState<boolean>(false)
  const screen = useSelector((state: RootState) => state.app.screen);

  useEffect(() => {
    const sortedPositions = [...positions].sort((a, b) => {
      if (a.exit.datetime > b.exit.datetime) return 1
      if (a.exit.datetime < b.exit.datetime) return -1
      return 0
    })
    setPositionsByYear(sortedPositions.reduce((acc, position) => {
      const year = position.exit.datetime.substring(0, 4)
      acc[year] = acc[year] || []
      acc[year].push(position)
      return acc
    }, {}))
    setPositionsByMonth(sortedPositions.reduce((acc, position) => {
      const month = position.exit.datetime.substring(0, 7)
      acc[month] = acc[month] || []
      acc[month].push(position)
      return acc
    }, {}))
    // setPositionsByTime(sortedPositions.reduce((acc, position) => {
    //   debugger
    //   const month = position.exit.datetime.substring(0, 7)
    //   acc[month] = acc[month] || []
    //   acc[month].push(position)
    //   return acc
    // }, {}))
  }, [positions])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <div className='performance-analysis-container'>
      <div className='performance-analysis-body' style={{ height: `${screen.height - 160}px` }}>
        <LoadingData loading={loading}>
          <div className='performance-analysis-options'>
            <FormControlLabel
              label="Break Bots by Symbol"
              control={
                <Checkbox {...label} size="small" checked={breakBotsBySymbol} onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setBreakBotsBySymbol(event.target.checked) }} />
              }
            />
          </div>
          <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}>
            <TradeNinjaTab label="All" />
            <TradeNinjaTab label="Yearly" />
            <TradeNinjaTab label="Monthly" />
            {/* <TradeNinjaTab label="by Time" /> */}
          </TradeNinjaTabs>
          <>
            {
              selectedTab === 0 && <PerformanceAnalysisRenderer backTests={backTests} items={{ all: positions }} breakBotsBySymbol={breakBotsBySymbol} titleRenderer={() => ''} />
            }
            {
              selectedTab === 1 && <PerformanceAnalysisRenderer backTests={backTests} items={positionsByYear} breakBotsBySymbol={breakBotsBySymbol} />
            }
            {
              selectedTab === 2 && <PerformanceAnalysisRenderer backTests={backTests} items={positionsByMonth} breakBotsBySymbol={breakBotsBySymbol} titleRenderer={(title) => moment(`${title}-01`).format('MMMM YYYY')} />
            }
            {/* {
              selectedTab === 2 && <PerformanceAnalysisRenderer backTests={backTests} items={positionsByTime} breakBotsBySymbol={breakBotsBySymbol} titleRenderer={(title) => moment(`${title}-01`).format('MMMM YYYY')} />
            } */}
          </>
        </LoadingData>
      </div>
    </div>
  )
}

export default PerformanceAnalysis