import { FC, useEffect, useState } from 'react'
import { AsyncData } from '../../../../types'
import './BackTestListOfTrades.scss'
import LoadingData from '../../../../components/LoadingData'
import DataGrid from '../../../../components/DataGrid'
import { formatNumber } from 'humanize-plus'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../state/store'

type BackTestListOfTradesProps = {
  initialCapital: number
  backTestPositions: AsyncData<any[]>
}

const BackTestListOfTrades: FC<BackTestListOfTradesProps> = ({ initialCapital, backTestPositions }) => {
  const screen = useSelector((state: RootState) => state.app.screen);
  const [positions, setPositions] = useState<any[]>([])

  useEffect(() => {
    if (backTestPositions.data) {
      setPositions(updateCurrentCapital(backTestPositions.data, initialCapital).sort((itm1: any, itm2: any) => {
        if (itm1.entry.datetime > itm2.entry.datetime) return -1
        if (itm1.entry.datetime < itm2.entry.datetime) return 1
        return 0
      }))
    }
  }, [backTestPositions, initialCapital])

  const updateCurrentCapital = (positions: any[], initialCapital: number): any[] => {
    let currentCapital = initialCapital
    return positions.map((position: any) => {
      currentCapital += position.exit.profit
      return {
        ...position,
        currentCapital
      }
    })
  }

  return (
    <div className="backtest-list-of-trades-container">
      <LoadingData loading={backTestPositions.loading}>
        <DataGrid
          height={screen.height - 170}
          data={positions}
          columns={[
            {
              name: '_id', title: 'bot', width: '10%', renderer: (data, record) => {
                debugger
                return record.entry.side
              }
            },
            {
              name: 'symbol', title: 'Side', width: '10%', renderer: (data, record) => {
                return record.entry.side
              }
            },
            { name: 'symbol', title: 'Symbol', width: '10%' },
            { name: 'stopLoss', title: 'Stop Loss', width: '10%', type: 'number', align: 'right' },
            {
              name: 'entry.datetime', title: 'Entry at', width: '15%', align: 'center', renderer: (data, record) => {
                return record.entry.datetime
              }
            },
            {
              name: 'exit.datetime', title: 'Exit at', width: '15%', align: 'center', renderer: (data, record) => {
                return record.exit.datetime
              }
            },
            {
              name: 'currentCapital', title: 'Capital', width: '10%', align: 'right', type: 'number'
            },
            {
              name: 'exit.proit', title: 'Profit', width: '10%', align: 'right', renderer: (data, record) => {
                const color = record.exit.profit >= 0 ? 'green' : 'red'
                return <span style={{ color }}>{formatNumber(record.exit.profit, 2)}</span>
              }
            },
          ]}
        />
      </LoadingData>
    </div>
  )
}

export default BackTestListOfTrades