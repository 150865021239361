import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AsyncData, RunBackTestOptions } from "../../types";
import backTestService from "../../service/backTestService";

interface BackTestState {
  runningBackTest: AsyncData<any>,
  backTests: AsyncData<any[]>,
  backTestPositions: AsyncData<any[]>
  performanceAnalysisPositions: AsyncData<any[]>
  deleteBackTest: AsyncData<string>
}

const initialState: BackTestState = {
  runningBackTest: { loading: false, data: {} },
  backTests: { loading: false, data: [] },
  backTestPositions: { loading: false, data: [] },
  performanceAnalysisPositions: { loading: false, data: [] },
  deleteBackTest: { loading: false, data: '' }
}

const backTestSlice = createSlice({
  name: 'backTest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //getAllBackTests
      .addCase(getAllBackTests.pending, (state) => {
        state.backTests.loading = true
      })
      .addCase(getAllBackTests.fulfilled, (state, action: PayloadAction<any>) => {
        state.backTests.loading = false
        state.backTests.data = action.payload || []
      })

      //getAllBackTestPositions
      .addCase(getAllBackTestPositions.pending, (state) => {
        state.backTestPositions.loading = true
      })
      .addCase(getAllBackTestPositions.fulfilled, (state, action: PayloadAction<any>) => {
        state.backTestPositions.loading = false
        state.backTestPositions.data = action.payload || []
      })

      //getPerformanceAnalysisPositions
      .addCase(getPerformanceAnalysisPositions.pending, (state) => {
        state.performanceAnalysisPositions.loading = true
      })
      .addCase(getPerformanceAnalysisPositions.fulfilled, (state, action: PayloadAction<any>) => {
        state.performanceAnalysisPositions.loading = false
        state.performanceAnalysisPositions.data = action.payload || []
      })


      //runBackTests
      .addCase(runBackTest.pending, (state) => {
        state.runningBackTest.loading = true
      })
      .addCase(runBackTest.fulfilled, (state, action: PayloadAction<any>) => {
        state.runningBackTest.loading = false //loading: false is set via websocket
      })

      //deleteBackTest
      .addCase(deleteBackTest.pending, (state) => {
        state.deleteBackTest.loading = true
      })
      .addCase(deleteBackTest.fulfilled, (state, action: PayloadAction<{ id: string }>) => {
        state.deleteBackTest.loading = false
        state.backTests.data = state.backTests.data.filter(itm => itm._id !== action.payload.id)
      })


  }
})

export const getAllBackTests = createAsyncThunk("backtest/getAllBackTests", async () => {
  return backTestService.getAllBackTests()
})

export const getAllBackTestPositions = createAsyncThunk("backtest/getBackTestPositions", async (args: { backTestIds: string[] }) => {
  return backTestService.getAllBackTestPositions(args.backTestIds)
})

export const getPerformanceAnalysisPositions = createAsyncThunk("backtest/getPerformanceAnalysisPositions", async (args: { backTestIds: string[] }) => {
  return backTestService.getAllBackTestPositions(args.backTestIds)
})

export const runBackTest = createAsyncThunk("backtest/runBackTest", async (runBackTestOptions: RunBackTestOptions) => {
  return backTestService.runBackTest(runBackTestOptions)
})

export const deleteBackTest = createAsyncThunk("backtest/deleteBackTest", async (backTestId: string) => {
  return backTestService.deleteBackTest(backTestId)
})

export default backTestSlice.reducer;