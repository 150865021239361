import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import nysePNG from '../../assets/images/nyse.png'
import './NyseStatus.scss'
import { ReactNode, useEffect, useState } from 'react';
import { useNyseMarketState } from '../../hooks';
import NinjaTooltip from '../NinjaTooltip';
import moment, { Moment } from 'moment-timezone';
import getPrettyDuration from '../../utils/getPrettyDuration';

const NyseStatus = () => {
  const [isActive, setActive] = useState<boolean>(false)
  const [message, setMessage] = useState<ReactNode>('')
  const [currentTime, setCurrentTime] = useState<Moment>(moment())
  const nyseMarketState = useNyseMarketState()

  useEffect(() => {
    setActive(nyseMarketState !== null && nyseMarketState.isOpen)
    if (nyseMarketState) {
      if (nyseMarketState.isOpen) {
        setMessage(
          <div>
            <span>{'NYSE is open and will close in '}</span>
            <span style={{ fontWeight: 600 }}>{`${getPrettyDuration(nyseMarketState.timeToClose, nyseMarketState.updatedAt, currentTime)}`}</span>
          </div>
        )
      } else {
        setMessage(
          <div>
            <span>{'NYSE will open in '}</span>
            <span style={{ fontWeight: 600 }}>{`${getPrettyDuration(nyseMarketState.timeToOpen, nyseMarketState.updatedAt, currentTime)}`}</span>
          </div>)
      }
    }
  }, [nyseMarketState, currentTime])

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().utc())
    }, 1000)
    return () => {
      clearInterval(interval);
    }
  }, [])

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: isActive ? '#44b700' : 'red',
      color: isActive ? '#44b700' : 'red',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));

  return (
    <NinjaTooltip message={message}>
      <StyledBadge
        className='nysestatus-container'
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        <Avatar alt="NYSE Status" src={nysePNG} />
      </StyledBadge>
    </NinjaTooltip>
  )
}

export default NyseStatus