import { PositionSide } from "../types"

export type getPositionDetailsResponse = {
  currentPrice: number
  positionCost: number
  quantityEntered: number
  avgEntryPrice: number
  quantityExit: number
  avgExitPrice: number
  profitPerc: number
  profitValue: number
}

const getPositionDetails = (side: PositionSide, orders: any, currentPrice: number): getPositionDetailsResponse => {
  const entryOrders = orders.filter((itm: any) => itm.side === side && itm.status === 'Filled')
  const exitOrders = orders.filter((itm: any) => itm.side !== side && itm.status === 'Filled')

  //
  const quantityEntered = entryOrders.reduce((acc: any, itm: any) => {
    acc += itm.quantity
    return acc
  }, 0)

  const avgEntryPrice = entryOrders.length > 0 ? entryOrders.reduce((acc: any, itm: any) => {
    acc += itm.price * itm.quantity
    return acc
  }, 0) / quantityEntered : 0

  //
  const quantityExit = exitOrders.reduce((acc: any, itm: any) => {
    acc += itm.quantity
    return acc
  }, 0)
  const avgExitPrice = exitOrders.length > 0 ? exitOrders.reduce((acc: any, itm: any) => {
    acc += itm.price * itm.quantity
    return acc
  }, 0) / quantityExit : 0

  const amountToBeClosed = quantityEntered - quantityExit
  const avgPriceClosing = ((avgExitPrice * quantityExit) + (currentPrice * amountToBeClosed)) / (quantityExit + amountToBeClosed)

  //
  const profitPerc = side === PositionSide.BUY ? (avgPriceClosing * 100 / avgEntryPrice) - 100 : (avgEntryPrice * 100 / avgPriceClosing) - 100
  const profitValue = (quantityEntered * avgEntryPrice) * (profitPerc / 100)

  return {
    currentPrice,
    positionCost: quantityEntered * avgEntryPrice,
    quantityEntered,
    avgEntryPrice,
    quantityExit,
    avgExitPrice,
    profitPerc,
    profitValue
  }
}

export default getPositionDetails