import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Controller, useForm } from 'react-hook-form'
import { Grid } from '@mui/material'
import { FC, MutableRefObject } from 'react'
import getFormItem from './getFormItem'
import './NinjaForm.scss'
import { NinjaFormField } from '../../types'

type NinjaFormProps = {
  formRef: MutableRefObject<HTMLFormElement | null>
  fields: NinjaFormField[]
  OnSubmit: (data: any) => void
}

const NinjaForm: FC<NinjaFormProps> = ({ formRef, fields, OnSubmit }) => {
  const formSchema = z.object(fields.reduce((acc: any, itm: NinjaFormField) => {
    acc[itm.name] = itm.zod
    return acc
  }, {}))
  type FormSchema = z.infer<typeof formSchema>
  const { control, handleSubmit, formState: { errors } } = useForm<FormSchema>({
    resolver: zodResolver(formSchema)
  })

  return (
    <form ref={formRef} onSubmit={handleSubmit(OnSubmit)} className="ninja-form-container">
      <Grid item container columnSpacing={2} rowSpacing={3}>
        {
          fields.map(ninjaFormField => (
            <Grid key={ninjaFormField.name} item xs={ninjaFormField.gridWidth}>
              <Controller
                name={ninjaFormField.name}
                control={control}
                render={({ field }) => (
                  <>
                    {getFormItem(field, ninjaFormField, errors)}
                    {
                      errors[ninjaFormField.name]?.message && (
                        <p className="ninja-form-error-massage">{`${errors[ninjaFormField.name]?.message!}`}</p>
                      )
                    }
                  </>
                )}
              />
            </Grid>
          ))
        }
      </Grid>
    </form>
  )
}

export default NinjaForm