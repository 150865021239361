import { Button, Dialog, DialogTitle } from "@mui/material"
import { useRef, useEffect, useState } from "react"
// import Draggable from 'react-draggable'
import firstLetterToUpperCase from "../../utils/firstLetterToUpperCase"
import { ModalButtonEnum } from "../../types"
import './CustomModal.scss'
import classNames from "classnames"


export interface ModalOnCloseResponse {
  button: ModalButtonEnum | null,
  values: any
}

export interface CustomModalProps {
  title: string
  buttons?: ModalButtonEnum[]
  className?: string
  width?: number | string
  minWidth?: number | string
  height?: number | string  
  minHeight?: number | string
  open: boolean
  isDialog: boolean
  onOpen?: () => void;
  onClose?: (resp: ModalOnCloseResponse) => void;
  children: JSX.Element
}

const CustomModal: React.FC<CustomModalProps> = ({ title, buttons = [ModalButtonEnum.CLOSE], className, width, minWidth, height, minHeight, open, isDialog = false, onClose, onOpen, children }) => {
  const [showModal, setShowModal] = useState<boolean>(open)
  const modalRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      setShowModal(true)
      setTimeout(() => {
        if (onOpen) onOpen()
        const modalChildrenContainer = modalRef.current?.querySelector('.custom-modal-children')

        // Get all the focusable elements in the document that are not disabled
        const focusableElements: any = modalChildrenContainer?.querySelectorAll('a[href], .MuiSelect-select, textarea:not([disabled]), input:not([disabled])');

        // Set focus to the first focusable element
        if (focusableElements && focusableElements.length > 0) {
          focusableElements[0].focus();
        }

      }, 100)
    } else {
      setShowModal(false)
    }
  }, [open, onOpen])

  return (
    <Dialog
      ref={modalRef}
      className={className}
      open={showModal}
      onClose={() => {
        onClose && onClose({ button: null, values: {} })
        setShowModal(false)
      }}
      sx={{
        '.MuiPaper-root': {
          width: `${width || minWidth || '600px'}`,
          minWidth: `${minWidth || width || '600px'}`, 
          height: `${height || minHeight  || '370px'}`,
          minHeight: `${minHeight || height || '370px'}`
        }
      }}
    // PaperComponent={(props) => (
    //   <Draggable
    //     handle="#draggable-dialog-title"
    //     cancel={'[class*="MuiDialogContent-root"]'}
    //   >
    //     <Paper style={{ maxWidth: '1200px', width, minWidth: minWidth || '350px', minHeight: minHeight || '150px' }} {...props} />
    //   </Draggable>        
    // )}
    // aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle className={classNames('draggable-dialog-title', { isDialog })} id="draggable-dialog-title">{title}</DialogTitle>
      <div className="custom-modal-body">
        <div className={classNames('custom-modal-children', { isDialog })}>
          {children}
        </div>
      </div>
      <div className="custom-modal-toolbar">
        <div>
          {
            buttons.map((button, idx) => {
              return (
                <Button key={idx} style={{ marginRight: '10px' }} autoFocus onClick={() => {
                  const form = modalRef.current?.querySelector('form')
                  let values = {}
                  if (form) {
                    const formData = new FormData(form);
                    values = Object.fromEntries(formData)
                  }
                  if (onClose) onClose({ button, values })
                  if (isDialog) setShowModal(false)
                }}>
                  {firstLetterToUpperCase(button)}
                </Button>
              )
            })
          }
        </div>
      </div>
    </Dialog >
  )
}

export default CustomModal