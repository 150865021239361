import { useEffect, useState } from "react"
import AppHeader from "../../components/AppHeader"
import TradeNinjaTab from "../../components/TradeNinjaTab"
import TradeNinjaTabs from "../../components/TradeNinjaTabs"
import './TraderWorkstation.scss'
import PositionsWidget from "./components/widgets/PositionsWidget"
import TradingviewGraph from "../../components/TradingviewGraph"
import TradingviewWatchList from "../../components/TradingviewWatchList"
import { FundamentalData, Screener, StockHeatmap } from "react-ts-tradingview-widgets"
import { useOpenPositions } from "../../hooks/useOpenPositions"
import StocksNotes from "./components/StocksNotes"

const TraderWorkstation = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [selectedSymbol, setSelectedSymbol] = useState<string>('')
  const openPositions = useOpenPositions()

  useEffect(() => {
    if (openPositions && Object.keys(openPositions).length > 0 && !selectedSymbol) {
      for (const symbol of Object.keys(openPositions).sort()) {
        if (openPositions[symbol].posQuantity > 0) {
          setSelectedSymbol(symbol)
          break;
        }
      }
    }
  }, [openPositions, selectedSymbol])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="trader-workspace-container">
      <AppHeader title="Trader Workstation">
      </AppHeader>
      <div className="trader-workspace-body">
        <div className="trader-workspace-left">
          <PositionsWidget openPositions={openPositions} selectedSymbol={selectedSymbol} setSelectedSymbol={setSelectedSymbol} />
        </div>
        <div className="trader-workspace-right">
          <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}>
            <TradeNinjaTab label={`Chart - ${selectedSymbol}`} />
            <TradeNinjaTab label={`Financials - ${selectedSymbol}`} />
            <TradeNinjaTab label="Watch List" />
            <TradeNinjaTab label="Stock Screener" />
            <TradeNinjaTab label="Stock Heatmap" />
            <TradeNinjaTab label="Notes" />
          </TradeNinjaTabs>
          <div className="trader-workspace-tabs-body">
            {
              selectedTab === 0 && <TradingviewGraph symbol={selectedSymbol} />
            }
            {
              selectedTab === 1 && (
                <FundamentalData
                  symbol={selectedSymbol}
                  height="100%"
                  width="100%"
                />
              )
            }
            {
              selectedTab === 2 && <TradingviewWatchList />
            }
            {
              selectedTab === 3 && (
                <Screener
                  defaultColumn="performance"
                  market="america"
                  defaultScreen="most_capitalized"
                  width="100%"
                  height="100%"
                />
              )
            }
            {
              selectedTab === 4 && (
                <StockHeatmap
                  width="100%"
                  height="100%"
                />
              )
            }
            {
              selectedTab === 5 && (
                <StocksNotes />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default TraderWorkstation