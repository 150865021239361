import moment, { Moment } from 'moment';
import prettyMilliseconds from 'pretty-ms';

const getPrettyDuration = (time: string, updatedAt: string, currentTime?: Moment, factor: number = 1): string => {
  if (!time) return ''
  const timeParts = time.split(':')
  const timeInMilliseconds = (parseInt(timeParts[0]) * 3600000) + (parseInt(timeParts[1]) * 60000) + (parseInt(timeParts[2]) * 1000)
  const diff = ((currentTime || moment().utc()).diff(moment(updatedAt), 'seconds') * 1000) * factor
  return prettyMilliseconds(timeInMilliseconds - diff, { verbose: true, keepDecimalsOnWholeSeconds: false })
}

export default getPrettyDuration