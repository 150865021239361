import { FC, useRef } from 'react';
import CustomModal from '../components/CustomModal/CustomModal';
import { HistoricalDataTypeEnum, ModalButtonEnum } from '../types';
import NinjaForm from '../components/NinjaForm';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { newSymbolToHistoricalData } from '../state/historicalData/historicalDataSlice';

type NewHIstoricalDataSymbolProps = {
  open: boolean
  setOpen: (open: boolean) => void
  type: HistoricalDataTypeEnum
  callback: (addedRecord: any) => void
}

const NewHIstoricalDataSymbol: FC<NewHIstoricalDataSymbolProps> = ({ open, setOpen, type, callback }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <CustomModal
      isDialog={false}
      height={'300px'}
      title={`Adding a new symbol to the historical data (${type === HistoricalDataTypeEnum.DAILY ? 'Daily' : 'Intraday'})`}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          const symbol = values.symbol.toUpperCase()
          dispatch(newSymbolToHistoricalData({
            type,
            symbol,
            successCb: (addedRecord: any) => {
              callback(addedRecord)
            }
          }))
        }
        setOpen(false)
      }}
    >
      <>
      <NinjaForm formRef={formRef} OnSubmit={() => { }} fields={
        [
            { name: 'symbol', label: 'Symbol', zod: z.string({ required_error: 'Symbol is required' }).email(), gridWidth: 12, style: { textTransform: "uppercase" } },
          ]} />
      </>
    </CustomModal>
  )
}

export default NewHIstoricalDataSymbol