import { useDispatch, useSelector } from "react-redux"
import AppHeader from "../../components/AppHeader"
import "./BackTestsPage.scss"
import { AppDispatch, RootState } from "../../state/store"
import { ReactNode, useEffect, useState } from "react"
import { getAllBackTestPositions, getAllBackTests } from "../../state/backTest/backTestSlice"
import TradeNinjaTabs from "../../components/TradeNinjaTabs"
import TradeNinjaTab from "../../components/TradeNinjaTab"
import BackTestOverview from "./components/BackTestOverview"
import BackTestListOfTrades from "./components/BackTestListOfTrades"
import NewBackTestModal from "../../modals/NewBackTestModal"
import { getAllBots } from "../../state/bot/botSlice"
import { useBacktestEvent } from "../../hooks/useBacktestEvent"
import Backdrop from '@mui/material/Backdrop';
import { Button, CircularProgress } from "@mui/material"
import { useSearchParams } from "react-router-dom"
import { PerformanceAnalysisDataType } from "../../types"
import BackTestFilter from "../../components/BackTestFilter/components/BackTestFilter"
import { enqueueSnackbar } from "notistack"
import PerformanceAnalysis from "../../components/PerformanceAnalysis"

const BackTestsPage = () => {
  const dispatch = useDispatch<AppDispatch>()
  const backTests = useSelector((state: RootState) => state.backTest.backTests);
  const allBots = useSelector((state: RootState) => state.bot.allBots);
  const backTestPositions = useSelector((state: RootState) => state.backTest.backTestPositions);
  const [selectedTab, setSelectedTab] = useState<number>(0)
  const [openNewBackTestModal, setOpenNewBackTestModal] = useState<boolean>(false)
  const backTestEvent = useBacktestEvent()
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [backDropMessage, setBackDropMessage] = useState<ReactNode>('')
  const [dataForPerformanceAnalysis, setDataForPerformanceAnalysis] = useState<PerformanceAnalysisDataType[]>([])
  const [selectedBackTestsIds, setSelectedBackTestsIds] = useState<string[]>([])
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getAllBackTests())
    dispatch(getAllBots())
  }, [dispatch])

  useEffect(() => {
    const initialBotId = searchParams.get('bot')
    if (initialBotId) setOpenNewBackTestModal(true)

    const tab = searchParams.get('tab')
    if (tab) setSelectedTab(parseInt(tab))

  }, [dispatch, searchParams])

  useEffect(() => {
    if (backTests.data) {
      const filter = searchParams.get('filter')
      if (filter && backTests.data.length > 0) {
        setSelectedBackTestsIds(filter.split(','))
      }

      const allStrategies = backTests.data.reduce((acc, itm) => {
        acc[itm.botOriginal.strategy._id] = itm.botOriginal.strategy.code
        return acc
      }, {})
      const data: PerformanceAnalysisDataType[] = Object.keys(allStrategies || []).map(strategyId => {
        return {
          strategy: {
            _id: strategyId,
            code: allStrategies[strategyId]
          },
          bots: (backTests.data || [])
            .filter((itm: any) => itm.botOriginal.strategy._id === strategyId)
            .map((itm: any) => ({
              backtestId: itm._id,
              _id: itm.botOriginal._id,
              name: itm.botOriginal.name
            }))
        }
      })
      setDataForPerformanceAnalysis(data)
    }
  }, [searchParams, backTests.data])

  useEffect(() => {
    if (selectedBackTestsIds.length > 0) {
      dispatch(getAllBackTestPositions({ backTestIds: selectedBackTestsIds }))
    }
  }, [selectedBackTestsIds, dispatch])

  useEffect(() => {
    if (backTestEvent) {
      switch (backTestEvent.event) {
        case 'ON_BACKTEST_STARTED':
          setShowBackdrop(true)
          setBackDropMessage(
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <div>{`Running BackTest: ${backTestEvent.payload.backtest.botOriginal.name}.`}</div>
            </div>
          )
          break;
        case 'ON_BACKTEST_CLOSED_POSITION':
          setShowBackdrop(true)
          break;
        case 'ON_BACKTEST_FINISHED':
          setShowBackdrop(false)
          dispatch(getAllBackTests())
          break;
        case 'ON_BACKTEST_ERROR':
          enqueueSnackbar(backTestEvent.payload.message, { variant: 'error' })
          setShowBackdrop(false)
          break;
        default:
          break;
      }
    }
  }, [backTestEvent, dispatch])

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setSearchParams(params => {
      params.set('tab', newValue.toString())
      return params;
    });
  };

  return (
    <div className="backtests-page-container">
      <Backdrop
        sx={{ display: 'flex', flexDirection: 'column', gap: '16px', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
        <div>{backDropMessage}</div>
      </Backdrop>
      <NewBackTestModal open={openNewBackTestModal} setOpen={setOpenNewBackTestModal} allBots={allBots} />
      <AppHeader title="Back Tests">
      </AppHeader>
      <div className="backtests-page-body">
        <div className="backtests-page-toolbar-container">
          <div className="backtests-page-toolbar">
            <Button size='small' variant='outlined' onClick={() => setOpenNewBackTestModal(true)}>New Back Test</Button>
          </div>
        </div>
        <div className="backtests-info-container">
          <div className="backtests-info-left">
            <BackTestFilter
              selectedBackTestsIds={selectedBackTestsIds}
              setSelectedBackTestsIds={setSelectedBackTestsIds}
              data={dataForPerformanceAnalysis}
              onApplyFilters={(backtestIds) => {
                setSearchParams(params => {
                  params.set('filter', backtestIds.join(','))
                  return params;
                });
                dispatch(getAllBackTestPositions({ backTestIds: backtestIds }))
              }}
              onDeletedBackTest={(backtestId) => {
                const filter = searchParams.get('filter')
                if (filter) {
                  const backtestIds = filter.split(',')
                  const idx = backtestIds.indexOf(backtestId)
                  if (idx !== -1) {
                    backtestIds.splice(idx)
                    setSearchParams(params => {
                      params.set('filter', backtestIds.join(','))
                      return params;
                    });
                  }
                }
              }}
            />              
          </div>
          <div className="backtests-info-right">
            <TradeNinjaTabs value={selectedTab} onChange={handleTabChange}> 
              <TradeNinjaTab label="Overview" />
              <TradeNinjaTab label="Trades" />
              <TradeNinjaTab label="Performance Analysis" />
            </TradeNinjaTabs>
            <div className="backtests-info-body">
              {selectedTab === 0 && (
                <BackTestOverview backTests={backTests.data.filter(itm => selectedBackTestsIds.includes(itm._id))} initialCapital={50000} tradeSize={10000} backTestPositions={backTestPositions} />
              )}
              {selectedTab === 1 && <BackTestListOfTrades initialCapital={50000} backTestPositions={backTestPositions} />}
              {selectedTab === 2 && <PerformanceAnalysis loading={backTests.loading || backTestPositions.loading} backTests={backTests.data.filter(itm => selectedBackTestsIds.includes(itm._id))} positions={backTestPositions.data} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BackTestsPage