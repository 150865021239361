import ApiClient from "../utils/ApiClient"

const getAllMountedBots = async (): Promise<any> => {
  return ApiClient.get('/tradeninja/all-monted-bots')
}

const getLastExecutions = async (): Promise<any> => {
  return ApiClient.get('/tradeninja/last-executions')
}

const getStrategies = async (): Promise<any> => {
  return ApiClient.get('/tradeninja/strategies')
}

const getBotsByStrategy = async (strategyId: string): Promise<any> => {
  return ApiClient.get(`/tradeninja/bots-by-strategy/${strategyId}`)
}

const updateStrategy = async (strategyId: string, data: any, callback: () => void): Promise<any> => {
  return ApiClient.put(`/tradeninja/update-strategy/${strategyId}`, data).then(resp => {
    callback()
    return resp
  })
}

const createBot = async (strategyId: string, name: string, symbols: any, callback: (createdBot: any) => void): Promise<any> => {
  return ApiClient.post('/tradeninja/create-bot', { strategyId, name, symbols }).then(resp => {
    callback(resp)
    return resp
  })
}

const deleteBot = async (botId: string, callback: (deletedBotId: string) => void): Promise<any> => {
  return ApiClient.delete(`/tradeninja/delete-bot/${botId}`).then(resp => {
    callback(resp)
    return resp
  })
}

const deleteMountedBot = (botId: string) => {
  return ApiClient.delete(`/tradeninja/delete-mounted-bot/${botId}`)
}

const mountBot = async (botId: string, force: boolean, callback: (resp: { code?: number, message?: string, bots?: any[] }) => void): Promise<any> => {
  return ApiClient.post(`/tradeninja/mount-bot/${botId}/${force}`).then(resp => {
    callback(resp)
    return resp
  })
}

const updateBot = async (botId: string, data: any, callback: () => void): Promise<any> => {
  return ApiClient.put(`/tradeninja/update-bot/${botId}`, data).then(resp => {
    callback()
    return resp
  })
}

const getAllBots = async (): Promise<any> => {
  return ApiClient.get('/tradeninja/all-bots')
}

const getEnableBotsIds = async (): Promise<any> => {
  return ApiClient.get('/tradeninja/enabled-bots-ids')
}

const enableBot = async (botId: string, enable: boolean): Promise<any> => {
  return ApiClient.post(`/tradeninja/enabled-bot/${botId}/${enable}`)
}

const buyAndHold = async (symbol: string, tradeSize: number, callback: (createdBot: any) => void): Promise<any> => {
  return ApiClient
    .post('/tradeninja/buy-and-hold', { symbol, tradeSize })
    .then((createdBot: any) => {
      setTimeout(() => callback(createdBot), 100)
      return createdBot
    })
}

const incrementBotPosition = async (botId: string, tradeSize: number, callback: (updatedBot: any) => void): Promise<any> => {
  return ApiClient
    .post('/tradeninja/increment-position-bot', { botId, tradeSize })
    .then((updatedBot: any) => {
      setTimeout(() => callback(updatedBot), 100)
      return updatedBot
    })
}

const sizeUpPosition = async (symbol: string, tradeSize: number): Promise<any> => {
  return ApiClient.post('/tradeninja/sizeup-position', { symbol, tradeSize })
}

const buy = async (symbol: string, tradeSize: number): Promise<any> => {
  return ApiClient.post('/tradeninja/buy', { symbol, tradeSize })
}

const closePosition = async (symbol: string, perc: number): Promise<any> => {
  return ApiClient.put('/tradeninja/close-position', { symbol, perc })
}

const sellAndHold = async (symbol: string, tradeSize: number, callback: (createdBot: any) => void): Promise<any> => {
  return ApiClient.post('/tradeninja/sell-and-hold', { symbol, tradeSize })
    .then((createdBot: any) => {
      setTimeout(() => callback(createdBot), 100)
      return createdBot
    })
}

const cancelBotPosition = async (_id: string): Promise<any> => {
  return ApiClient.delete(`/tradeninja/cancel-position-bot/${_id}`)
}

const closeBotPosition = async (_id: string): Promise<any> => {
  return ApiClient.put(`/tradeninja/close-position-bot/${_id}`)
}


const botService = {
  getLastExecutions,
  getAllMountedBots,
  getStrategies,
  getBotsByStrategy,
  updateStrategy,
  createBot,
  deleteBot,
  deleteMountedBot,
  mountBot,
  updateBot,
  getAllBots,
  getEnableBotsIds,
  enableBot,
  buyAndHold,
  incrementBotPosition,
  sizeUpPosition,
  buy,
  closePosition,
  sellAndHold,
  cancelBotPosition,
  closeBotPosition
}

export default botService