import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../../state/store"
import { useEffect } from "react"
import { getDepositsAndWithdrawals } from "../../../../../state/user/userSlice"
import DataGrid from "../../../../../components/DataGrid"
import './DepositsAndWithdrawalsWidget.scss'
import LoadingData from "../../../../../components/LoadingData"

const DepositsAndWithdrawalsWidget = () => {
  const dispatch = useDispatch<AppDispatch>()
  const depositsAndWithdrawals = useSelector((state: RootState) => state.user.depositsAndWithdrawals);

  useEffect(() => {
    dispatch(getDepositsAndWithdrawals())
  }, [dispatch])

  return (
    <div className="deposits-and-withdrawals-widget-container">
      <LoadingData loading={depositsAndWithdrawals.loading}>
        <DataGrid
          data={depositsAndWithdrawals.data}
          height={350}
          columns={[
            { name: 'settleDate', width: '25%', title: 'Settle Date', type: 'date' },
            { name: 'ref', width: '45%', title: 'Ref.' },
            { name: 'amount', width: '30%', title: 'Amount', align: 'right', type: 'number' },
          ]}
        />
      </LoadingData>
    </div>
  )
}

export default DepositsAndWithdrawalsWidget