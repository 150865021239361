import './OpenOrders.scss'
import { AsyncData } from "../../../../types";
import { FC, useEffect, useState } from "react";
import { useOpenOrders } from '../../../../hooks/useOpenOrders';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../../state/store';

type OpenOrdersProps = {
  allMountedBots: AsyncData<any[]>
}

const OpenOrders: FC<OpenOrdersProps> = ({ allMountedBots }) => {
  const openOrders = useOpenOrders()
  // const screen = useSelector((state: RootState) => state.app.screen);
  const [data, setData] = useState<any>([])

  useEffect(() => {
    setData(Object.keys(openOrders || {}).map(orderId => openOrders[orderId]))
  }, [openOrders])

  return (
    <div className="open-orders-container">
      <div>{JSON.stringify(data, null, 2)}</div>
      {/* <DataGrid
        data={data}
        height={screen.height - 120}
        columns={[
          // { name: 'time', width: '15%', title: 'Time', type: 'datetime' },
          { name: 'side', width: '10%', title: 'Side' },
          // { name: 'symbol', width: '15%', title: 'Symbol' },
          // { name: 'shares', width: '15%', title: 'Shares', align: 'center' },
          // { name: 'avgPrice', width: '10%', title: 'Price', type: 'number', align: 'right' },
          // {
          //   name: 'botId', width: '35%', title: 'Bot', renderer: (data, record) => {
          //     const foundBot = allMountedBots.data.find(itm => itm._id === record.botId)
          //     if (foundBot.type === 'BUY_AND_HOLD') return foundBot.name
          //     return <a href="#">{foundBot.name}</a>
          //   }
          // },
        ]}
      /> */}
    </div>
  )
}

export default OpenOrders