import { AreaChart, AreaChartProps } from '@opd/g2plot-react'
import { FC, useEffect, useRef, useState } from 'react'
import { formatNumber } from 'humanize-plus'
import moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../utils/constants'
import { Datum } from '@antv/g2plot'
import './PortfolioPerformanceChart.scss'

type PortfolioPerformanceChartProps = {
  initialCapital: number
  positions: any[]
  startMonth: string
  endMonth: string
}

type GraphDataType = {
  datetime: string
  balance: number
}

const PortfolioPerformanceChart: FC<PortfolioPerformanceChartProps> = ({ positions, initialCapital, startMonth, endMonth }) => {
  const [graphConfig, setGraphConfig] = useState<AreaChartProps>()
  const chartRef = useRef<any>()

  const normalizeDataForGraph = (initialCapital: number, startMonth: string, endMonth: string, positions: any[]): GraphDataType[] => {
    if (!startMonth || !endMonth || positions.length === 0) return []
    const startDate = startMonth.substring(0, 10)
    const endDate = endMonth.substring(0, 10)
    const currentDateMom = moment(startDate).tz(DEFAULT_TIMEZONE, true)
    const endDateMom = moment(endDate).tz(DEFAULT_TIMEZONE, true)
    const dateInterval: any = []
    while (currentDateMom.isSameOrBefore(endDateMom)) {
      dateInterval.push(currentDateMom.format('YYYY-MM-DD'))
      currentDateMom.add(1, 'day')
    }

    let currentBalance = initialCapital
    return dateInterval.reduce((acc: GraphDataType[], date: string) => {
      const positionsInDate = positions.filter(itm => itm.exit.datetime.substring(0, 10) === date)
      if (positionsInDate.length > 0) {
        const profitsInDate = positionsInDate.reduce((acc: number, position: any) => {
          acc += position.exit.profit
          return acc
        }, 0)
        currentBalance += profitsInDate
      }
      acc.push({ datetime: date, balance: currentBalance })
      return acc
    }, [{ datetime: startDate, balance: initialCapital }])
  }

  useEffect(() => {
    if (positions) {
      const graphData = normalizeDataForGraph(initialCapital || 0, startMonth, endMonth, positions)
      setGraphConfig({
        height: 350,
        autoFit: true,
        xField: 'datetime',
        yField: 'balance',
        yAxis: {
          min: 45000,
        },
        xAxis: {
          label: null
        },
        style: {
          flex: 1
        },
        line: {
          color: '#1890ff',
          size: 1
        },
        areaStyle: (datum: Datum) => {
          return {
            fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
          }
        },
        data: graphData,
        tooltip: {
          formatter: (datum) => ({
            name: '<b>Balance</b>',
            value: formatNumber(datum.balance, 2)
          }),
        },
      })
    }

  }, [positions, initialCapital, startMonth, endMonth])  

  return (
    <div className="portfolio-performance-chart-container">
      <AreaChart {...graphConfig!} chartRef={chartRef} />
    </div>
  )
}

export default PortfolioPerformanceChart