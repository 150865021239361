import './PositionsWidget.scss'
import { formatNumber } from 'humanize-plus';
import { FC, useEffect, useState } from 'react';
import { usePrices } from '../../../../../hooks';
import { closePosition, getLastExecutions } from '../../../../../state/bot/botSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../../state/store';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton } from '@mui/material';
import DropDownMenu from '../../../../../components/DropDownMenu';
import SizeUpPositionModal from '../../../../../modals/SizeUpPositionModal';
import classNames from 'classnames';
import walletPNG from '../../../../../assets/images/wallet.png'
import piggyPNG from '../../../../../assets/images/piggy.png'
import AddIcon from '@mui/icons-material/Add';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { useAccountSummary } from '../../../../../hooks/useAccountSummary';
import BuyModal from '../../../../../modals/BuyModal';
import NinjaTooltip from '../../../../../components/NinjaTooltip';

type PositionsWidgetProps = {
  openPositions: any
  selectedSymbol: string
  setSelectedSymbol: (symbol: string) => void
}

const PositionsWidget: FC<PositionsWidgetProps> = ({ openPositions, selectedSymbol, setSelectedSymbol }) => {
  const dispatch = useDispatch<AppDispatch>()
  const [appMenuButtonAnchorEl, setAppMenuButtonAnchorEl] = useState<null | HTMLElement>(null);
  const [openPositionsData, setOpenPositionsData] = useState<any[]>([])
  const [last24HoursExecutions, setLast24HoursExecutions] = useState<any>({})
  const [openSizingUpPositionModal, setOpenSizingUpPositionModal] = useState<boolean>(false)
  const [openBuyModal, setOpenBuyModal] = useState<boolean>(false)
  const [totalInvested, setTotalInvested] = useState<number>(0)
  const lastExecutions = useSelector((state: RootState) => state.bot.lastExecutions);
  const isAppMenuOpen = Boolean(appMenuButtonAnchorEl);
  const prices = usePrices()
  const accountSummary = useAccountSummary()

  useEffect(() => {
    dispatch(getLastExecutions())
  }, [dispatch])

  useEffect(() => {
    // const lastMidmight = moment().tz(DEFAULT_TIMEZONE, true).startOf('day')
    const execs = lastExecutions.data/*.filter((itm: any) => moment(itm.time).isAfter(lastMidmight))*/.reduce((acc, itm) => {
      acc[itm.symbol] = acc[itm.symbol] || []
      acc[itm.symbol].push(itm)
      return acc
    }, {})
    setLast24HoursExecutions(execs)
  }, [lastExecutions.data])

  useEffect(() => {
    if (openPositions && Object.keys(last24HoursExecutions).length > 0 && prices) {
      const openPosData = Object.keys(openPositions || {}).map(symbol => {
        const position = openPositions[symbol]
        const price = prices[symbol]
        const costValue = position.posQuantity * position.avgCost
        const marketValue = position.posQuantity * price
        const profit = marketValue - costValue
        const profitPerc = profit * 100 / costValue

        return {
          symbol,
          avgCost: position.avgCost,
          posQuantity: position.posQuantity,
          marketValue,
          profit,
          profitPerc
        }
      })
      setOpenPositionsData(openPosData)
      setTotalInvested(openPosData.reduce((acc, position) => {
        acc += position.marketValue
        return acc
      }, 0))
    }
  }, [openPositions, last24HoursExecutions, prices])

  const handleAppMenuButtonOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAppMenuButtonAnchorEl(event.currentTarget);
  };

  return (
    <div className="positions-widget-container">
      <SizeUpPositionModal symbol={selectedSymbol} open={openSizingUpPositionModal} setOpen={setOpenSizingUpPositionModal} />
      <BuyModal open={openBuyModal} setOpen={setOpenBuyModal} />
      <DropDownMenu open={isAppMenuOpen} arrowPosition='RIGHT' anchorEl={appMenuButtonAnchorEl} handleClose={() => setAppMenuButtonAnchorEl(null)} menuItems={[
        {
          text: 'Size Up', onClick: () => {
            setOpenSizingUpPositionModal(true)
          }, divider: true
        },
        { text: 'Close 100%', onClick: () => dispatch(closePosition({ symbol: selectedSymbol, perc: 100 })) },
        { text: 'Close 75%', onClick: () => dispatch(closePosition({ symbol: selectedSymbol, perc: 75 })) },
        { text: 'Close 50%', onClick: () => dispatch(closePosition({ symbol: selectedSymbol, perc: 50 })) },
        { text: 'Close 25%', onClick: () => dispatch(closePosition({ symbol: selectedSymbol, perc: 25 })) },
      ]} />
      <div className="positions-header">
        <div className="positions-header-value-container">
          <img alt="invested value" src={piggyPNG} style={{ marginRight: '8px' }} />
          <span className="positions-header-value">{formatNumber(totalInvested, 2)}</span>
        </div>
        <AddIcon style={{ color: '#64748b', fontSize: '42px' }} />
        <div className="positions-header-value-container">
          <img alt="cash value" src={walletPNG} />
          <span className="positions-header-value">{formatNumber(accountSummary.TotalCashValue || 0, 2)}</span>
        </div>
        <DragHandleIcon style={{ color: '#64748b', fontSize: '42px' }} />
        <div className="positions-header-value-container">
          <span className="positions-header-value" style={{ fontWeight: 500, fontSize: '18px' }}>{accountSummary.TotalCashValue > 0 ? formatNumber(totalInvested + parseFloat(accountSummary.TotalCashValue), 2) : '0.00'}</span>
        </div>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-end' }}>
          <NinjaTooltip message="Buy to staring a new position">
            <IconButton color="primary" aria-label="add to shopping cart" onClick={() => setOpenBuyModal(true)}>
              <AddShoppingCartIcon style={{ opacity: 1 }} />
            </IconButton>
          </NinjaTooltip>
        </div>
      </div>
      <div className="positions-data-grid">
        <div className="positions-data-grid-header-row">
          <div className="positions-data-grid-header-col">Pos</div>
          <div className="positions-data-grid-header-col">Market Value</div>
          <div className="positions-data-grid-header-col">Unrlzd P&L</div>
        </div>
        {
          openPositionsData
            .filter(position => position.posQuantity > 0)
            .sort((pos1, pos2) => {
              if (pos1.symbol > pos2.symbol) return 1
              if (pos1.symbol < pos2.symbol) return -1
              return 0
            }).map(position => {
              return (
                <div className={classNames('positions-data-grid-row', { selected: selectedSymbol === position.symbol,  withProfit: position.profit > 0 })} onClick={() => setSelectedSymbol(position.symbol)}>
                  <div className="positions-data-grid-col" style={{ width: '80px' }}><a href={`https://www.tradingview.com/symbols/${position.symbol}`} target="_blank" rel="noreferrer">{position.symbol}</a></div>
                  <div className="positions-data-grid-row-body">
                    <div className="positions-data-grid-col">{position.posQuantity}</div>
                    <div className="positions-data-grid-col">{position.marketValue ? formatNumber(position.marketValue, 2) : ''}</div>
                    <div className="positions-data-grid-col">
                      {
                        position.posQuantity > 0 && !Number.isNaN(position.profit) && (
                          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                            <div style={{ color: position.profit > 0 ? '#10b981' : '#ef4444', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                              <div>{`${formatNumber(position.profit, 2)}`}</div>
                              <div style={{ minWidth: '40px', fontSize: '12px', marginLeft: '4px' }}>{`${formatNumber(position.profitPerc, 1)}%`}</div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                    <IconButton onClick={(e) => {
                      setSelectedSymbol(position.symbol)
                      handleAppMenuButtonOpen(e)
                    }}>
                      <MoreVertIcon />
                    </IconButton>
                  </div>
                </div>
              )
            })
        }
      </div>
    </div>
  )
}

export default PositionsWidget