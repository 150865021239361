import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import './NinjaTooltip.scss'
import { FC, ReactNode } from 'react';
import { Box } from '@mui/material';

const borderColor = '#cbd5e1'
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${borderColor}`,
    color: "#0c4a6e",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(14),
    borderRadius: '8px',
    padding: '12px'
  },
  [`& .${tooltipClasses.arrow}`]: {
    "&:before": {
      border: `1px solid ${borderColor}`
    },
    color: theme.palette.common.white
  },
  [`& .${tooltipClasses.popperArrow}`]: {
    backgroundColor: '#f5f5f9',
    borderColor: 'rgba(0, 0, 0, 0.87)',
    width: '32px !important',
    height: '28px !important',
  }
}));

type NinjaTooltipProps = {
  children: ReactNode
  message: string | ReactNode
}

const NinjaTooltip: FC<NinjaTooltipProps> = ({ children, message }) => {
  return (
    <HtmlTooltip title={message}>
      <Box>{children}</Box>
    </HtmlTooltip>
  )
}

export default NinjaTooltip