import { FC, useEffect, useRef, useState } from 'react';
import './NinjaTextEditor.scss'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';

interface NinjaTextEditorProps extends SunEditorReactProps {
  html: string;
}

const NinjaTextEditor: FC<NinjaTextEditorProps> = props => {
  const [hasFocus, setHasFocus] = useState<boolean>(false)
  const editor = useRef();

  useEffect(() => {
    if (editor.current && !hasFocus) (editor.current as any).setContents(props.html)
  }, [props.html, hasFocus])

  const getSunEditorInstance = (sunEditor: any) => {
    editor.current = sunEditor;
  };

  return (
    <div className='ninja-text-editor-container'>
      <SunEditor
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        getSunEditorInstance={getSunEditorInstance}
        setDefaultStyle="font-family:Ubuntu; font-size:14px; line-height:12px"
        setOptions={{
          resizingBar: false,
          buttonList: [
            ['font', 'fontSize', 'formatBlock'],
            ['fontColor', 'hiliteColor'],
            ['outdent', 'indent'],
            ['align', 'horizontalRule', 'list', 'table'],
            ['link', 'image'],
            ['fullScreen', 'preview', 'codeView']
          ],
          value: props.html
        }}
        defaultValue={props.html}
        {...props}
      />
    </div>
  )
}

export default NinjaTextEditor