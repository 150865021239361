
import { FC, ReactNode } from "react"
import ReactLoading from "react-loading";

type LoadingDataProps = {
  loading: boolean
  children: ReactNode
}

const LoadingData: FC<LoadingDataProps> = ({ loading, children }) => {

  //
  if (loading) return <ReactLoading type="bubbles" color="#38bdf8" />

  return <>{children}</>
}

export default LoadingData