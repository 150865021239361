import { useEffect, useRef, FC, memo } from 'react';

type TradingviewGraphProps = {
  symbol: string
}

const TradingviewGraph: FC<TradingviewGraphProps> = ({ symbol }) => {
  const container = useRef<any>();

  useEffect(() => {
    if (symbol) {
      const iFrame = container.current.querySelector('iframe')
      if (iFrame) iFrame.remove()

      const script = document.createElement("script");
      script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
        {
          "autosize": true,
          "symbol": "${symbol}",
          "interval": "D",
          "timezone": "exchange",
          "theme": "light",
          "style": "1",
          "locale": "en",
          "gridColor": "rgba(255, 255, 255, 0.06)",
          "hide_side_toolbar": false,
          "hide_legend": false,
          "allow_symbol_change": false,
          "save_image": true,
          "details": false,
          "calendar": false,
          "studies": [
            "STD;RSI",
            "BookerIntradayPivots@tv-basicstudies",
            "STD;Stochastic_RSI",
            "STD;Supertrend"
          ],
          "hide_volume": true
        }`;
      container.current.appendChild(script);
    }
  }, [symbol]);

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
    </div>
  );
}

export default memo(TradingviewGraph);