import { FC, ReactNode, useEffect, useState } from "react"
import './HistoricalDataGrid.scss'
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../../state/store"
import DataGrid from "../../../../components/DataGrid"
import { Button, CircularProgress } from "@mui/material"
import NinjaLinearProgressWithLabel from "../../../../components/NinjaLinearProgressWithLabel"
import moment from "moment-timezone"
import { DEFAULT_TIMEZONE } from "../../../../utils/constants"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { openDialogConfirm } from "../../../../utils/openDialog"
import { HistoricalDataTypeEnum } from "../../../../types"
import { removeSymbolFromHistoricalData, updateHistoricalDataDaily, updateHistoricalDataIntraday, upgradeRecordDaily, upgradeRecordIntraday } from "../../../../state/historicalData/historicalDataSlice"
import NewHIstoricalDataSymbol from "../../../../modals/NewHIstoricalDataSymbol"
import { getPreviousMarketWorkingDay } from "../../../../utils/getPreviousMarketWorkingDay"
import { useHistoricalDataUpdateEvent } from "../../../../hooks/useHistoricalDataUpdateEvent"
import Backdrop from '@mui/material/Backdrop';
import { formatNumber } from "humanize-plus"

type HistoricalDataGridProps = {
  type: HistoricalDataTypeEnum
  data: any[]
}

const yesterday = getPreviousMarketWorkingDay(moment().tz(DEFAULT_TIMEZONE))
const HistoricalDataGrid: FC<HistoricalDataGridProps> = ({ type, data }) => {
  const screen = useSelector((state: RootState) => state.app.screen);
  const [selectItemId, setSelectItemId] = useState(data.length > 0 ? data[0]._id : null)
  const [openNewHIstoricalDataSymbol, setOpenNewHIstoricalDataSymbol] = useState<boolean>(false)
  const historicalDataUpdateEvent = useHistoricalDataUpdateEvent()
  const [showBackdrop, setShowBackdrop] = useState(false)
  const [backDropMessage, setBackDropMessage] = useState<ReactNode>('')
  const [sortedData, setSortedData] = useState<any[]>([])
  const dispatch = useDispatch<AppDispatch>()

  useEffect(() => {
    if (data) {
      setSortedData([...data].sort((itm1: any, itm2: any) => {
        if (itm1.symbol > itm2.symbol) return 1
        if (itm1.symbol < itm2.symbol) return -1
        return 0
      }))
    }
  }, [data])

  useEffect(() => {
    if (historicalDataUpdateEvent) {
      switch (historicalDataUpdateEvent.event) {
        case 'ON_HISTORICAL_DATA_UPDATE_STARTED':
          setShowBackdrop(true)
          break;
        case 'ON_HISTORICAL_DATA_UPDATE_PROGRESS':
          setShowBackdrop(true)
          const payload = historicalDataUpdateEvent.payload
          if (type === HistoricalDataTypeEnum.DAILY) {
            dispatch(upgradeRecordDaily({ symbol: historicalDataUpdateEvent.payload.symbol, data: payload }))
          } else {
            dispatch(upgradeRecordIntraday({ symbol: historicalDataUpdateEvent.payload.symbol, data: payload }))
          }

          const startDate = moment(payload.startDate, 'YYYY-MM-DD').tz(DEFAULT_TIMEZONE, true)
          const endDate = moment(payload.endDate, 'YYYY-MM-DD').tz(DEFAULT_TIMEZONE, true)
          const totalDays = yesterday.diff(startDate, 'days')
          const executedDays = endDate.diff(startDate, 'days')
          const perc = executedDays * 100 / totalDays
          setBackDropMessage(
            <div style={{ display:'flex', flexDirection:'column', alignItems:'center' }}>
              <div>{`Updating Historical Data (${type === HistoricalDataTypeEnum.DAILY ? 'Daily' : 'Intraday'}) "${historicalDataUpdateEvent.payload.symbol}"`}</div>
              <div>{`from ${payload.startDate} to ${payload.endDate}`}</div>              
              <div>{formatNumber(perc, 1)}%</div>
            </div>
          )
          break;
        case 'ON_HISTORICAL_DATA_UPDATE_ERROR':
          setShowBackdrop(false)
          break;
        case 'ON_HISTORICAL_DATA_UPDATE_FINISHED':
          setShowBackdrop(false)
          // const payloadFinish: any = { endDate: historicalDataUpdateEvent.payload.endDate }
          // if (historicalDataUpdateEvent.payload.startDate) payloadFinish.startDate = historicalDataUpdateEvent.payload.startDate
          // if (type === HistoricalDataTypeEnum.DAILY) {
          //   dispatch(upgradeRecordDaily({ symbol: historicalDataUpdateEvent.payload.symbol, data: payloadFinish }))
          // } else {
          //   dispatch(upgradeRecordIntraday({ symbol: historicalDataUpdateEvent.payload.symbol, data: payloadFinish }))
          // }
          break;
        default:
          break;
      }
    }
  }, [historicalDataUpdateEvent, type, dispatch])

  return (
    <div className="historical-data-grid-container">
      <Backdrop
        sx={{ display: 'flex', flexDirection: 'column', gap: '16px', color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showBackdrop}
      >
        <CircularProgress color="inherit" />
        <div>{backDropMessage}</div>
      </Backdrop>
      <NewHIstoricalDataSymbol
        open={openNewHIstoricalDataSymbol}
        type={type}
        setOpen={setOpenNewHIstoricalDataSymbol}
        callback={(addedRecord) => {
          setSelectItemId(addedRecord._id)
        }} />
      <div className='historical-data-grid-toolbar-container'>
        <div className='historical-data-grid-toolbar'>
          <Button size='small' variant='outlined' onClick={() => setOpenNewHIstoricalDataSymbol(true)}>New Symbol</Button>
          <Button size='small' variant='outlined' color='success'>Update All Symbols</Button>
        </div>
      </div>
      <DataGrid
        selectable={true}
        shouldSelectRow={(record: any) => record._id === selectItemId}
        onSelectRecord={(record: any) => {
          setSelectItemId(record._id)
        }}
        height={screen.height - 170}
        data={sortedData}
        columns={[
          { name: 'symbol', title: 'Symbol', width: '7%', align: 'center' },
          { name: 'startDate', title: 'Start', width: '8%', align: 'center' },
          { name: 'endDate', title: 'End', width: '8%', align: 'center' },
          {
            name: '_id', width: '77%', renderer: (data, record) => {
              const startDate = moment(record.startDate, 'YYYY-MM-DD').tz(DEFAULT_TIMEZONE, true)
              const endDate = moment(record.endDate, 'YYYY-MM-DD').tz(DEFAULT_TIMEZONE, true)
              const totalDays = yesterday.diff(startDate, 'days')
              const executedDays = endDate.diff(startDate, 'days')
              const value = executedDays * 100 / totalDays
              return (
                <div className="historical-data-grid-progress">
                  <NinjaLinearProgressWithLabel value={value} />
                  <Button
                    onClick={() => {
                      if (type === HistoricalDataTypeEnum.DAILY) {
                        dispatch(updateHistoricalDataDaily({ symbol: record.symbol }))
                      } else {
                        dispatch(updateHistoricalDataIntraday({ symbol: record.symbol }))
                      }
                    }}
                    disabled={value >= 100 || showBackdrop}
                    size='small'
                    variant='outlined'
                    color='success'
                    style={{ marginLeft: '6px', marginRight: '6px' }}
                  >Update Symbol
                  </Button>
                  <DeleteForeverIcon onClick={() => {
                    openDialogConfirm(`Are you sure you want to delete the symbol "${record.symbol}" from the Historical Date (${type === HistoricalDataTypeEnum.DAILY ? 'Daily' : 'Intraday'})?`, button => {
                      if (button === 'confirm') {
                        dispatch(removeSymbolFromHistoricalData({
                          type,
                          symbol: record.symbol,
                          successCb: (symbolRemoved: string) => {
                            console.log(symbolRemoved)
                          },
                        }))
                      }
                    })
                  }} />
                </div>
              )
            }
          },

        ]}
      />
    </div>
  )
}

export default HistoricalDataGrid