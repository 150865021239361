import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../state/store";
import { AsyncData } from "../types";
import { useEffect } from "react";
import { getAllMountedBots } from "../state/bot/botSlice";

export const useMountedBots = (): AsyncData<any[]> => {
  const dispatch = useDispatch<AppDispatch>()
  const allMountedBots = useSelector((state: RootState) => state.bot.allMountedBots);

  useEffect(() => {
    dispatch(getAllMountedBots())
  }, [dispatch])

  return allMountedBots
}