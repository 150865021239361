import { PayloadAction, createSlice } from "@reduxjs/toolkit"

interface AppState {
  screen: {
    width: number
    height: number
  }
}

const initialState: AppState = {
  screen: {
    width: 0,
    height: 0
  }
}

const counterSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setScreen: (state, action: PayloadAction<{ width: number, height: number }>) => {
      state.screen = action.payload
    },
  },
})

export const { setScreen } = counterSlice.actions;

export default counterSlice.reducer;