import { LineChart, LineChartProps } from '@opd/g2plot-react'
import { FC, useEffect, useRef, useState } from 'react'
import { formatNumber } from 'humanize-plus'
import moment from 'moment-timezone'
import { DEFAULT_TIMEZONE } from '../../utils/constants'
import './PortfolioComparingChart.scss'

type PortfolioComparingChartProps = {
  backTests: any[]
  initialCapital: number
  positions: any[]
  startMonth: string
  endMonth: string
}

type GraphDataType = {
  botName: string
  datetime: string
  balance: number
}

const PortfolioComparingChart: FC<PortfolioComparingChartProps> = ({ backTests, positions, initialCapital, startMonth, endMonth }) => {
  const [graphConfig, setGraphConfig] = useState<LineChartProps>()
  const chartRef = useRef<any>()

  const normalizeDataForGraph = (): GraphDataType[] => {
    if (!startMonth || !endMonth || positions.length === 0) return []
    const botsNames = Array.from(new Set(backTests.map(itm => itm.botOriginal.name)))
    const positionsToUse = positions.map(position => {
      const foundBackTest: any = backTests.find(itm => itm._id === position.backTestId)
      return {
        ...position,
        botName: foundBackTest.botOriginal.name
      }
    })
    const startDate = startMonth.substring(0, 10)
    const endDate = endMonth.substring(0, 10)
    const currentDateMom = moment(startDate).tz(DEFAULT_TIMEZONE, true)
    const endDateMom = moment(endDate).tz(DEFAULT_TIMEZONE, true)
    const dateInterval: any = []
    while (currentDateMom.isSameOrBefore(endDateMom)) {
      dateInterval.push(currentDateMom.format('YYYY-MM-DD'))
      currentDateMom.add(1, 'day')
    }

    const currentBalance = botsNames.reduce((acc: any, botName: string) => {
      acc[botName] = initialCapital
      return acc
    }, {})
    const dataForGraph = dateInterval.reduce((acc: GraphDataType[], date: string) => {
      botsNames.forEach((botName: string) => {
        const positionsInDate = positionsToUse.filter(itm => itm.exit.datetime.substring(0, 10) === date)
        if (positionsInDate.length > 0) {
          const profitsInDate = positionsInDate.reduce((acc2: number, position: any) => {
            if (position.botName === botName) {
              acc2 += position.exit.profit
            }
            return acc2
          }, 0)
          currentBalance[botName] += profitsInDate
        }
        acc.push({ botName, datetime: date, balance: currentBalance[botName] })
      })
      return acc

    }, botsNames.map(botName => [{ botName, datetime: startDate, balance: initialCapital }]))

    return dataForGraph 
  }

  useEffect(() => {
    if (positions) {
      const graphData = normalizeDataForGraph()
      setGraphConfig({
        height: 350,
        autoFit: true,
        xField: 'datetime',
        yField: 'balance',
        yAxis: {
          min: 45000,
          label: {
            formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
          },
        },
        xAxis: {
          label: null
        },
        style: {
          flex: 1
        },
        seriesField: 'botName',
        data: graphData,
        legend: {
          title: {
            style: { x: 50, textAlign: 'center', fontWeight: 600, color: 'red', fontSize: 18 },
            text: 'Bots',
          },
          position: 'right-top',
          offsetX: 10
        },
        tooltip: {
          customItems: (originalItems: any[]) => {
            return originalItems.sort((a, b) => {
              if (parseFloat(a.value) > parseFloat(b.value)) return -1
              if (parseFloat(a.value) < parseFloat(b.value)) return 1
              return 0
            })
          }, 
          formatter: (datum) => {
            return {
              name: `<b>${datum.botName}</b>`,
              value: formatNumber(datum.balance, 2)
            }
          },
        },
      })
    }

  //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [positions, initialCapital, startMonth, endMonth])

  return (
    <div className="portfolio-comparing-chart-container">
      <LineChart {...graphConfig!} chartRef={chartRef} />
    </div>
  )
}

export default PortfolioComparingChart