import './NinjaSelect.scss'
import { StateManagerProps } from 'react-select/dist/declarations/src/stateManager'
import Select from 'react-select';
import { FC, useState } from 'react'
import { InputLabel } from '@mui/material';
import classNames from 'classnames';

const NinjaSelect: FC<StateManagerProps> = (props) => {
  const [focused, setFocused] = useState<boolean>(false)



  return (
    <div className={classNames('ninja-select-container', { focused })}>
      {
        props['aria-label'] && (
          <InputLabel shrink>{props['aria-label']}</InputLabel>
        )
      }
      <Select
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        placeholder=""
        classNames={{
          menuList: () => 'react-select-menulist',
          container: () => 'react-select-container',
          control: (state) => state.isFocused ? 'react-select-control-focused' : 'react-select-control-not-focused',
        }}
        {...props}
      />
    </div>
  )
}

export default NinjaSelect