import { enqueueSnackbar } from "notistack"
import { HistoricalDataTypeEnum } from "../types"
import ApiClient from "../utils/ApiClient"

const getHistoricalDataDaily = (): Promise<any> => {
  return ApiClient.get('/backtest/historical-data-daily')
}

const getHistoricalDataIntraday = (): Promise<any> => {
  return ApiClient.get('/backtest/historical-data-intraday')
}

const updateHistoricalDataDaily = (symbol: string): Promise<any> => {
  return ApiClient.post(`/backtest/update-historical-data-daily/${symbol}`)
}

const updateHistoricalDataIntraday = (symbol: string): Promise<any> => {
  return ApiClient.post(`/backtest/update-historical-data-intraday/${symbol}`)
}

const newSymbolToHistoricalData = (type: HistoricalDataTypeEnum, symbol: string, successCb: (addedRecord: any) => void): Promise<any> => {
  return ApiClient.post('/backtest/historical-data-new-symbol', { type, symbol })
    .then((addedRecord: any) => {
      enqueueSnackbar(`Symbol "${symbol}" added successfully.`, { variant: 'success' })
      setTimeout(() => successCb(addedRecord), 100)
      return addedRecord
    })
}

const removeSymbolFromHistoricalData = (type: HistoricalDataTypeEnum, symbol: string, successCb: (type: HistoricalDataTypeEnum, symbolRemoved: string) => void): Promise<any> => {
  return ApiClient.delete(`/backtest/historical-data-remove-symbol/${type}/${symbol}`)
    .then((resp: any) => {
      enqueueSnackbar(`Symbol "${symbol}" removed successfully.`, { variant: 'success' })
      setTimeout(() => successCb(resp.type, resp.symbolRemoved), 100)
      return resp
    })
}

const historicalDataService = {
  getHistoricalDataDaily,
  getHistoricalDataIntraday,
  updateHistoricalDataDaily,
  updateHistoricalDataIntraday,
  newSymbolToHistoricalData,
  removeSymbolFromHistoricalData
}

export default historicalDataService