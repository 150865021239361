
const calculateMaxDrawdown = (initialCapital: number, positions: any[]): { value: number, perc: number } => {
  let currentCapital = initialCapital
  let maxCurrentCapital = initialCapital
  let maxDrawdownValue = 0
  let maxDrawdownPerc = 0
  positions.forEach(position => {
    currentCapital += position.exit.profit
    maxCurrentCapital = Math.max(currentCapital, maxCurrentCapital)
    if (position.exit.profit < 0) {
      const drawdown = maxCurrentCapital - currentCapital
      if (drawdown > maxDrawdownValue) {
        maxDrawdownValue = Math.max(maxDrawdownValue, maxCurrentCapital - currentCapital)
        maxDrawdownPerc = maxCurrentCapital * 100 / currentCapital - 100
      }
    }
  })
  return {
    value: maxDrawdownValue,
    perc: maxDrawdownPerc
  }
}

export default calculateMaxDrawdown