import { FC, useRef } from 'react';
import CustomModal from '../components/CustomModal/CustomModal';
import { ModalButtonEnum } from '../types';
import NinjaForm from '../components/NinjaForm';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { sellAndHold } from '../state/bot/botSlice';

type SellAndHoldModalProps = {
  open: boolean
  setOpen: (open: boolean) => void
  callback: (createdBot: any) => void
}

const SellAndHoldModal: FC<SellAndHoldModalProps> = ({ open, setOpen, callback }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <CustomModal
      isDialog={false}
      title={'Starting a Short Position'}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          const symbol = values.symbol.toUpperCase()
          const tradeSize = parseFloat(values.tradeSize)
          
          dispatch(sellAndHold({ symbol, tradeSize, callback }))
        }
        setOpen(false)
      }}
    >
      <>
      <NinjaForm formRef={formRef} OnSubmit={() => { }} fields={
        [
            { name: 'symbol', label: 'Symbol', zod: z.string({ required_error: 'Symbol is required' }).email(), gridWidth: 8, style: { textTransform: "uppercase" } },
            { name: 'tradeSize', label: 'Trade Size($)', zod: z.bigint({ required_error: 'Trade Size is required' }), gridWidth: 4, defaultValue: localStorage.getItem('buy-and-hold-amount') },
          ]} />
      </>
    </CustomModal>
  )
}

export default SellAndHoldModal