import { useEffect, useRef, memo } from 'react';

const TradingviewWatchList = () => {
  const container = useRef<any>();

  useEffect(() => {
    const iFrame = container.current.querySelector('iframe')
    if (iFrame) iFrame.remove()

    const script = document.createElement("script");
    script.src = "https://s3.tradingview.com/external-embedding/embed-widget-market-overview.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
        {
          "title": "Stocks",
          "tabs": [
            {
              "title": "Technology",
              "symbols": [
                {
                  "s": "NASDAQ:AAPL",
                  "d": "Apple"
                },
                {
                  "s": "NASDAQ:GOOGL",
                  "d": "Alphabet"
                },
                {
                  "s": "NASDAQ:MSFT",
                  "d": "Microsoft"
                },
                {
                  "s": "NASDAQ:FB",
                  "d": "Meta Platforms"
                },
                {
                  "s": "NYSE:ORCL",
                  "d": "Oracle Corp"
                },
                {
                  "s": "NASDAQ:INTC",
                  "d": "Intel Corp"
                }
              ]
            },
            {
              "title": "Financial",
              "symbols": [
              {
                "s": "NYSE:JPM",
                "d": "JPMorgan Chase"
              },
              {
                "s": "NYSE:WFC",
                "d": "Wells Fargo Co New"
              },
              {
                "s": "NYSE:BAC",
                "d": "Bank Amer Corp"
              },
              {
                "s": "NYSE:HSBC",
                "d": "Hsbc Hldgs Plc"
              },
              {
                "s": "NYSE:C",
                "d": "Citigroup Inc"
              },
              {
                "s": "NYSE:MA",
                "d": "Mastercard Incorporated"
              }
              ]
            },
            {
              "title": "Services",
              "symbols": [
                {
                  "s": "NASDAQ:AMZN",
                  "d": "Amazon"
                },
                {
                  "s": "NYSE:BABA",
                  "d": "Alibaba Group Hldg Ltd"
                },
                {
                  "s": "NYSE:T",
                  "d": "At&t Inc"
                },
                {
                  "s": "NYSE:WMT",
                  "d": "Walmart"
                },
                {
                  "s": "NYSE:V",
                  "d": "Visa"
                }
              ]
            },
            {
              "title": "Others",
              "symbols": [
                {
                  "s": "HIMS",
                  "d": "Hims & Hers Health, Inc."
                }
              ]
            }
        ],
        "width": "100%",
        "height": "100%",
        "showChart": true,
        "showFloatingTooltip": true,
        "locale": "en",
        "plotLineColorGrowing": "#2962FF",
        "plotLineColorFalling": "#2962FF",
        "belowLineFillColorGrowing": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorFalling": "rgba(41, 98, 255, 0.12)",
        "belowLineFillColorGrowingBottom": "rgba(41, 98, 255, 0)",
        "belowLineFillColorFallingBottom": "rgba(41, 98, 255, 0)",
        "gridLineColor": "rgba(240, 243, 250, 0)",
        "scaleFontColor": "rgba(120, 123, 134, 1)",
        "showSymbolLogo": true,
        "symbolActiveColor": "rgba(41, 98, 255, 0.12)",
        "colorTheme": "light"
      }`;
    container.current.appendChild(script);
  }, []);

  return (
    <div className="tradingview-widget-container" ref={container} style={{ height: "100%", width: "100%" }}>
      <div className="tradingview-widget-container__widget" style={{ height: "calc(100% - 32px)", width: "100%" }}></div>
    </div>
  );
}

export default memo(TradingviewWatchList);