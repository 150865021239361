import { FC, useRef } from 'react';
import CustomModal from '../components/CustomModal/CustomModal';
import { ModalButtonEnum } from '../types';
import NinjaForm from '../components/NinjaForm';
import { z } from 'zod';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../state/store';
import { sizeUpPosition } from '../state/bot/botSlice';

type SizeUpPositionModalProps = {
  symbol: string
  open: boolean
  setOpen: (open: boolean) => void
}

const SizeUpPositionModal: FC<SizeUpPositionModalProps> = ({ symbol, open, setOpen }) => {
  const formRef = useRef<HTMLFormElement>(null)
  const dispatch = useDispatch<AppDispatch>()

  return (
    <CustomModal
      isDialog={false}
      title={`Sizing Up Position (${symbol})`}
      buttons={[ModalButtonEnum.CANCEL, ModalButtonEnum.CONFIRM]}
      open={open}
      onClose={({ button, values }) => {
        if (button === ModalButtonEnum.CONFIRM) {
          const tradeSize = parseFloat(values.tradeSize)          
          dispatch(sizeUpPosition({ symbol, tradeSize }))
          localStorage.setItem('size-up-position-tradesize', tradeSize.toString())
        }
        setOpen(false)
      }}
    >
      <>
      <NinjaForm formRef={formRef} OnSubmit={() => { }} fields={
        [
            { name: 'tradeSize', label: 'Trade Size($)', zod: z.bigint({ required_error: 'Trade Size is required' }), gridWidth: 12, defaultValue: localStorage.getItem('increment-position-amount') },
          ]} />
      </>
    </CustomModal>
  )
}

export default SizeUpPositionModal