import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AsyncData, HistoricalDataTypeEnum } from '../../types'
import historicalDataService from "../../service/historicalDataService"

interface HistoricalDataState {
  historicalDataDaily: AsyncData<{ symbol: string, startDate?: string, endDate?: string }[]>
  historicalDataIntraday: AsyncData<{ symbol: string, startDate?: string, endDate?: string }[]>
}

const initialState: HistoricalDataState = {
  historicalDataDaily: { loading: false, data: [] },
  historicalDataIntraday: { loading: false, data: [] },
}

const historicalDataSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    upgradeRecordDaily: (state, action: PayloadAction<{ symbol: string, data: any }>) => {
      state.historicalDataDaily.data = state.historicalDataDaily.data.map(itm => {
        if (itm.symbol === action.payload.symbol) return { ...itm, ...action.payload.data, startDate: itm.startDate || action.payload.data.startDate }
        return itm
      })
    },
    upgradeRecordIntraday: (state, action: PayloadAction<{ symbol: string, data: any }>) => {
      state.historicalDataIntraday.data = state.historicalDataIntraday.data.map(itm => {
        if (itm.symbol === action.payload.symbol) return { ...itm, ...action.payload.data, startDate: itm.startDate || action.payload.data.startDate }
        return itm
      })
    },
  },
  extraReducers: (builder) => {
    builder

      //getHistoricalDataDaily
      .addCase(getHistoricalDataDaily.pending, (state) => {
        state.historicalDataDaily.loading = true
      })
      //getHistoricalDataDaily.fulfilled
      .addCase(getHistoricalDataDaily.fulfilled, (state, action) => {
        state.historicalDataDaily.loading = false
        state.historicalDataDaily.data = action.payload
      })

      //getHistosricalDataIntraday
      .addCase(getHistoricalDataIntraday.pending, (state) => {
        state.historicalDataIntraday.loading = true
      })
      //getHistoricalDataIntraday.fulfilled
      .addCase(getHistoricalDataIntraday.fulfilled, (state, action) => {
        state.historicalDataIntraday.loading = false
        state.historicalDataIntraday.data = action.payload
      })

      //newSymbolToHistoricalData
      .addCase(newSymbolToHistoricalData.pending, (state) => {
        //
      })
      //newSymbolToHistoricalData.fulfilled
      .addCase(newSymbolToHistoricalData.fulfilled, (state, action) => {
        if (action.meta.arg.type === HistoricalDataTypeEnum.DAILY) {
          state.historicalDataDaily.data.push({ ...action.payload })
        } else {
          state.historicalDataIntraday.data.push({ ...action.payload })
        }
      })

      //removeSymbolFromHistoricalData
      .addCase(removeSymbolFromHistoricalData.pending, (state) => {
        //
      })
      //removeSymbolFromHistoricalData.fulfilled
      .addCase(removeSymbolFromHistoricalData.fulfilled, (state, action) => {
        if (action.payload.type === HistoricalDataTypeEnum.DAILY) {
          state.historicalDataDaily.data = state.historicalDataDaily.data.filter(itm => itm.symbol !== action.payload.symbolRemoved)
        } else {
          state.historicalDataIntraday.data = state.historicalDataIntraday.data.filter(itm => itm.symbol !== action.payload.symbolRemoved)
        }
      })

      //updateHistoricalDataDaily
      .addCase(updateHistoricalDataDaily.pending, (state) => {
        //
      })
      //updateHistoricalDataDaily.fulfilled
      .addCase(updateHistoricalDataDaily.fulfilled, (state, action) => {
        //
      })
    
      //updateHistoricalDataIntraday
      .addCase(updateHistoricalDataIntraday.pending, (state) => {
        //
      })
      //updateHistoricalDataIntraday.fulfilled
      .addCase(updateHistoricalDataIntraday.fulfilled, (state, action) => {
        //
      })

  }
})

export const getHistoricalDataDaily = createAsyncThunk("historicalData/getHistoricalDataDaily", () => {
  return historicalDataService.getHistoricalDataDaily()
})

export const getHistoricalDataIntraday = createAsyncThunk("historicalData/getHistoricalDataIntraday", () => {
  return historicalDataService.getHistoricalDataIntraday()
})

export const updateHistoricalDataDaily = createAsyncThunk("historicalData/updateHistoricalDataDaily", (args: { symbol: string }) => {
  return historicalDataService.updateHistoricalDataDaily(args.symbol)
})

export const updateHistoricalDataIntraday = createAsyncThunk("historicalData/updateHistoricalDataIntraday", (args: { symbol: string }) => {
  return historicalDataService.updateHistoricalDataIntraday(args.symbol)
})

export const newSymbolToHistoricalData = createAsyncThunk("historicalData/newSymbolToHistoricalData", (args: { type: HistoricalDataTypeEnum, symbol: string, successCb: (addedRecord: any) => void }) => {
  return historicalDataService.newSymbolToHistoricalData(args.type, args.symbol, args.successCb)
})

export const removeSymbolFromHistoricalData = createAsyncThunk("historicalData/removeSymbolFromHistoricalData", (args: { type: HistoricalDataTypeEnum, symbol: string, successCb: (type: HistoricalDataTypeEnum, symbolRemoved: string) => void }) => {
  return historicalDataService.removeSymbolFromHistoricalData(args.type, args.symbol, args.successCb)
})

export const { upgradeRecordDaily, upgradeRecordIntraday } = historicalDataSlice.actions;

export default historicalDataSlice.reducer;